import { IonContent, IonModal, IonPage } from "@ionic/react";
import TermImage from "../../../assets/images/max.png";
import GenerateVoucher from "./generateVoucher";
import { Link, useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleFirstLogin } from "../loginSlice";
import DOMPurify from "dompurify";
import backImage from "../../../assets/icons/arrow-back-black.svg";
import temp from "../../../utils/termsAndConditionData";
import BackButton from "../../../components/common/BackButton";
import userService from "../../../services/UserService";

const logo = {
  width: "91px",
  height: "64px",
  display: "flex",
  margin: "auto",
  justifyContent: "center",
};
const conditions = {
  color: "#171A21",
  font: "montserrat",
  fontSize: "12px",
  lineHeight: "18px",
};
export default function TermsCondition({ closeTerm }) {
  const loginStore = useSelector((state) => state.login);
  const [consentBox, setConsentBox] = useState(true);
  const [ackBox, setAckBox] = useState(true);
  const [btnActive, setBtnActive] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  const handleAckBox = (e) => {
    setAckBox(e.target.checked);
  };

  const handleConsentBox = (e) => {
    setConsentBox(e.target.checked);
  };

  useEffect(() => {
    setBtnActive(consentBox && ackBox);
  }, [consentBox, ackBox]);

  const handleSubmit = () => {
    userService
      .saveConsentStatus(loginStore.user.patientProfile.id)
      .then((res) => {
        console.log("Consent saved");
        dispatch(toggleFirstLogin());
        if (loginStore && loginStore.user && loginStore.user.patientProfile) {
          history.replace("/testimonials");
        } else {
          history.push("/register");
        }
      })
      .catch((err) => {
        dispatch(toggleFirstLogin());
        if (loginStore && loginStore.user && loginStore.user.patientProfile) {
          history.replace("/testimonials");
        } else {
          history.push("/register");
        }
      });
  };

  const imgStyle = {
    width: "24px",
    height: "24px",
  };

  return (
    <IonPage>
      <IonContent>
        <div class="items-center justify-center p-5" style={conditions}>
          <div class="max-w-md w-full mx-auto">
            <div className=" flex items-center  pt-4 space-x-2">
              <BackButton />
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "1rem",
                  lineHeight: "20px",
                  color: "#171A21",
                }}
              >
                Terms And Conditions
              </div>
            </div>
            {/* <img src={TermImage} alt="image" style={logo} /> */}

            <div class="bg-white p-5 border rounded-lg shadow-lg overflow-y-auto h-[49vh]  mt-4">
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(temp),
                }}
              ></div>
            </div>
          </div>
          <div className=" p-5  " style={conditions}>
            <div className="pt-3 flex">
              <input
                type="checkbox"
                checked={ackBox}
                className=" accent-green-500 checked:bg-white "
                onClick={(e) => handleAckBox(e)}
              />
              <div className="pl-2">
                I acknowledge that I have read and understood the terms and
                conditions, and privacy policy.
              </div>
            </div>
            <div className="pt-3 flex">
              <input
                type="checkbox"
                checked={consentBox}
                className="accent-green-500 checked:bg-white"
                onClick={(e) => handleConsentBox(e)}
              />
              <div className="pl-2">
                I authorize Axis Max Life Insurance to contact me via SMS /
                Email / Phone / WhatsApp or any other mode over-riding my ‘DND’.
              </div>
            </div>
          </div>
          <div className="flex justify-center  p-6">
            <div
              onClick={() => btnActive && handleSubmit()}
              className={
                btnActive
                  ? "w-full bg-zburgundy text-white p-4 text-2xl no-underline text-center  font-semibold rounded-full"
                  : "w-full bg-[#BEBFCA] text-white p-4 text-2xl no-underline text-center  font-semibold rounded-full"
              }
            >
              I Agree
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}

// {termsConditions.map((term, index) => {
//   return (
//     <>
//       <div className="flex space-x-3 my-3 ">
//         <div>{term.id}.</div>
//         <div>{term.Des}</div>
//       </div>
//     </>
//   );
// })}
