import React from "react";
import { useDispatch, useSelector } from "react-redux";
import backImage from "../../../assets/icons/arrow-back-black.svg";
import ProgressIndicator from "./ProgressIndicator";
import SecureImage from "../../../components/common/SecureImage";
import MaleActive from "../../../assets/images/ic_male_active.png";
import FemaleActive from "../../../assets/images/ic_female_active.png";
import { logEventWithData } from "../../../app/eventSlice";
import { setStateFromPayload } from "../navigoSlice";
import { useHistory } from "react-router";
import BackButton from "../../../components/common/BackButton";
export default function BookingForSection({ handleGotoAddress }) {
  const navigoStore = useSelector((store) => store.navigo);
  const patientProfile = useSelector(
    (store) => store.login.user?.patientProfile
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    isForFamily,
    familyMemberSelected,
    familyMemberSelectedData,
    currentServices,
    familyMembers,
    isUserSelectedFamily,
  } = navigoStore.ui;

  return (
    <React.Fragment>
      <div className="navigo-container-screen-2">
        <div className="navigo-container-screen-2-header">
          {/* <BackButton /> */}
          <img
            src={backImage}
            alt="icon"
            onClick={() => {
              dispatch(
                setStateFromPayload({
                  currScreen: 1,
                  familyMemberSelected: isForFamily ? familyMemberSelected : -2,
                  familyMemberSelectedData: isForFamily
                    ? familyMemberSelectedData
                    : {},
                  addressSelected: null,
                })
              );
            }}
          />
          {/* <p>{"Book services"}</p> */}
          <div className="navigo-container-screen-2-main-top">
            <span className="font-semibold text-base leading-7 text-lightblack">
              {currentServices.name}
            </span>
          </div>
        </div>
        <div className="navigo-container-screen-2-main">
          <div className="navigo-container-screen-2-main-mid">
            <ProgressIndicator isInput={false} />
          </div>
          <p className="navigo-container-screen-2-main-mid-2">Booking for</p>
          <div className="navigo-container-screen-2-main-family-list">
            <div className="navigo-container-screen-2-main-family-list-ele">
              <SecureImage
                src={patientProfile.profileImage}
                defaultImage={
                  patientProfile.gender === 1 ? FemaleActive : MaleActive
                }
              />
              <div
                className={`navigo-container-screen-2-main-family-list-ele-mid ${
                  currentServices.availedForObj[0]
                    ? "general-disabled-color-class"
                    : ""
                }`}
              >
                <p className="navigo-container-screen-2-main-family-list-ele-mid-top">{`${
                  patientProfile.firstName
                } ${
                  patientProfile.lastName ? patientProfile.lastName : ""
                }`}</p>
                <p className="navigo-container-screen-2-main-family-list-ele-mid-bottom">
                  {patientProfile.age ? `${patientProfile.age}, ` : ""}
                  self
                </p>
              </div>

              <input
                type="radio"
                style={{ accentColor: "#97144D" }}
                checked={familyMemberSelected === -1}
                disabled={currentServices.availedForObj[0] ? true : false}
                onChange={() => {
                  dispatch(
                    setStateFromPayload({
                      familyMemberSelected: -1,
                      familyMemberSelectedData: { ...patientProfile },
                    })
                  );
                }}
              />
            </div>
            {currentServices.availableForFamily &&
              !currentServices.availedForFamily &&
              familyMembers.map((fd, index) => {
                return (
                  <div
                    className="navigo-container-screen-2-main-family-list-ele"
                    key={fd.id}
                    onClick={() => {}}
                  >
                    <SecureImage
                      src={fd.profilePicURL}
                      defaultImage={fd.gender === 1 ? FemaleActive : MaleActive}
                    />
                    <div
                      className={`navigo-container-screen-2-main-family-list-ele-mid ${
                        currentServices.availedForObj[fd.memberPatientId]
                          ? "general-disabled-color-class"
                          : ""
                      }`}
                    >
                      <p className="navigo-container-screen-2-main-family-list-ele-mid-top">{`${
                        fd.firstName
                      } ${fd.lastName ? fd.lastName : ""}`}</p>
                      <p className="navigo-container-screen-2-main-family-list-ele-mid-bottom">
                        {fd.age ? `${fd.age}, ` : ""}
                        {fd.relationName}
                      </p>
                    </div>
                    <input
                      type="radio"
                      style={{ accentColor: "#97144D" }}
                      disabled={
                        currentServices.availedForObj[fd.memberPatientId]
                          ? true
                          : false
                      }
                      checked={familyMemberSelected === fd.memberPatientId}
                      onChange={() => {
                        logEventWithData({
                          action: "navigo_choose_family_member",
                          data: {
                            is_app_event: true,
                            serviceID: currentServices?.ID,
                            serviceName: currentServices?.name,
                          },
                        });
                        dispatch(
                          setStateFromPayload({
                            familyMemberSelected: fd.memberPatientId,
                            familyMemberSelectedData: { ...fd },
                            isUserSelectedFamily: true,
                          })
                        );
                      }}
                    />
                  </div>
                );
              })}
          </div>
        </div>
        <div className="navigo-container-screen-2-bottom">
          {currentServices.availableForFamily && (
            <p
              onClick={() => {
                dispatch(
                  logEventWithData({
                    action: "navigo_add_family",
                    data: { is_app_event: true },
                  })
                );
                history.push({
                  pathname: "/family",
                  state: { isMedicine: true, isPath: true, path: "/navigo" },
                });
                // dispatch(
                //   setStateFromPayload({
                //     openFamily: true,
                //     openAddFamily: true,
                //     openFamilyProfile: false,
                //   })
                // );
              }}
            >
              Add a family member
            </p>
          )}
          <button
            disabled={familyMemberSelected === -2}
            onClick={() => {
              if (currentServices.addressRequired) {
                handleGotoAddress(
                  familyMemberSelected,
                  familyMemberSelectedData,
                  isUserSelectedFamily
                );
              } else {
                dispatch(
                  setStateFromPayload({
                    appointmentDate: null,
                    currScreen: 3,
                  })
                );
              }
            }}
          >
            {"Continue"}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}
