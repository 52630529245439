class EnvironmentService {
  getBaseAPI() {
    return process.env.REACT_APP_API_URL;
  }

  getServiceAPI() {
    return process.env.REACT_APP_SERVICE_URL;
  }

  getServiceSocket() {
    return process.env.serviceSocket;
  }
  getSafePayUrl() {
    if (process.env.REACT_APP_CUSTOM_NODE_ENV === "uat") {
      return "https://zyla.in/staging-safe-pay";
    } else return "https://zyla.in/safe-pay";
  }
  getStrapiAPI() {
    if (process.env.REACT_APP_CUSTOM_NODE_ENV === "uat") {
      return "https://strapi.dev1.zyla.in";
    } else return "https://strapi1.prod.zyla.in";
  }
}

export default EnvironmentService;
