import myPlanImage from "../../../assets/images/documentSuccess.png";
import correctImage from "../../../assets/images/correct.png";
import { homePlanData } from "../homePageSlice";
import forwardImage from "../../../assets/images/forwardRedz.svg";

import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { logEvent } from "../../../app/eventSlice";

export default function MyPlan() {
  const dispatch = useDispatch();

  const plan = useSelector((state) => state.home.plans);
  const hsaPlan = plan?.response?.payload?.hsaPlan;
  const ciRider = plan?.response?.payload?.ciRider;
  const policyToDisplayInMyPlan = hsaPlan && hsaPlan[0] ? "hsaPlan" : "ciRider";
  const cidrPlan = plan?.response?.payload?.ciRider;

  const data = {
    id: 1,
    heading: "Wellness benefits: ",
    image: correctImage,
    text1: "Doctor on call",
    text2: "24*7 chat",
    text3: "  Health risk assessment and much more",
  };

  return (
    <>
      <div className="mt-8">
        <div className="font-bold text-base leading-7 text-lightblack py-3">
          My plan
        </div>
        {(hsaPlan && hsaPlan[0]) || (ciRider && ciRider[0]) ? (
          <Link
            to={
              policyToDisplayInMyPlan === "hsaPlan"
                ? "/sewa-myplan"
                : `/cidr-myplan`
            }
            className="no-underline text-black"
          >
            <div
              className=" bg-white   p-3 shadow-md "
              onClick={() => {
                dispatch(logEvent({ action: "click my plan" }));
              }}
            >
              <div className="w-full flex cursor-pointer items-center  justify-between">
                <div className="flex items-center">
                  <img src={myPlanImage} alt="image" className="w-8 h-8 " />
                  <div className="font-semibold text-xs leading-7 text-lightblack px-2">
                    Policy number:&nbsp;
                    <span className="font-medium text-xs ">
                      {policyToDisplayInMyPlan === "hsaPlan"
                        ? plan.response?.payload.hsaPlan[0].policyNumber
                        : plan.response?.payload.ciRider[0].productId}
                    </span>
                  </div>
                </div>

                <img src={forwardImage} className="w-6 h-6 " />
              </div>
              <div>
                <div className="font-semibold text-xs leading-7 text-lightblack ">
                  Product name:&nbsp;
                  <span className="font-medium text-xs ">
                    {policyToDisplayInMyPlan === "hsaPlan"
                      ? plan.response?.payload.hsaPlan[0].productName
                      : plan.response?.payload.ciRider[0].productName}
                  </span>
                </div>
              </div>
              <div className="flex justify-between">
                <div>
                  <div className="font-semibold text-xs leading-7">
                    Policy status:
                  </div>
                  <div className="font-medium text-xs">
                    {policyToDisplayInMyPlan === "hsaPlan"
                      ? plan.response?.payload.hsaPlan[0].policyStatus
                      : plan.response?.payload.ciRider[0].policyStatus}
                  </div>
                </div>
              </div>

              <div>
                <div className="font-semibold text-xs leading-5 pt-3">
                  {data.heading}
                </div>
                <div className=" flex items-center  text-xs leading-5 font-normal">
                  <img src={data.image} alt="" className="w-4 h-4" />
                  <div className="px-2 font-medium"> {data.text1}</div>
                </div>
                <div className=" flex items-center  text-xs leading-5 font-normal">
                  <img src={data.image} alt="" className="w-4 h-4" />
                  <div className="px-2 font-medium"> {data.text2}</div>
                </div>
                <div className=" flex items-center  text-xs leading-5 font-normal">
                  <img src={data.image} alt="" className="w-4 h-4" />
                  <div className="px-2 font-medium"> {data.text3}</div>
                </div>
              </div>
            </div>
          </Link>
        ) : cidrPlan && cidrPlan[0] ? (
          <Link to="/cidr-myplan" className="no-underline text-black">
            <div
              className=" bg-white   p-3 shadow-md "
              onClick={() => {
                dispatch(logEvent({ action: "click my plan" }));
              }}
            >
              <div className="w-full flex cursor-pointer items-center  justify-between">
                <div className="flex items-center">
                  <img src={myPlanImage} alt="image" className="w-8 h-8 " />
                  <div className="font-semibold text-xs leading-7 text-lightblack px-2">
                    Policy number:&nbsp;
                    <span className="font-medium text-xs ">
                      {plan.response?.payload.ciRider[0].productId}
                    </span>
                  </div>
                </div>

                <img src={forwardImage} className="w-6 h-6 " />
              </div>
              <div>
                <div className="font-semibold text-xs leading-7 text-lightblack ">
                  Product name:&nbsp;
                  <span className="font-medium text-xs ">
                    {plan.response?.payload.ciRider[0].productName}
                  </span>
                </div>
              </div>
              <div className="flex justify-between">
                <div>
                  <div className="font-semibold text-xs leading-7">
                    Policy status:
                  </div>
                  <div className="font-medium text-xs">
                    {plan.response?.payload.ciRider[0].policyStatus}
                  </div>
                </div>
              </div>

              <div>
                <div className="font-semibold text-xs leading-5 pt-3">
                  {data.heading}
                </div>
                <div className=" flex items-center  text-xs leading-5 font-normal">
                  <img src={data.image} alt="" className="w-4 h-4" />
                  <div className="px-2 font-medium"> {data.text1}</div>
                </div>
                <div className=" flex items-center  text-xs leading-5 font-normal">
                  <img src={data.image} alt="" className="w-4 h-4" />
                  <div className="px-2 font-medium"> {data.text2}</div>
                </div>
                <div className=" flex items-center  text-xs leading-5 font-normal">
                  <img src={data.image} alt="" className="w-4 h-4" />
                  <div className="px-2 font-medium"> {data.text3}</div>
                </div>
              </div>
            </div>
          </Link>
        ) : (
          <div className=" bg-white   p-4 shadow-md ">
            <div className="text-center">No plan assigned</div>
          </div>
        )}
      </div>
    </>
  );
}
