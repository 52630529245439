import { Link } from "react-router-dom";
import { logEvent } from "../../../../../app/eventSlice";
import earnImage from "../../../../../assets/images/coinImage.svg";
import pointImage from "../../../../../assets/images/earn image.png";
import forwardImage from "../../../../../assets/images/forwardRedz.svg";
import { useDispatch } from "react-redux";

export const SewaPointerCard = ({ selectedPolicy }) => {
  const dispatch = useDispatch();
  const policyData = selectedPolicy.data;

  return (
    <div className="relative  text-lightblack">
      <img src={pointImage} alt="" className="w-full h-60 rounded-lg" />
      <Link
        to="/rewardInfo"
        onClick={() => {
          dispatch(logEvent({ action: `click reward banner` }));
        }}
      >
        <img
          src={forwardImage}
          className="h-6 w-6 absolute top-5 right-5 cursor-pointer"
        />
      </Link>
      <div className="absolute bottom-4 px-2  flex justify-between w-full items-center">
        <div
          className="rounded-lg bg-white p-1 outline left-7 flex items-center "
          style={{ outlineColor: "#95ADCF", outlineWidth: "1px" }}
        >
          <img src={earnImage} alt="" className="w-4 h-4 top-4 " />
          <span className="font-bold text-base text-center">
            &nbsp;&nbsp; {policyData.totalRewardsPoint.toLocaleString()}
            <span>&nbsp;points earned</span>
          </span>
        </div>
        <button className="rounded  w-20 h-10 px-2 mx-[3px] text-white font-bold reward-point-level-tag">
          <span className="reward-point-level-top">Level</span>
          <span className="reward-point-level-bottom">
            {policyData.currentLevelName}
          </span>
        </button>
      </div>
    </div>
  );
};
