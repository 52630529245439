import React from "react";
import { useHistory } from "react-router";
import noVital from "../../../assets/images/no_vital_submitted.png";

export default function NoVitalsShared() {
  const history = useHistory();
  return (
    <div className="no-vital-box">
      <div className="no-vital-box-inner-div">
        <img src={noVital} className="no-vital-main-img" alt="bg-img" />
        <p>Add your vitals to measure progress</p>
        <div
          onClick={() => history.replace("/vitals")}
          style={{ textDecoration: "none" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <div className=" px-5 py-2 cursor-pointer text-center rounded-full text-[#FFFFFF] bg-[#97144D]">
              Add vitals
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
