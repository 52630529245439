import { CircularProgress } from "@mui/material";
import React, { useContext, useEffect } from "react";
import ProgressIndicator from "./ProgressIndicator";
import SingleAppointmentComponent from "./SingleAppointmentComponent";
import backImage from "../../../assets/icons/arrow-back-black.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  createAppointments,
  rescheduleAppointments,
  rescheduleLabtestAppointments,
  navigoShowError,
  setStateFromPayload,
  updateCurrentScreen,
  getAllTimeSlots,
} from "../navigoSlice";
import { logEvent, logEventWithData } from "../../../app/eventSlice";
import noSlotVector from "../../../assets/images/no-slots.svg";
import tickimg from "../../../assets/images/tick.png";
import moment from "moment";
import {
  createDateAndChangeScreen,
  errorMessageHandler,
  stringToIntTime,
} from "../utilityFunctions";
import { useHistory } from "react-router";
import navigoServices from "../../../services/NavigoServices";
import { NavContext } from "@ionic/react";

export default function NavigoFinalSection() {
  const navigoStore = useSelector((store) => store.navigo);
  const patientProfile = useSelector(
    (store) => store.login.user.patientProfile
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const navContext = useContext(NavContext);

  const {
    isReschedule,
    currentServices,
    isForFamily,
    currentAppointment,
    loadingSlots,
    appointmentDate,
    timeSlot,
    slots,
    dateArr,
    dateSelected,
    sltInd,
    callReschedule,
    familyMemberSelected,
    familyMemberSelectedData,
    addressSelected,
    vendorId,
    isUserSelectedFamily,
  } = navigoStore.ui;

  useEffect(() => {
    dispatch(
      logEventWithData({
        action: "navigo_open_schedule_slot",
        data: {
          is_app_event: true,
          serviceID: currentServices.ID,
          serviceName: currentServices.name,
        },
      })
    );
    let dateArr = createDateAndChangeScreen(
      currentServices.minDayBooking,
      currentServices.maxDayBooking
    );
    dispatch(
      setStateFromPayload({
        dateArr: dateArr,
      })
    );
    getTimeSlots(dateArr[0].exactDate, 0);
  }, []);
  useEffect(() => {
    if (callReschedule) {
      let body = {
        appointmentID: currentAppointment.appointment.ID,
        appointmentDate: new Date(
          moment(appointmentDate).add(1, "h").format()
        ).toISOString(),
        timeSlot: stringToIntTime(timeSlot.time),
      };
      dispatch(rescheduleAppointments(body));
    }
  }, [callReschedule]);

  const startPayment = (body) => {
    try {
      navigoServices
        .generateOrderForNavigo(body)
        .then((result) => {
          const res = result.data;

          if (window.Capacitor.platform !== "web") {
            setTimeout(() => {
              let url = `${process.env.REACT_APP_ZYLA_PAY_URL}?orderId=${res.orderId}&redirect=https://ep3z4.app.goo.gl/oi43`;
              window.open(url, "_top");
            });
          }

          dispatch(
            setStateFromPayload({
              openZylaPay: true,
              paymentLoaderOrderId: res.orderId,
            })
          );
        })
        .catch((err) => {
          let errMsg = "Couldn't create appointment.";

          if (err && err.message) {
            errMsg = errorMessageHandler(
              err?.response?.data,
              "Couldn't create appointment."
            );
          }
          dispatch(navigoShowError({ msg: errMsg }));
        });
    } catch (err) {
      dispatch(navigoShowError({ msg: "Something went wrong." }));
    }
  };

  const handleCreateAppointment = () => {
    let body = {
      patientID: patientProfile.id,
      bookedFor:
        familyMemberSelected === -1
          ? 0
          : familyMemberSelectedData.memberPatientId,
      serviceID: currentServices.ID,
      status: 0,
      bookingDate: new Date().toISOString(),
      appointmentDate: new Date(
        moment(appointmentDate).add(1, "h").format()
      ).toISOString(),
      timeSlot: stringToIntTime(timeSlot.time),
      timeSlotId: timeSlot.id,
      vendorId: vendorId,
    };

    if (currentServices.addressRequired) {
      body.addressId = addressSelected.id;
    }

    if (
      currentServices.discountedPrice !== 0 &&
      currentServices.discountedPrice !== null
    ) {
      dispatch(logEvent({ action: `click payment` }));
      body.orderId = "";
      startPayment(body);
      return;
    }
    dispatch(createAppointments(body))
      .unwrap()
      .then((res) => {
        dispatch(
          setStateFromPayload({
            currScreen: 1,
            addressSelected: null,
            loadedAppointments: false,
            fetchingAppointments: false,
            openDrawer: false,
            openZylaPay: false,
            openBookAfterPayment: false,
          })
        );
        dispatch(logEvent({ action: `Confirm appointment` }));
        navContext.navigate(
          `/mliNavigo/bookingCard?date=${moment(appointmentDate).format(
            "DD MMM, YYYY"
          )}&time=${timeSlot.time}`,
          "none",
          "pop"
        );
      })
      .catch((err) => {});
  };

  const handleRescheduleAndBooking = () => {
    if (isReschedule) {
      let body = {
        appointmentID: currentAppointment.appointment.ID,
        addressId: currentAppointment.appointment.addressId,
        appointmentDate: new Date(
          moment(appointmentDate).add(1, "h").format()
        ).toISOString(),
        timeSlot: stringToIntTime(timeSlot.time),
        timeSlotId: timeSlot.id,
        vendor: currentAppointment.appointment.vendorId,
        vendorCode: currentAppointment.appointment.vendorCode,
      };

      dispatch(rescheduleAppointments(body));
    } else {
      handleCreateAppointment();
    }
  };

  const getTimeSlots = (date, dateSelected = 0) => {
    try {
      // if (currentServices.isLabTest) {
      let body = {
        collectionDate: moment(date).format("YYYY-MM-DD"),
        patientID: patientProfile?.id,
        email: "",

        serviceId: currentServices.ID,
        vendor: "",
        vendorCode: "",
      };
      if (currentServices.addressRequired) {
        body.addressId = isReschedule
          ? currentAppointment.appointment.addressId
          : addressSelected.id;
      } else {
        body.addressId = 0;
      }

      if (isReschedule) {
        body = {
          ...body,
          vendor: currentAppointment.appointment.vendorId,
          vendorCode: currentAppointment.appointment.vendorCode,
        };
      }
      dispatch(
        getAllTimeSlots({
          data: body,
          dateSelected: dateSelected,
          appointmentDate: date,
        })
      );
      // }
      // else {
      //   let body = {
      //     serviceId: currentServices.ID,
      //     date: moment(date).format("YYYY-MM-DD"),
      //   };
      //   dispatch(
      //     getNavigoServicesSlots({
      //       data: body,
      //       dateSelected: dateSelected,
      //       appointmentDate: date,
      //     })
      //   );
      // }
    } catch (err) {
      dispatch(navigoShowError({ msg: "Something went wrong." }));
    }
  };

  return (
    <React.Fragment>
      <div className="navigo-booking-screen-3-container">
        <div className="navigo-booking-screen-3-header">
          <img
            src={backImage}
            alt="icon"
            onClick={() => {
              if (isReschedule) {
                dispatch(
                  setStateFromPayload({
                    currScreen: 1,
                    isReschedule: false,
                    currentAddressSelected: null,
                  })
                );
              } else {
                if (isForFamily && !isUserSelectedFamily) {
                  dispatch(updateCurrentScreen(1));
                } else {
                  dispatch(updateCurrentScreen(2));
                }
              }
            }}
          />
        </div>
        <p className="navigo-booking-screen-3-mid-service-name">
          {isReschedule
            ? currentAppointment.service.name
            : currentServices.name}
        </p>
        <div className="navigo-booking-screen-3-mid">
          {isReschedule ? (
            <div className="navigo-booking-screen-3-mid-progress">
              <div className="navigo-booking-container-section-3-ele">
                <SingleAppointmentComponent
                  serviceName={"Old appointment"}
                  displayName={currentAppointment.appointment?.displayName}
                  date={currentAppointment.appointment?.appointmentDate}
                  timeSlot={currentAppointment.appointment?.timeSlot}
                />
              </div>
            </div>
          ) : (
            <div className="navigo-booking-screen-3-mid-progress">
              <ProgressIndicator isInput={true} />
            </div>
          )}
          <div className="navigo-booking-screen-3-calendar">
            {dateArr &&
              dateArr.length > 0 &&
              dateArr.map((dt, dateIndex) => {
                return (
                  <div
                    className={`navigo-booking-screen-3-calendar-date ${
                      dateSelected === dateIndex
                        ? "navigo-booking-screen-3-calendar-date-selected "
                        : ""
                    }`}
                    onClick={() => {
                      getTimeSlots(dt.exactDate, dateIndex);
                    }}
                  >
                    <span>
                      {dt.day}&nbsp;{dt.month}
                    </span>
                    &nbsp;
                  </div>
                );
              })}
          </div>
          <div className="navigo-booking-screen-3-bottom-parent">
            <div className="navigo-booking-screen-3-bottom">
              {!loadingSlots &&
                slots &&
                slots.length > 0 &&
                slots.map((slt) => {
                  return (
                    <div
                      className={`navigo-booking-screen-3-bottom-ele ${
                        timeSlot && timeSlot.id === sltInd
                          ? "navigo-booking-screen-3-bottom-ele-selected"
                          : ""
                      }`}
                      onClick={() => {
                        dispatch(
                          setStateFromPayload({ timeSlot: slt, sltInd: slt.id })
                        );
                      }}
                      key={slt.id}
                    >
                      <p>{slt.time}</p>
                      {timeSlot && slt.id === timeSlot.id && (
                        <img src={tickimg} alt="icon" />
                      )}
                    </div>
                  );
                })}
              {!loadingSlots && slots.length === 0 && (
                <div className="navigo-booking-screen-3-bottom-no-slots">
                  <img src={noSlotVector} alt="bg-img" />
                  <p>
                    Ops! no free slot available,
                    <br /> try some other date
                  </p>
                </div>
              )}
              {loadingSlots && (
                <CircularProgress className="navigo-no-slots-loader"></CircularProgress>
              )}
            </div>
          </div>
          <div className="navigo-booking-screen-3-button">
            <button
              disabled={!timeSlot || !appointmentDate}
              onClick={() => {
                handleRescheduleAndBooking();
              }}
            >
              {isReschedule ? "Reschedule" : "Book"}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
