import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import { IonContent, IonModal, IonPage } from "@ionic/react";
import { CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { logEvent } from "../../app/eventSlice";
import "../../assets/css/registration.scss";
import ErrorComponent from "../../components/common/ErrorComponent";
import AuthenticationService from "../../services/AuthenticationService";
import { handleBackButtonFunc } from "../../utils/helperMethods";
import { updateIsRegistered } from "../login/loginSlice";
import DrawerComponent from "./components/drawerComponent";
import RegistrationInfo from "./components/registrationInfo";
import { SourceSelector } from "./components/sourceSelector";
import {
  checkWhitelistedStatus,
  updateErrorState,
  updateSouceOnTentacle,
} from "./registrationSlice";

import { backToPhoneForm } from "../../containers/login/loginSlice";
import arrowBlack from "../../assets/icons/arrow-back-black.svg";
import BackButton from "../../components/common/BackButton";
export default function RegistrationPage() {
  const registrationStore = useSelector((store) => store.registration);

  const dispatch = useDispatch();
  const history = useHistory();
  const { registrationStatus, ui } = registrationStore;
  const { error, errorMsg, sourceCodeOpen, sourceModal, pageLoader } = ui;
  // useEffect(() => {
  //   if (window.Capacitor.platform === "android") {
  //     document.addEventListener("ionBackButton", handleBackButtonFunc);
  //   }
  //   return () => {
  //     if (window.Capacitor.platform === "android") {
  //       document.removeEventListener("ionBackButton", handleBackButtonFunc);
  //     }
  //   };
  // }, []);

  useEffect(() => {
    if (registrationStatus === 1) {
      dispatch(updateIsRegistered());
      dispatch(logEvent({ action: "success_register" }));
      history.replace("/testimonials");
    } else if (registrationStatus === 2) {
      dispatch(logEvent({ action: "error_registration_server" }));
    }
  }, [registrationStatus]);
  const revereseErrorUpdates = () => {
    dispatch(updateErrorState({ data: false }));
  };

  // const onClickBackToPhonePage = () => {
  //   dispatch(backToPhoneForm());
  // };

  return (
    <IonPage>
      <IonContent className="signup-container">
        <ErrorComponent
          show={error}
          msg={errorMsg}
          errorClose={revereseErrorUpdates}
        />

        <React.Fragment>
          {/* <img
            src={arrowBlack}
            className="w-6 h-6 mb-2"
            onClick={(e) => {
              history.goBack();
            }}
          /> */}
          <BackButton />

          <div className="font-bold text-base leading-6 text-lightblack pl-1">
            To generate the Voucher Code for Axis Max Life Secure Earnings and
            Wellness Advantage Plan (SEWA) (UIN:104N136V01), fill out the
            following details for the life to be insured:
          </div>
          <main className="responsive-600">
            <RegistrationInfo />
          </main>
        </React.Fragment>
      </IonContent>
    </IonPage>
  );
}
