import React from "react";
import MaleActive from "../../../assets/images/Clipped.svg";
import SecureImage from "../../../components/common/SecureImage";
import FemaleActive from "../../../assets/images/femaleClipped.svg";
import plusIcon from "../../../assets/images/ic_plus_button_primary_2z.svg";
import { useDispatch, useSelector } from "react-redux";
import { updateLayout, openMemberInfo } from "../familySlice";
import { getUrlWithToken } from "../../../utils/helperMethods";
import { logEvent } from "../../../app/eventSlice";
export default function FamilyDashboard() {
  const docToken = useSelector((store) => store.tab.docToken?.token);
  const dispatch = useDispatch();
  const familyStore = useSelector((store) => store.familyInfo);
  const { familyData, relations } = familyStore;

  return (
    <div className="family-plan-member-container">
      <p className="family-plan-member-top">
        Add a family member to view their health records. You will require their
        consent via mobile OTP verification to access their medical records.
      </p>
      <div className="family-plan-member-mid">
        {familyData.map((fd) => {
          return (
            <div
              className="family-plan-member-grid-ele"
              key={fd.id}
              onClick={() => dispatch(openMemberInfo(fd))}
            >
              <SecureImage
                src={fd.profilePicURL}
                defaultImage={fd.gender === 1 ? FemaleActive : MaleActive}
              ></SecureImage>

              <p className="family-plan-grid-ele-name">
                {fd.firstName + " " + (fd.lastName || "")}
              </p>
              <p className="family-plan-grid-ele-relation">
                {relations.entities[fd.relation]?.value
                  ? relations.entities[fd.relation]?.value
                  : ""}
              </p>
            </div>
          );
        })}
        <div
          className="family-plan-member-grid-ele fp-member-grid-ele-white"
          onClick={() => {
            dispatch(updateLayout(4));
            dispatch(logEvent({ action: "click add member" }));
          }}
        >
          <img src={plusIcon} alt="icon" />
          <p className="family-plan-grid-ele-name">Add member</p>
        </div>
      </div>
      <p className="family-plan-member-bottom">
        <strong>Disclaimer: </strong>By adding a family member to your account,
        you can access their medical profile. You are responsible for
        maintaining the safety of the medical records of your family members.
      </p>
    </div>
  );
}
