import React, { useEffect, useState } from "react";
import "./healthGoals.scss";
import ShortTermGoals from "./components/shortTermGoals";
import nextImage from "../../assets/images/nextz.svg";
import backImage from "../../assets/images/icon_arrow_back.png";
import Message from "../../components/common/message";
import LongTermGoals from "./longTermGoals";
import Slider from "./longTermGoalsSlider";
import EmptyLongTermGoalsComponent from "./emptyLongTermGoals";
import { IonPage, IonContent, IonSpinner } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { logEvent } from "../../app/eventSlice";
import { fetchLongTermGoalsApi } from "./goalSlice";
import { Drawer } from "@mui/material";
import { useHistory } from "react-router";
import { sendMessage } from "../chat/chatSlice";

export default function HealthGoal() {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.login.user);

  const goalStore = useSelector((state) => state.goals);
  const chatStore = useSelector((state) => state.chat);
  const chat = useSelector((state) => state.tab.chat);
  const { ui, longTermGoals } = goalStore;
  const patientId = user.patientProfile.id;
  const { messages } = chatStore;
  let lastMessage = null;
  if (messages && messages.ids.length > 0) {
    lastMessage = messages.entities[messages.ids[messages.ids.length - 1]];
  }
  const [hideMessage, setHideMessage] = useState(false);
  const [sheetVisible, setSheetVisible] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showEmptyLongTermGoalScreen, setShowEmptyLongTermGoalScreen] =
    useState(false);
  const [showShortTermGoalScreen, setShowShortTermGoalScreen] = useState(false);
  const [lastSlide, setLastSlide] = useState(false);

  useEffect(() => {
    dispatch(fetchLongTermGoalsApi({ patientId }));
  }, [dispatch, patientId]);

  useEffect(() => {
    if (longTermGoals && longTermGoals.length === 0 && ui.loaded) {
      setShowEmptyLongTermGoalScreen(true);
    }
  }, [longTermGoals, ui]);

  const toggleGoalDetails = () => {
    dispatch(
      logEvent({
        action: "click_detail_long_term_goal",
      })
    );
    setShowDetails((show) => !show);
  };

  const goToShortTermGoals = () => {
    setSheetVisible(false);
    setShowShortTermGoalScreen(true);
  };

  const handleClick = () => {
    setHideMessage(true);

    dispatch(
      logEvent({
        action: "click_popup_via_long_term_goals",
      })
    );
  };

  const getLongTermGoalsScreen = () => {
    return (
      <div>
        <div className="message-term fs14">
          Based on my quick analysis, here are the key health goals for you and
          we will work on this together!
        </div>
        <Message
          hideMessage={hideMessage}
          handleClick={handleClick}
          button={"OKAY"}
          message={
            "Please go through each and every health goal in detail to proceed forward."
          }
        />
        <div
          onClick={toggleGoalDetails}
          className={
            hideMessage ? "ht-msg long-term-goal-data" : "long-term-goal-data"
          }
        >
          <LongTermGoals longTermGoals={longTermGoals} />
        </div>
      </div>
    );
  };

  const showBottomSheet = () => {
    dispatch(
      logEvent({
        action: "click_understand_long_term_goal",
      })
    );
    setSheetVisible(true);
  };

  const handleSheetChange = (isOpened) => {
    setSheetVisible(isOpened);
  };
  const lastSlideReached = () => {
    setLastSlide(true);
  };
  const sendMessageGoalComplete = () => {
    if (
      lastMessage &&
      lastMessage.content.state &&
      lastMessage.content.state.type === "goal_start"
    ) {
      const previousState = lastMessage.content.state;
      let message = {
        message: {
          text: "I understand my health goals.",
          type: 1,
          id: "" + new Date().getTime() + Math.random(),
        },
      };
      if (previousState && previousState.type) {
        message.state = previousState;
      }
      const req = {
        roomId: chat.chatRoom,
        userId: chat.externalUserIdSt,
        content: message,
      };
      dispatch(sendMessage(req))
        .unwrap()
        .then((r) => {
          history.replace("/tab/chat");
        })
        .catch((err) => {
          console.log("error ehile sending the message");
        });
    } else {
      history.replace("/tab/chat");
    }
  };

  if (showShortTermGoalScreen) {
    return (
      <IonPage>
        <IonContent>
          <ShortTermGoals
            patientId={patientId}
            sendMessage={sendMessageGoalComplete}
          />
        </IonContent>
      </IonPage>
    );
  }

  if (showEmptyLongTermGoalScreen) {
    return (
      <IonPage>
        <IonContent>
          <EmptyLongTermGoalsComponent handleClick={goToShortTermGoals} />
        </IonContent>
      </IonPage>
    );
  }

  return (
    <IonPage>
      <IonContent>
        {ui.loading ? (
          <div className="loading-spinner-holder">
            <IonSpinner />
          </div>
        ) : (
          <div className="health-goal-content">
            <div
              className={
                showDetails ? "extra-padding head-goals" : "head-goals"
              }
            >
              <div
                className={!showDetails ? "zeroWidth arw" : "arw"}
                onClick={toggleGoalDetails}
              >
                <img className={"back-arw"} alt="" src={backImage} />
              </div>
              <div
                className={
                  showDetails
                    ? "m-15 h-goal fs20 fw-semibold"
                    : "h-goal fs20 fw-semibold"
                }
              >
                Health Score
              </div>
              {ui.error && (
                <div className="error-box">
                  <span>{ui.error}</span>
                </div>
              )}
            </div>
            {!showDetails && (
              <div className="dash-container">
                <div className="orange-dash" />
              </div>
            )}
            <div className="content-health-goals">
              {!showDetails ? (
                getLongTermGoalsScreen()
              ) : (
                <div className="slider-container">
                  {
                    <Slider
                      longTermGoals={longTermGoals}
                      lastSlide={() => {
                        lastSlideReached();
                      }}
                    />
                  }
                </div>
              )}
            </div>
            {!showDetails ? (
              <div onClick={toggleGoalDetails} className="details-button">
                <div className="fs16 fw-semibold">Health goals in details</div>
                <div>
                  <img className="detail-icon" alt="" src={nextImage} />
                </div>
              </div>
            ) : (
              <div
                id={lastSlide ? "details-button-two-active" : ""}
                className="details-button-two"
              >
                <div
                  className="fs16 fw-semibold"
                  onClick={() => {
                    if (lastSlide) {
                      showBottomSheet();
                    }
                  }}
                >
                  I understand my health goals
                </div>
              </div>
            )}
          </div>
        )}
      </IonContent>
      <div className="swipe-screen">
        <Drawer
          anchor={"bottom"}
          open={sheetVisible}
          onClose={handleSheetChange}
        >
          <div className="sheet-text">
            <div className="fs18">
              Thats' great! We will achieve these big goals slowly with simple
              steps by setting smaller goals.
            </div>
            <div className="sheet-btn-short">
              <button onClick={goToShortTermGoals} className="prime-btn">
                Okay
              </button>
            </div>
          </div>
        </Drawer>
      </div>
    </IonPage>
  );
}
