import { IonContent, IonPage } from "@ionic/react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import backImage from "../../../assets/icons/arrow-back-black.svg";
import correctImage from "../../../assets/images/correct.png";
import dropdownImage from "../../../assets/images/forwardRedz.svg";
import Successimage from "../../../assets/images/greenSuccess.png";
import { OtherPolicyDetail } from "./OtherPolicyDetail";
import { useEffect, useState } from "react";
import moment from "moment";
import BackButton from "../../../components/common/BackButton";

function Policy({ v, selectedIndex, policy }) {
  return (
    <>
      <div className=" flex flex-start space-x-1 p-3 mt-6">
        <span className="font-bold text-xs leading-5 whitespace-nowrap">
          {" "}
          Product name:
        </span>
        <div className="text-xs leading-5 font-medium  ">
          {policy === "sewa" &&
            v.response.payload.hsaPlan[selectedIndex]?.productName}
          {policy === "cidr" &&
            v.response.payload.ciRider[selectedIndex]?.productName}
        </div>
      </div>
      <div className=" flex p-3 items-center space-x-1">
        <span className="font-bold text-xs leading-5 "> Policy status:</span>
        <div className="text-xs leading-5 font-medium  ">
          {policy === "sewa" &&
            v.response.payload.hsaPlan[selectedIndex].policyStatus}
          {policy === "cidr" &&
            v.response.payload.ciRider[selectedIndex].policyStatus}
        </div>
        <img src={Successimage} alt="" className="h-3 w-3 " />
      </div>

      {v.response.payload.hsaPlan && policy === "sewa" && (
        <div className=" flex p-3 items-center space-x-1">
          <div className="text-xs leading-5 font-medium  ">
            <span className=" text-xs leading-4 ">
              {" "}
              <span className="text-xs leading-4  font-bold">
                Issuance date:
              </span>{" "}
              &nbsp;
              {moment(
                v.response.payload.hsaPlan[selectedIndex].issuanceDate.slice(
                  0,
                  10
                )
              ).format("DD MMM YYYY")}
            </span>

            {/* {policy === "cidr"
              &&moment(
                  v.response.payload.ciRider[selectedIndex].issuanceDate.slice(
                    0,
                    10
                  )
                ).format("DD MMM YYYY")} */}
          </div>
        </div>
      )}
      <div>
        <div className="flex justify-between text-lightblack space-x-3 p-1">
          {policy === "sewa" && (
            <div className="font-normal text-xs px-2 py-2 rounded-md  w-40 h-24 bg-indigo">
              <span className="font-bold text-xs ">
                ICU hospitalisation benefit per day:
              </span>
              <br />
              Rs.{" "}
              {policy === "sewa"
                ? v.response.payload.hsaPlan[selectedIndex]
                    .icuhospitalizationBenefitPerDay
                : ""}
            </div>
          )}
          {policy === "sewa" && (
            <div className="font-normal text-xs px-2 py-2  rounded-md w-40 h-24 bg-indigo">
              <span className="font-bold text-xs ">
                Non-ICU hospitalisation <br />
                benefit per day: <br />
              </span>
              Rs.{" "}
              {policy === "sewa"
                ? v.response.payload.hsaPlan[selectedIndex]
                    .hospitalizationBenefitPerDay
                : ""}
            </div>
          )}
        </div>
        <div className="flex justify-between text-lightblack space-x-3 p-1">
          {policy === "cidr" && (
            <div className="font-normal text-xs px-2 py-3 rounded-md  w-40 h-full bg-indigo">
              <span className="font-bold text-xs ">Issuance date:</span>
              <br />

              {moment(
                v.response.payload.ciRider[selectedIndex].issuanceDate.slice(
                  0,
                  10
                )
              ).format("DD MMM YYYY")}
            </div>
          )}
          {policy === "cidr" && (
            <div className="font-normal text-xs px-2 py-3  rounded-md w-40 h-full bg-indigo">
              <span className="font-bold text-xs ">
                Expiry date: <br />
              </span>

              {moment(
                v.response.payload.ciRider[selectedIndex].expiryDate.slice(
                  0,
                  10
                )
              ).format("DD MMM YYYY")}
            </div>
          )}
        </div>
        {policy === "sewa" && (
          <div className="flex justify-between text-lightblack space-x-3 p-1">
            <div className="font-normal text-xs px-2 py-2 rounded-md  w-40 h-20 bg-indigo">
              <span className="font-bold text-xs ">
                Minor surgery claim amount
              </span>
              <br />
              Rs.{" "}
              {v.response.payload.hsaPlan
                ? v.response.payload.hsaPlan[selectedIndex]
                    .minorSurgeryClaimAmount
                : ""}
            </div>
            <div className="font-normal text-xs px-2 py-2  rounded-md w-40 h-20 bg-indigo">
              <span className="font-bold text-xs ">
                Major surgery claim amount
                <br />
              </span>
              Rs.{" "}
              {v.response.payload.hsaPlan
                ? v.response.payload.hsaPlan[selectedIndex]
                    .majorSurgeryClaimAmount
                : ""}
            </div>
          </div>
        )}
      </div>

      <div className="font-bold text-xs leading-5 px-3 mt-4">
        Wellness benefits:
      </div>
      <div className=" flex items-center px-3 text-xs leading-5 font-normal">
        <img src={correctImage} alt="" className="w-4 h-4" />
        Doctor on call
      </div>
      <div className="flex items-center px-3 text-xs leading-5 font-normal">
        <img src={correctImage} alt="" className="w-4 h-4" />
        24*7 chat
      </div>
      <div className=" flex items-center px-3 text-xs leading-5 font-normal">
        <img src={correctImage} alt="" className="w-4 h-4" />
        Health risk assessment and much more
      </div>
    </>
  );
}

export const MyPlan3 = () => {
  const selector = useSelector((state) => state.home.plans);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [policyType, setPolicyType] = useState("");
  const [openPolicyMenu, setOpenPolicyMenu] = useState(false);
  const [policyList, setPolicyList] = useState([]);
  const [cidrPolicyList, setCidrPolicyList] = useState([]);

  useEffect(() => {
    if (
      selector &&
      selector.response &&
      selector.response.payload &&
      selector.response.payload.hsaPlan &&
      selector.response.payload.hsaPlan.length > 0
    ) {
      setPolicyList(selector.response.payload.hsaPlan);
      setPolicyType("sewa");
    }
    if (
      selector &&
      selector.response &&
      selector.response.payload &&
      selector.response.payload.ciRider &&
      selector.response.payload.ciRider.length > 0
    ) {
      setCidrPolicyList(selector.response.payload.ciRider);
      setPolicyType("cidr");
    }
  }, [selector]);

  return (
    <IonPage>
      <IonContent>
        <div className="p-5">
          <div className="text-lightblack">
            <div className="flex items-center p-2">
              <BackButton />

              <div className="px-2 font-bold text-base leading-7 ">My plan</div>
            </div>
            <div
              className="outline outline-2 rounded-md flex justify-between mt-2  relative p-2 items-center"
              style={{ outlineColor: "#D9D9D9" }}
            >
              <div className=" text-xs leading-5 font-normal ">
                <span className="font-bold text-xs leading-5 px-2">
                  Policy number:{" "}
                  {policyType === "sewa" &&
                    selector.response.payload.hsaPlan[selectedIndex]
                      .policyNumber}
                  {policyType === "cidr" &&
                    selector.response.payload.ciRider[selectedIndex]?.productId}
                </span>{" "}
              </div>

              <div
                onClick={() => {
                  setOpenPolicyMenu((prev) => !prev);
                }}
              >
                <img
                  src={dropdownImage}
                  alt=""
                  className={`w-6 h-6 ${
                    openPolicyMenu ? "-rotate-90" : "rotate-90 "
                  }`}
                />
              </div>
              {openPolicyMenu && (
                <div
                  className="absolute bg-white z-100  translate-y-full  rounded-lg border-2 border-slate-300 w-full overflow-hidden gap-y-px -bottom-5 flex flex-col justify-start left-0"
                  style={{ border: "1px solid #d9d9d9" }}
                >
                  {policyList &&
                    policyList.map((ele, ind) => {
                      return (
                        <button
                          className="text-lightblack text-xs bg-white w-full h-full p-4 text-left"
                          onClick={() => {
                            setSelectedIndex(ind);
                            setOpenPolicyMenu(false);
                            setPolicyType("sewa");
                          }}
                          style={{ borderBottom: "1px solid #d9d9d9" }}
                        >
                          {/* <strong>Policy number:</strong>&nbsp; */}
                          <span className="font-semibold text-xs leading-5">
                            {" "}
                            {ele.policyNumber}
                          </span>
                        </button>
                      );
                    })}

                  {cidrPolicyList &&
                    cidrPolicyList.map((ele, ind) => {
                      return (
                        //  <Link to={`/cidr-myplan/${ele.productId}`}>
                        <button
                          className="text-lightblack text-xs bg-white w-full h-full p-4 text-left"
                          onClick={() => {
                            setSelectedIndex(ind);
                            setOpenPolicyMenu(false);
                            setPolicyType("cidr");
                          }}
                          style={{ borderBottom: "1px solid #d9d9d9" }}
                        >
                          {/* <strong>Policy number:</strong>&nbsp; */}
                          <span className="font-semibold text-xs leading-5">
                            {" "}
                            {ele.productId}
                          </span>
                        </button>
                        //  </Link>
                      );
                    })}
                </div>
              )}
            </div>
            <Policy
              v={selector}
              selectedIndex={selectedIndex}
              policy={policyType}
            />
            {policyType === "sewa" && (
              <OtherPolicyDetail
                selector={selector}
                selectedIndex={selectedIndex}
              />
            )}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
