import React from "react";
import { useHistory } from "react-router-dom";
import stepsIcon from "../../../assets/icons/steps_icon.png";
import googleIcon from "../../../assets/icons/google_logo.png";
import OrangeChevron from "../../../assets/images/forwardRedz.svg";

import { useDispatch } from "react-redux";
import { logEvent } from "../../../app/eventSlice";
import blackSync from "../../../assets/icons/sync_black.svg";
import blackShoeIcon from "../../../assets/icons/black_shoe_icon.svg";
import forwardImage from "../../../assets/images/forwardRedz.svg";
const messages = {
  success: "YOU HAVE ACHIEVED YOUR GOAL",
  shortYesterday: "STEPS SHORT OF GOAL",
  shortToday: "STEPS TO ACHIEVE GOAL",
  tommorrow: "GOAL TO ACHIEVE",
  noTracking: "Measure the steps",
};
function Steps({ trackingEnabled, day, initializeStepAndSleep, steps }) {
  const dispatch = useDispatch();
  const history = useHistory();

  let message = "";
  let emptystep = "----Steps";
  if (day === 1) {
    emptystep = "10000 steps";
  }
  if (trackingEnabled === true) {
    message = (
      <div className="flex items-center gap-x-2 w-full">
        <img src={blackShoeIcon} className="w-5 h-5 justify-between" />
        <div className="flex-1 flex items-center justify-between">
          <p className="m-0 text-xs text-lightblack">Daily step count</p>
          <p className="font-medium text-xs text-lightblack pr-7">
            &nbsp;{steps ? steps.toLocaleString() : "-"}
          </p>
        </div>
      </div>
    );
  } else {
    message = (
      <div className="flex items-center gap-x-2 w-full justify-around">
        <img src={blackSync} className="w-5 h-5" />
        {window.Capacitor.platform === "ios" ? (
          <p className="m-0 text-xs text-lightblack">Sync with HealthKit</p>
        ) : (
          <p className="m-0 text-xs text-lightblack">Sync with Google Fit</p>
        )}

        {/* <img src={forwardImage} alt="img" className="w-6 h-6 cursor-pointer" /> */}
      </div>
    );
  }

  return (
    <div className="steps-tracker-wrapper" id="step">
      <div
        className="steps-tracker-detail"
        onClick={(e) => {
          if (trackingEnabled === true && day !== 1) {
            dispatch(logEvent({ action: "open_tracking_chart_from_steps" }));
            history.push("/trackingChart?1");
          }
        }}
      >
        {/* <div className="steps">
          <div className="icon__wrapper">
            <img src={stepsIcon} alt="icon" />
            <span>Steps</span>
          </div>
        </div> */}
        <div className="reading-div !items-center">
          <div className="reading-div-left">{message}</div>

          {day !== 1 && trackingEnabled === true ? (
            <div className="progress-bar">
              <img
                className="w-6 h-6 cursor-pointer"
                src={OrangeChevron}
                alt="icon"
              />
            </div>
          ) : trackingEnabled === false && day !== 1 ? (
            <>
              {window.Capacitor.platform === "ios" ? (
                <div
                  className="start-tracking"
                  onClick={(e) => {
                    dispatch(
                      logEvent({ action: "click_start_tracking_steps" })
                    );
                    initializeStepAndSleep({
                      lastSyncedSteps: "1970-01-01T00:00:00Z",
                      lastSyncedSleep: "1970-01-01T00:00:00Z",
                    });
                  }}
                >
                  <p>Start Tracking</p>
                </div>
              ) : (
                <div
                  className="start-tracking-android"
                  onClick={(e) => {
                    dispatch(
                      logEvent({ action: "click_start_tracking_steps" })
                    );
                    initializeStepAndSleep({
                      lastSyncedSteps: "1970-01-01T00:00:00Z",
                      lastSyncedSleep: "1970-01-01T00:00:00Z",
                    });
                  }}
                >
                  <img src={googleIcon} alt="icon" />
                  <span>Sign In</span>
                </div>
              )}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default Steps;
