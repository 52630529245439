import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import "../StepAndSleepChart/tracker_chart.scss";
import next_button from "../../../assets/images/forwardRedz.svg";
import previous_button from "../../../assets/images/ic_previous_button.png";

import LineGraph from "../StepAndSleepChart/TrackerLineChart";
import moment from "moment";

const createAndReturnParamGroups = () => {
  let trackerParamGroups = [{ id: 1, name: "Steps" }];
  if (window.Capacitor.platform === "ios") {
    trackerParamGroups.push({ id: 2, name: "Sleep" });
  }
  return trackerParamGroups;
};

function TrackerChartGraph() {
  const [selectedEndTime, setSelectedEndTime] = useState(moment.now());
  const [selectedStartTime, setSelectedStartTime] = useState(
    moment().subtract(6, "days")
  );
  const [paramGroupId, setParamGroupId] = useState(
    window.location.search.substring(1) || 1
  );
  const [average, setAverage] = useState(-1);
  const [goalStreak, setGoalStreak] = useState(-1);
  const [initialselectedEndTime, setInitialselectedEndTime] = useState(
    moment.now()
  );
  const [initialselectedStartTime, setInitialselectedStartTime] = useState(
    moment().subtract(6, "days")
  );

  const [showChart, setShowChart] = useState(true);

  const [trackerParamGroups, setTrackerParamGroups] = useState(() =>
    createAndReturnParamGroups()
  );
  const history = useHistory();

  const renderDisplay = () => {
    return (
      moment(selectedStartTime).format("DD MMM") +
      " - " +
      moment(selectedEndTime).format("DD MMM")
    );
  };
  const onTabChange = (pgId) => {
    if (pgId) {
      setParamGroupId(pgId);
      setSelectedEndTime(initialselectedEndTime);
      setSelectedStartTime(initialselectedStartTime);
    }
  };
  const getAverage = (average, goalStreak) => {
    if (paramGroupId == 1) {
      setAverage(average);
      setGoalStreak(goalStreak);
    } else {
      let hr = parseInt(average / 60);
      let min = average % 60;
      let avg = `${hr}hr ${min}min`;
      setAverage(avg);
      setGoalStreak(goalStreak);
    }
  };
  const onClickPrevious = () => {
    changeDateRange(true);
  };
  const onClickNext = () => {
    changeDateRange(false);
  };
  const changeDateRange = (back) => {
    let newStartDate = selectedStartTime;
    let newEndDate = selectedEndTime;
    if (back) {
      newEndDate = moment(selectedStartTime).subtract(1, "days");
      newStartDate = moment(newEndDate).subtract(6, "days");
    } else {
      newStartDate = moment(selectedEndTime).add(1, "days");
      newEndDate = moment(newStartDate).add(6, "days");
    }

    setSelectedStartTime(moment(newStartDate).toDate().valueOf());
    setSelectedEndTime(moment(newEndDate).toDate().valueOf());
  };

  let avgMessage = "";
  if (paramGroupId == 1) {
    avgMessage = "----Steps";
  } else {
    avgMessage = "--hr --min";
  }

  return (
    <div className="tracker-chart-graph-container">
      {showChart && (
        <div className="tracker_chart_chart_box">
          <div className="date_section">
            <img
              src={next_button}
              onClick={() => onClickPrevious()}
              alt="icon"
              className="date_button rotate-180"
            ></img>
            <p> {renderDisplay()}</p>
            <img
              src={next_button}
              onClick={() => onClickNext()}
              alt="icon"
              className="date_button"
            ></img>
          </div>
          <LineGraph
            time={selectedEndTime}
            paramGroupId={paramGroupId}
            paramGroups={trackerParamGroups}
            getAverage={(average, goalStreak) =>
              getAverage(average, goalStreak)
            }
            onTabChange={(pgID) => {
              onTabChange(pgID);
            }}
          ></LineGraph>
        </div>
      )}
      {showChart && (
        <div className="tracker_chart_average_vitals ">
          <div className="tracker_value">
            <span>Average</span>
            {average !== -1 ? (
              <p>{average.toLocaleString()}</p>
            ) : (
              <p>{avgMessage}</p>
            )}
          </div>
          <hr />
          <div className="tracker_value">
            <span> Goal Streak</span>
            {goalStreak !== -1 ? <p>{goalStreak} days</p> : <p>-days</p>}
          </div>
        </div>
      )}
    </div>
  );
}

export default TrackerChartGraph;
