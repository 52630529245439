import successGreenImage from "../../assets/images/successGreen.png";
import calenderImage from "../../assets/images/calender.png";
import clockImage from "../../assets/images/clock-black.png";
import { IonContent, IonPage } from "@ionic/react";
import { useHistory, useLocation, useParams } from "react-router";
const MedicineSuccessfully = () => {
  const history = useHistory();
  const location = useLocation();

  let params = new URLSearchParams(location.search);

  return (
    <IonPage>
      <IonContent>
        <div className="p-8 bg-white h-full">
          <div className="w-full flex justify-center pt-40">
            <img src={successGreenImage} className="h-20 w-16 "></img>
          </div>
          <div className="text-center font-medium text-2xl  text-lightblack py-4">
            Your booking has been placed successfully.
          </div>
          <div className="font-normal text-xs text-lightblack text-center">
            You will receive a call from our medicine partner to confirm
            medicine & quantity you want to order.
          </div>
          <div className="flex justify-between mx-14 text-lightblack items-center"></div>

          <button
            className="w-full bg-zburgundy text-white p-4 text-xl   font-semibold rounded-full text-center mt-28"
            onClick={() => {
              history.replace("/tab/home");
            }}
          >
            Go back
          </button>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default MedicineSuccessfully;
