import { IonPage, IonContent } from "@ionic/react";
import BackButton from "../../components/common/BackButton";

export default function CustomerSupport() {
  const backStyle = {
    height: "24px",
    width: "24px",
    marginRight: "12px",
  };

  const webURL = () => {
    // window.location.href = "https://www.maxlifeinsurance.com/customer_service";
    window.open("https://www.maxlifeinsurance.com/customer-service", "_blank");
  };
  const openWhatsApp = () => {
    const phoneNumber = "+91-74283 96005";
    const message = "Hello";

    const lang = "en";
    // Construct the WhatsApp URL with the phone number and message
    const whatsappURL = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
      message
    )}&lang=${lang}`;

    window.open(whatsappURL, "_blank");
  };
  const makePhoneCall = () => {
    //phone call
    const phoneNumber = " 1860 1205 577";
    const phoneCallURL = `tel:${phoneNumber}`;
    window.open(phoneCallURL, "_blank");
  };

  //go to send Email
  const emailAddress = "service.helpdesk@maxlifeinsurance.com";

  const sendEmail = () => {
    const mailtoLink = `mailto:${emailAddress}`;
    window.location.href = mailtoLink;
  };

  return (
    <>
      <IonPage>
        <IonContent>
          <div className="coustmer-support bg-white h-full">
            <div className="flex items-center px-5 py-3">
              <BackButton style={backStyle} />
              <span className="font-bold text-base leading-4 text-lightblack">
                Customer support
              </span>
            </div>

            <div className=" px-5 py-2 text-left block leading-4 font-medium text-lightblack ">
              <p className="leading-4 font-medium text-lightblack">
                WhatsApp support number:
              </p>
              <span
                className="  font-bold leading-5 text-base tracking-{-2%} cursor-pointer"
                onClick={openWhatsApp}
              >
                +91-74283 96005
              </span>
              <p className="leading-4 font-medium text-lightblack">
                Customer helpline number:
              </p>
              <span
                className="  font-bold leading-5 text-base tracking-{-2%} cursor-pointer"
                onClick={makePhoneCall}
              >
                1860 1205 577
              </span>
              <p className="leading-4 font-medium text-lightblack">Website:</p>

              <span
                className="  font-bold leading-5 text-base tracking-{-2%} cursor-pointer"
                onClick={webURL}
              >
                maxlifeinsurance.com/
                <br />
                customer-service
              </span>

              <p className="leading-4 font-medium text-lightblack">E-mail:</p>
              <span
                className="  font-bold leading-5 text-base tracking-{-2%} cursor-pointer "
                onClick={sendEmail}
              >
                service.helpdesk@maxlifeinsurance.com
              </span>
            </div>
          </div>
        </IonContent>
      </IonPage>
    </>
  );
}
