import BookCon from "../../../assets/images/bookCon.png";
import BookDia from "../../../assets/images/bookDia.png";
import BookMed from "../../../assets/images/orderMed.png";
import HraImage from "../../../assets/icons/hraIconHome.svg";
import forwardImage from "../../../assets/images/forwardRedz.svg";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logEvent } from "../../../app/eventSlice";

export function BookingNow() {
  const dispatch = useDispatch();
  const history = useHistory();

  const bookArr = [
    {
      id: 1,
      image: BookCon,
      name: "Book consultation",
      link: "/navigo?currScreen=1",
      event_name: "click_zyla_offer_consultation",
    },
    {
      id: 1,
      image: BookDia,
      name: "Book diagnostics",
      link: "/navigo?currScreen=1",
      event_name: "click_zyla_offer_diagnostic",
    },
    {
      id: 1,
      image: BookMed,
      name: "Order medicine",
      link: "/medicine",
      event_name: "click_zyla_offer_medicine",
    },
  ];

  return (
    <>
      <div className="text-lightblack mt-8">
        <div className="font-bold text-base leading-5 py-3">What’s next</div>
        <div
          className="flex  items-center leading-7 shadow-md bg-white  rounded-md  px-3 py-4 "
          onClick={() => {
            console.log("on clicj in whatsnext");
          }}
        >
          <div className="rounded-sm border-black h-8 w-8">
            <img src={HraImage} alt="" className="  h-8 w-8" />
          </div>
          <div className="text-xs font-medium px-2 bg-[#FFFFFF4D]">
            View your health benefits
          </div>
          {/* <Link to="/navigo?currScreen=1"> */}
          <div className="flex justify-end ml-auto">
            <img
              src={forwardImage}
              alt=""
              className=" min-w-6 w-6 h-6 "
              onClick={() => {
                dispatch(logEvent({ action: "click what's next" }));
                history.push(
                  `/navigo?currScreen=1&serviceId=${process.env.REACT_APP_HRA_ID}`
                );
              }}
            />
          </div>
          {/* </Link> */}
        </div>
        <div>
          <div className="font-bold text-base leading-5 mt-8 text-lightblack">
            Quick access
          </div>

          <div className="flex flex-row justify-between">
            {bookArr.map((e) => {
              return (
                <>
                  <Link to={e.link} className="no-underline">
                    <div
                      key={e.id}
                      className="px-3 pt-3 text-center no-underline"
                      onClick={() => {
                        dispatch(logEvent({ action: `${e.event_name}` }));
                      }}
                    >
                      <img src={e.image} alt="" className="h-14 w-14" />
                      <div className="text-xs font-semibold leading-4 text-lightblack">
                        {e.name}
                      </div>
                    </div>
                  </Link>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
