import { Drawer } from "@mui/material";
import React, { Fragment, useCallback, useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logEvent } from "../../../app/eventSlice";
import nextImage from "../../../assets/images/nextz.svg";
import { fetchShortTermGoalsApi } from "../goalSlice";
import ShortTermCard from "./shortTermCard";

export default function ShortTermGoal({ patientId, sendMessage }) {
  const dispatch = useDispatch();
  const goalStore = useSelector((state) => state.goals);
  const isDisabled = useRef(false);
  const { shortTermGoals } = goalStore;

  const [sheetVisible, setSheetVisible] = useState();
  useEffect(() => {
    isDisabled.current = false;
    dispatch(logEvent({ action: "open_short_term_goals" }));
    dispatch(fetchShortTermGoalsApi({ patientId }));
  }, [dispatch, patientId]);

  const showBottomSheet = () => {
    setSheetVisible(true);
  };

  const handleSheetChange = (isOpened) => {
    setSheetVisible(isOpened);
  };

  const onClickSendMessage = () => {
    if (!isDisabled.current) {
      isDisabled.current = true;
      sendMessage();
    }
  };

  return (
    <Fragment>
      <Drawer anchor={"bottom"} open={sheetVisible} onClose={handleSheetChange}>
        <div className="sheet-text">
          <div className="fs18">
            We need a plan, obviously! And we have one. It's the Level-1 of the
            health plan designed by the leading Doctors. We'll try to achieve
            your short-term goals with the help of it.
          </div>
          <div className="sheet-btn-short">
            <button onClick={onClickSendMessage} className="prime-btn">
              Let's do it!
            </button>
          </div>
        </div>
      </Drawer>

      <div className="short-term-content leading-tight">
        <div>
          <div className="fs20 fw-semibold">
            Here are your short-term health goals
          </div>
          <div className="dash-container">
            <div className="orange-dash" />
          </div>
          <div className="message-term fs14 ">
            We will try to achieve these in the next 10 days to build a healthy
            foundation. Once we achieve these, we will slowly achieve your
            long-term goals over the next 100 days.
          </div>
          <div className="short-term-data">
            {shortTermGoals &&
              shortTermGoals.map((goal) => {
                return <ShortTermCard goal={goal} key={goal.id} />;
              })}
          </div>
          <div onClick={showBottomSheet} className="details-button">
            <div className="fs16 fw-semibold">How to achieve these goals?</div>
            <div>
              <img className="detail-icon" alt="" src={nextImage} />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
