import React, { useCallback, useEffect, useState } from "react";
import { IonContent, IonSlides, IonSlide } from "@ionic/react";
import PlayImage from "../../../assets/images/group_copyz.svg";
import swipeUp from "../../../assets/icons/swipe-up-iconz.svg";
import vegIcon from "../../../assets/icons/veg.png";
import nonVegIcon from "../../../assets/icons/non-veg.png";
import dummyRecipe from "../../../assets/images/dummy-recipe-image.png";
import exerciseIcon from "../../../assets/images/introCarousel/icon_exercise.png";
import meditationIcon from "../../../assets/images/introCarousel/icon_meditation.png";
import nutritionIcon from "../../../assets/images/introCarousel/icon_nutrition.png";
import medicalIcon from "../../../assets/images/introCarousel/icon_medical.png";
import closeIcon from "../../../assets/images/ic_close_white.png";
import closeIconBlack from "../../../assets/images/ic_close.png";
import TimeIcon from "../../../assets/images/ic_in_progress_white.png";
import Description from "./descriptionContainer";
import { Link } from "react-router-dom";
import dompurify from "dompurify";

let swiper = null;
const slideOpts = {
  initialSlide: 0,
  speed: 400,
  on: {
    beforeInit() {
      swiper = this;
    },
  },
};

let swiper2 = null;
const slideOpts2 = {
  initialSlide: 0,
  speed: 400,
  on: {
    beforeInit() {
      swiper2 = this;
    },
  },
};

let swiper3 = null;
const slideOpts3 = {
  initialSlide: 0,
  speed: 400,
  on: {
    beforeInit() {
      swiper3 = this;
    },
  },
};
export default function HealthPlanSlider({
  sliderData,
  closeSlider,
  initialSlide,
}) {
  const [currentSubRecipe, setCurrentSubRecipe] = useState({});
  const [currentRecipe, setCurrentRecipe] = useState({});

  const [mainRecipes, setMainRecipes] = useState([]);
  const [
    openCurrentSubRecipeSlideDetails,
    setOpenCurrentSubRecipesSlideDetails,
  ] = useState(false);
  const [openSubrecipeSlider, setOpenSubrecipeSlider] = useState(false);
  const [openCurrentSlidedetails, setOpenCurrentSlidedetails] = useState(false);
  const [openPlanSlides, setOpenPlanSlides] = useState(false);
  const [currentVisibleSlideIndex, setCurrentVisibleSlideIndex] = useState(0);
  const [optionImages, setOptionImages] = useState([]);

  useEffect(() => {
    if (!openSubrecipeSlider) {
      slideOpts3.initialSlide = 0;
    }
  }, [openSubrecipeSlider]);

  const getCategoryIcon = useCallback((category) => {
    if (category === "Medical") {
      return medicalIcon;
    } else if (category === "Exercise") {
      return exerciseIcon;
    } else if (category === "Meditation") {
      return meditationIcon;
    } else if (category === "Nutrition") {
      return nutritionIcon;
    }
  }, []);

  const handleImageClick = (option, name) => {
    if (name == "Nutrition") {
      setOptionImages(
        option.images && option.images.length > 0 ? [option.images[0]] : []
      );
    } else {
      setOptionImages(option.images ? option.images : []);
    }
  };

  const closeImageSlider = () => {
    setOptionImages([]);
    setCurrentVisibleSlideIndex(0);
  };

  const handleChangeSlide = (e) => {
    setCurrentVisibleSlideIndex(swiper.activeIndex);
  };
  const getExactNumbers = useCallback((num) => {
    if (!num) {
      return "";
    }
    let str = num.split(".");

    if (str && str.length > 1) {
      let top = str[0];
      if (str[0] == "0") {
        top = "";
      }
      if (str[1] == "5" || str[1] == "50") {
        return (
          <span>
            {top}
            <sup>1</sup>&frasl;<sub>2</sub>&nbsp;
          </span>
        );
      } else if (str[1] == "25") {
        return (
          <span>
            {top}
            <sup>1</sup>&frasl;<sub>4</sub>&nbsp;
          </span>
        );
      } else if (str[1] == "75") {
        return (
          <span>
            {top}
            <sup>3</sup>&frasl;<sub>4</sub>&nbsp;
          </span>
        );
      } else {
        return <span>{str[0]}&nbsp;</span>;
      }
    } else if (str && str.length == 1) {
      return <span>{str[0]}&nbsp;</span>;
    }
  }, []);

  return optionImages.length ? (
    <div className="image-slide-body">
      <div style={{ textAlign: "right", padding: "10px" }}>
        <img
          onClick={closeImageSlider}
          className="close-hp-icon"
          src={closeIconBlack}
          alt="icon"
        />
      </div>
      <IonContent>
        <IonSlides pager={true} options={slideOpts}>
          {optionImages.map((image, imageIndex) => {
            return (
              <IonSlide key={imageIndex}>
                <div>
                  <img src={image} alt="bg-img" />
                </div>
              </IonSlide>
            );
          })}
        </IonSlides>
      </IonContent>
    </div>
  ) : (
    <React.Fragment>
      <IonContent>
        <div className="hp-slide-head">
          <div className="indicators-hp">
            {sliderData.options.map((indicator, indexIndicator) => {
              return (
                <div
                  className={
                    currentVisibleSlideIndex == indexIndicator
                      ? "indicate-hp activated-hp"
                      : "indicate-hp"
                  }
                />
              );
            })}
          </div>
          <div className="slide-category-head">
            <div className="slide-data">
              <img
                className="slide-hp-Icon"
                src={getCategoryIcon(sliderData.catname)}
                alt="icon"
              />
              <div>
                <div className="fs20 fw-semibold">{sliderData.catname}</div>
                <div className="fs14">Level {sliderData.level}</div>
              </div>
            </div>
            <div>
              <img
                onClick={closeSlider}
                className="close-hp-icon"
                src={closeIcon}
                alt="icon"
              />
            </div>
          </div>
        </div>

        <IonSlides
          className="hide-bullet"
          onIonSlideWillChange={handleChangeSlide}
          pager={false}
          options={
            initialSlide
              ? { ...slideOpts, initialSlide: initialSlide }
              : slideOpts
          }
        >
          {sliderData.options.map((option, optionKey) => {
            return (
              <IonSlide key={optionKey}>
                <div className="slide-hp">
                  <div className="slide-hp-inner-div">
                    <img
                      onClick={() =>
                        handleImageClick(option, sliderData.catname)
                      }
                      className="option-hp-img"
                      src={option.images ? option.images[0] : dummyRecipe}
                      alt="bg-img"
                    />
                    <div className="fw-semibold img-tag slide-hp-info-box">
                      {sliderData.time && (
                        <div className="slide-hp-info-box-time">
                          <img src={TimeIcon} alt="icon"></img>
                          {sliderData.time}
                        </div>
                      )}
                      <div className="tag-name-hp">{sliderData.name}</div>
                    </div>
                  </div>
                  <div className="hp-data-option">
                    <div>
                      <div className="fs20 fw-semibold option-hp-name">
                        {option.name}
                      </div>

                      <div
                        style={{ margin: "5px 0px", width: "60px" }}
                        className="orange-dash"
                      ></div>
                      {sliderData.catname === "Meditation" && (
                        <Link to="/meditation">
                          <img
                            className="common-img"
                            src={PlayImage}
                            alt="icon"
                          />
                        </Link>
                      )}
                    </div>

                    <Description option={option} />
                    <div className="slide-hp-recipe-container">
                      {option.recipes &&
                        option.recipes.length > 0 &&
                        option.recipes.map((rec, index) => {
                          return (
                            <div
                              className="slide-hp-recipe-box"
                              onClick={() => {
                                slideOpts2.initialSlide = index;
                                setOpenPlanSlides(true);
                                setMainRecipes([...option.recipes]);
                              }}
                            >
                              <img
                                src={rec.image ? rec.image : dummyRecipe}
                                alt="bg-img"
                              />
                              <span>{rec.name}</span>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </IonSlide>
            );
          })}
        </IonSlides>
        {openPlanSlides && (
          <div className="healthplan-recipe-slider-second-container">
            <div className="healthplan-recipe-slider-second">
              <IonSlides
                className="healthplan-slider-2-ion-slides"
                onIonSlideWillChange={() => {
                  // console.log("change recipe option here");
                }}
                pager={true}
                options={slideOpts2}
              >
                {mainRecipes.map((option, optionKey) => {
                  return (
                    <IonSlide
                      key={optionKey}
                      className="healthplan-slider-2-ion-slide"
                    >
                      <div className="healthpan-2-slider-container">
                        <div className="healthpan-2-slider-container-top">
                          <img
                            className="healthpan-2-slider-container-top-main-img"
                            src={option.image ? option.image : null}
                            alt="bg-img"
                          />
                          <img
                            className="healthplan-2-slider-container-top-back-img"
                            src={option.image ? option.image : null}
                            alt="bg-img"
                          />
                        </div>
                        <div
                          className="healthplan-2-slider-close-parent"
                          onClick={() => {
                            setOpenPlanSlides(false);
                          }}
                        >
                          <img
                            src={closeIcon}
                            alt="icon"
                            className="healthplan-2-slider-close"
                          />
                        </div>
                        <div className="healthplan-2-slider-info-box">
                          <p className="healthplan-2-slider-info-top">
                            {option.name}
                          </p>
                          <div className="healthplan-2-slider-info-box-mid">
                            <span>{option.servingSize || 0} Serving</span>
                            <span className="healthplan-2-slider-info-box-mid-span-2">
                              {option.cookingTime || 0} minutes
                            </span>

                            <span>
                              {option.ingredients?.length || 0} ingredients
                            </span>
                          </div>
                          <div
                            className="healthplan-2-slider-info-box-bottom"
                            onClick={() => {
                              setOpenCurrentSlidedetails(true);
                              setCurrentRecipe({ ...option });
                            }}
                          >
                            <span className="healthplan-2-slider-info-box-bottom-span1">
                              View Recipe
                            </span>
                            <img
                              src={swipeUp}
                              alt="icon"
                              className="healthplan-2-slider-info-box-bottom-span2"
                            />
                          </div>
                        </div>
                        <div className="common-slider-bottom-gradient"></div>
                      </div>
                    </IonSlide>
                  );
                })}
              </IonSlides>
              {openCurrentSlidedetails && (
                <div className="healthplan-slider-2-backdrop"></div>
              )}
              <div
                className={`healthplan-slider-2-details-container ${
                  openCurrentSlidedetails ? "bring-slider-up" : ""
                }`}
              >
                <div className="healthplan-slider-2-details-main-box">
                  <div className="healthplan-slider-2-top">
                    <div className="healthplan-slider-2-details-main-box-header">
                      <div className="healthplan-slider-2-details-main-box-header-left">
                        <span>{currentRecipe.name}</span>
                        <img
                          alt="icon"
                          src={
                            currentRecipe.preference == 0 ? vegIcon : nonVegIcon
                          }
                        />
                      </div>
                      <div
                        className="healthplan-slider-2-details-main-box-header-right"
                        onClick={() => {
                          setOpenCurrentSlidedetails(false);
                        }}
                      >
                        <img src={closeIconBlack} alt="icon" />
                      </div>
                    </div>
                    <hr className="healthplan-slider-2-details-divider" />
                  </div>

                  <div className="healthplan-slider-2-mid">
                    {currentRecipe.subrecipe && (
                      <div className="healthplan-slider-2-details-mid-section-1">
                        <p className="healthplan-slider-2-details-mid-section-1-heading">
                          Subrecipes
                        </p>
                        <div className="healthplan-slider-2-details-mid-section-1-content">
                          {currentRecipe.subrecipes &&
                            currentRecipe.subrecipes.map((subrec, index) => {
                              return (
                                <div
                                  className="healthplan-slider-2-details-midsection-1-content-box"
                                  onClick={() => {
                                    slideOpts3.initialSlide = index;
                                    setOpenSubrecipeSlider(true);
                                  }}
                                >
                                  <img
                                    alt="bg-img"
                                    src={
                                      subrec.image ? subrec.image : dummyRecipe
                                    }
                                  />
                                  <span>{subrec.name}</span>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="healthplan-slider-2-bottom">
                    <div className="healthplan-slider-2-bottom-first">
                      <span className="healthplan-slider-2-bottom-first-first">
                        Ingredients:
                      </span>
                      <div className="healthplan-slider-2-bottom-first-last">
                        {currentRecipe &&
                          currentRecipe.ingredients &&
                          currentRecipe.ingredients.map((ing) => {
                            return (
                              <>
                                <span>
                                  {getExactNumbers(ing.amount)}
                                  {`${ing.amount ? " " : ""} ${
                                    ing.amount && ing.unit ? ing.unit : ""
                                  } ${ing.amount && ing.unit ? "·" : ""} ${
                                    ing.name
                                  } ${
                                    ing.instruction
                                      ? `· ${ing.instruction}`
                                      : ""
                                  }`}
                                </span>
                                <br />
                              </>
                            );
                          })}
                      </div>
                    </div>
                    <div className="healthplan-slider-2-bottom-last">
                      <span className="healthplan-slider-2-bottom-last-first">
                        Method:
                      </span>
                      <div
                        className="healthplan-slider-2-bottom-last-last"
                        dangerouslySetInnerHTML={{
                          __html: dompurify.sanitize(currentRecipe.method),
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {openSubrecipeSlider && (
          <div className="healthplan-recipe-slider-third">
            <IonSlides
              className="healthplan-slider-3-ion-slides"
              onIonSlideWillChange={() => {
                // console.log("change subrecipe option here");
              }}
              pager={true}
              options={slideOpts3}
            >
              {currentRecipe.subrecipes.map((option, optionKey) => {
                return (
                  <IonSlide
                    key={optionKey}
                    className="healthplan-slider-3-ion-slide"
                  >
                    <div className="healthpan-3-slider-container">
                      <div className="healthpan-3-slider-container-top">
                        <img
                          className="healthpan-3-slider-container-top-main-img"
                          src={option.image ? option.image : dummyRecipe}
                          alt="bg-img"
                        />
                        <img
                          className="healthplan-3-slider-container-top-back-img"
                          src={option.image ? option.image : dummyRecipe}
                          alt="bg-img"
                        />
                      </div>
                      <div
                        className="healthplan-3-slider-close-parent"
                        onClick={() => {
                          setOpenSubrecipeSlider(false);
                        }}
                      >
                        <img
                          src={closeIcon}
                          alt="icon"
                          className="healthplan-3-slider-close"
                        />
                      </div>
                      <div className="healthplan-3-slider-info-box">
                        <p className="healthplan-3-slider-info-top">
                          {option.name}
                        </p>
                        <div className="healthplan-3-slider-info-box-mid">
                          <span>{option.servingSize || 0} Serving</span>
                          <span className="healthplan-2-slider-info-box-mid-span-2">
                            {option.cookingTime || 0} minutes
                          </span>
                          <span>
                            {option.ingredients?.length || 0} ingredients
                          </span>
                        </div>
                        <div
                          className="healthplan-3-slider-info-box-bottom"
                          onClick={() => {
                            setOpenCurrentSubRecipesSlideDetails(true);
                            setCurrentSubRecipe({ ...option });
                          }}
                        >
                          <span className="healthplan-3-slider-info-box-bottom-span1">
                            View Recipe
                          </span>
                          <img
                            src={swipeUp}
                            alt="icon"
                            className="healthplan-3-slider-info-box-bottom-span2"
                          />
                        </div>
                      </div>
                      <div className="common-slider-bottom-gradient"></div>
                    </div>
                  </IonSlide>
                );
              })}
            </IonSlides>
            <div
              className="healthplan-slider-3-backdrop"
              style={{
                display: `${
                  openCurrentSubRecipeSlideDetails ? "initial" : "none"
                }`,
              }}
            ></div>

            <div
              className={`healthplan-slider-3-details-container ${
                openCurrentSubRecipeSlideDetails ? "bring-slider-up" : ""
              }`}
            >
              <div className="healthplan-slider-3-details-main-box">
                <div className="healthplan-slider-3-top">
                  <div className="healthplan-slider-3-details-main-box-header">
                    <div className="healthplan-slider-3-details-main-box-header-left">
                      <span>{currentSubRecipe.name}</span>
                      <img
                        alt="icon"
                        src={
                          currentSubRecipe.preference == 0
                            ? vegIcon
                            : nonVegIcon
                        }
                      />
                    </div>
                    <div
                      className="healthplan-slider-3-details-main-box-header-right"
                      onClick={() => {
                        setOpenCurrentSubRecipesSlideDetails(false);
                      }}
                    >
                      <img src={closeIconBlack} alt="icon" />
                    </div>
                  </div>
                  <hr className="healthplan-slider-3-details-divider" />
                </div>
                <div className="healthplan-slider-3-bottom">
                  <div className="healthplan-slider-3-bottom-first">
                    <span className="healthplan-slider-3-bottom-first-first">
                      Ingredients:
                    </span>
                    <div className="healthplan-slider-3-bottom-first-last">
                      {currentSubRecipe &&
                        currentSubRecipe.ingredients &&
                        currentSubRecipe.ingredients.map((ing) => {
                          return (
                            <span>
                              {getExactNumbers(ing.amount)}
                              {`${ing.amount ? " " : ""} ${
                                ing.amount && ing.unit ? ing.unit : ""
                              } ${ing.amount && ing.unit ? "·" : ""} ${
                                ing.name
                              } ${
                                ing.instruction ? `· ${ing.instruction}` : ""
                              }`}
                              <br />
                            </span>
                          );
                        })}
                    </div>
                  </div>
                  <div className="healthplan-slider-3-bottom-last">
                    <span className="healthplan-slider-3-bottom-last-first">
                      Method:
                    </span>
                    <div
                      className="healthplan-slider-3-bottom-last-last"
                      dangerouslySetInnerHTML={{
                        __html: dompurify.sanitize(currentSubRecipe.method),
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </IonContent>
    </React.Fragment>
  );
}
