import { IonContent, IonPage } from "@ionic/react";
import backImage from "../../assets/images/back.png";
import assesmentImage from "../../assets/images/riskAssement.png";
import greenImage from "../../assets/images/greenSuccess.png";
import frameImage from "../../assets/images/Frame.png";
import dotImage from "../../assets/images/dot.png";

export default function HealthRiskAssesment2() {
  return (
    <IonPage>
      <IonContent>
        <div className="p-5 text-lightblack">
          <div className="flex items-center ">
            <img src={backImage} alt="icon" className="w-6 h-6" />
            <div className="px-5 font-semibold text-base leading-7  ">
              Health risk assessment
            </div>
          </div>
          <div>
            <hr className="bg-gray-200 w-full " />
          </div>

          <div className="w-full py-2">
            <img src={assesmentImage} alt="" className="h-9 w-full" />
            <div className="flex justify-between py-2">
              <div>Basic details</div>
              <div>Schedule</div>
            </div>
            <div>
              <hr className="bg-gray-200 w-full " />
            </div>
          </div>
          <div className="flex justify-between">
            {["13 Mar", "14 Mar", "16 Mar"].map((v, index) => {
              return (
                <>
                  <div
                    className=" rounded-md flex justify-center outline my-5 p-2"
                    style={{ outlineColor: "#E9ECEF", outlineWidth: "1px" }}
                  >
                    <p className="font-semibold text-base leading-7 text-center">
                      {v}
                    </p>
                  </div>
                  {index === 1 ? (
                    <div>
                      <div
                        className="p-2 rounded-md flex justify-center outline my-5 shadow-md"
                        style={{ outlineColor: "#171A21", outlineWidth: "1px" }}
                      >
                        <p className="font-semibold text-base leading-7">
                          15 Mar
                        </p>
                      </div>
                    </div>
                  ) : null}
                </>
              );
            })}
          </div>

          <div
            className=" rounded-md  outline my-5 p-3"
            style={{ outlineColor: "#171A21", outlineWidth: "1px" }}
          >
            <div className="font-semibold text-base leading-6 flex justify-between items-center text-center">
              10:00 <img src={greenImage} alt="" className="h-6 w-6" />
            </div>
          </div>

          {["11:00", "13:00", "14:00", "16:00"].map((v) => {
            return (
              <>
                <div
                  className=" rounded-md  outline my-5 p-3"
                  style={{ outlineColor: "#171A21", outlineWidth: "1px" }}
                >
                  <div className="font-semibold text-base leading-6 flex justify-between items-center text-center">
                    {v}
                  </div>
                </div>
              </>
            );
          })}

          <div className="flex justify-center  pt-10">
            <button
              type="submit"
              className="w-full bg-zburgundy text-white p-4 text-xl leading-7  font-semibold rounded-full"
            >
              Book
            </button>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}
