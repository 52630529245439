import React, { useRef, useState } from "react";
import { IonLabel } from "@ionic/react";
import "../index.scss";
import BackImage from "../../../assets/images/backward.png";
import NextImage from "../../../assets/images/forward.png";
import PauseImage from "../../../assets/images/group_3z.svg";
import PlayImage from "../../../assets/images/group_copyz.svg";
import { Slider } from "@mui/material";
import RippleButton from "../../../components/common/RippleButton";
import { useDispatch } from "react-redux";
import { logEvent } from "../../../app/eventSlice";
import { calculateValue } from "../medUtilityFunc";

export default function AudioPlayer({
  onDoneLoading,
  onNext,
  isNext,
  onPrevious,
  isPrevious,
  file,
}) {
  const [isPlaying, setIsPlaying] = useState(true);
  const [total, setTotal] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [loading, setLoading] = useState(true);
  const [enableManualSlide, setEnableManualSlider] = useState(false);
  const audioRef = useRef(null);
  const dispatch = useDispatch();

  const onErrorLoadingFile = () => {
    if (onDoneLoading) {
      setLoading(false);
      onDoneLoading("E");
    }
  };
  const initProgressBar = () => {
    if (onDoneLoading) {
      onDoneLoading();
    }

    if (
      audioRef.current &&
      audioRef.current.duration &&
      audioRef.current.currentTime
    ) {
      setTotal(audioRef.current.duration);
      setCurrentTime(audioRef.current.currentTime);
    }
  };
  const onSeek = (value) => {
    if (audioRef.current) {
      audioRef.current.currentTime = value;
    }
    setEnableManualSlider(false);
  };

  const togglePlay = () => {
    if (isPlaying) {
      audioRef.current?.pause();
      dispatch(logEvent({ action: "meditation_stop" }));
    } else {
      let playPromise = audioRef.current?.play();

      if (playPromise !== undefined) {
        playPromise
          .then((res) => {
            // Automatic playback started!
            // Show playing UI.
          })
          .catch((error) => {
            // Auto-play was prevented
            // Show paused UI.
            console.log("some error occurred", error);
          });
      }
      dispatch(logEvent({ action: "meditation_start" }));
    }
    setIsPlaying((prevData) => {
      return !prevData;
    });
  };

  return (
    <div className="controls-media">
      <div className="audio-controls">
        <RippleButton disabled={!isPrevious}>
          <img
            className="audio-option"
            alt="icon"
            style={{ opacity: isPrevious ? 1 : 0.5 }}
            src={BackImage}
            onClick={() => {
              isPrevious && onPrevious();
            }}
          />
        </RippleButton>
        <RippleButton>
          <img
            className="common-img-small"
            alt="icon"
            src={isPlaying ? PauseImage : PlayImage}
            onClick={() => togglePlay()}
          />
        </RippleButton>

        <RippleButton disabled={!isNext}>
          <img
            className="audio-option"
            alt="icon"
            style={{ opacity: isNext ? 1 : 0.5 }}
            src={NextImage}
            onClick={() => {
              isNext && onNext();
            }}
          />
        </RippleButton>
      </div>

      <audio
        autoPlay
        ref={(ref) => (audioRef.current = ref)}
        onPlay={(e) => setIsPlaying(true)}
        onPause={(e) => setIsPlaying(false)}
        onTimeUpdate={(e) => initProgressBar()}
        onError={(e) => onErrorLoadingFile()}
      >
        <source src={file}></source>
      </audio>
      <div className="audio-progress-container">
        <IonLabel slot="start" color="light">
          {calculateValue(currentTime)}
        </IonLabel>
        <Slider
          value={currentTime}
          min={0}
          max={total}
          color="secondary"
          onChange={(e, v) => onSeek(v)}
          aria-labelledby="continuous-slider"
        ></Slider>
        <IonLabel slot="end" color="light">
          {calculateValue(total)}
        </IonLabel>
      </div>
    </div>
  );
}
