import React, { useEffect, useRef, useState } from "react";
import { Health as HealthKit } from "@awesome-cordova-plugins/health";
import "../home-page.scss";
// import { HealthKit } from "@ionic-native/health-kit";
import trackerService from "../../../services/trackerServices";
import Steps from "./StepsTracker";
import Sleep from "./SleepTracker";
import VitalSubmission from "./VitalSubmission";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { logEvent, logEventWithData } from "../../../app/eventSlice";
import { showNoDataAlert, toggleTrackingEnabled } from "../homePageSlice";
import {
  processSingleDayData,
  getExactSLeepingTime,
  formatSleepData,
} from "../homeUtilityFunc";
import { myPromisSettled } from "../../../utils/helperMethods";
import useInterval from "../../../components/common/useInterval";
import { useIonViewDidEnter } from "@ionic/react";
import SkeletonLoader from "../../../components/common/skeletonLoader";
import { PrivacyScreen } from "@capacitor-community/privacy-screen";

function DailyTarget() {
  const patientProfile = useSelector(
    (store) => store.login.user?.patientProfile
  );
  const showNoDataAlert = useSelector((store) => store.home.showNoDataAlert);
  const trackingEnabled = useSelector((store) => store.home.trackingEnabled);
  const platform = useSelector((store) => store.app.device.platform);
  const [selectedDate, setSelectedDate] = useState(0);
  const [loading, setLoading] = useState(false);
  const [steps, setSteps] = useState(0);
  const [sleep, setSleep] = useState(0);
  const [stepsTarget, setStepsTarget] = useState(10000);
  const [sleepYesterday, setSleepYesterday] = useState(0);
  const [sleepToday, setSleepToday] = useState(0);
  const [sleepTargetMin, setSleepTargetMin] = useState(420);
  const [sleepTargetMax, setSleepTargetMax] = useState(600);
  const [day, setDay] = useState("today");
  const [recommendedVital, setRecommendedVital] = useState({ id: 0 });
  const targetInterval = useRef(null);
  const healthInterval = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    handlePrivacyScreenChanges(false);
    syncTrackerData()
      .then((res) => {
        stepAndSleepInitialize(res);
      })
      .catch((err) => {
        console.log("Permission not available");
      });
    updateTarget();

    return () => {
      if (targetInterval.current !== null) {
        clearInterval(targetInterval.current);
      }
      if (healthInterval.current) {
        clearInterval(healthInterval.current);
      }
    };
  }, []);
  const handlePrivacyScreenChanges = async (arg) => {
    if (window.Capacitor.platform != "web") {
      if (arg) {
        await PrivacyScreen.enable();
      } else {
        await PrivacyScreen.disable();
      }
    }
  };
  useInterval(() => {
    updateTarget();
  }, 20000);

  useIonViewDidEnter(() => {
    updateTarget();
  }, [selectedDate]);

  useEffect(() => {
    updateTarget();
  }, [selectedDate]);

  const onClickChangeDate = (date) => {
    setLoading(true);
    setSelectedDate(date);
  };

  const setDataFromResponse = (response) => {
    setSteps(response.steps);
    setStepsTarget(response.stepsTarget);
    setSleepTargetMin(response.sleepTargetMin);
    setSleepTargetMax(response.sleepTargetMax);
    setRecommendedVital(response.recommendedVital);
    setLoading(false);
    if (selectedDate === -1) {
      setSleepYesterday(response.sleep);
      setDay(-1);
    } else if (selectedDate === 0) {
      setSleepToday(response.sleep);
      setDay(0);
    } else {
      setSleep(response.sleep);
      setDay(1);
    }
  };

  const updateTarget = () => {
    if (selectedDate === -1) {
      trackerService
        .getTargetByDate(
          patientProfile?.id,
          new Date(Date.now() - 24 * 60 * 60 * 1000).toISOString()
        )
        .then((result) => {
          setDataFromResponse(result.data);
        })
        .catch((err) => {
          setDay(-1);
        });
    } else if (selectedDate === 0) {
      trackerService
        .getTargetByDate(patientProfile?.id, new Date(Date.now()).toISOString())
        .then((result) => {
          setDataFromResponse(result.data);
        })
        .catch((err) => {
          setDay(0);
        });
    } else {
      trackerService
        .getTargetByDate(
          patientProfile?.id,
          new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString()
        )
        .then((result) => {
          setDataFromResponse(result.data);
        })
        .catch((err) => {
          setDay(1);
        });
    }
  };
  const _createHeaderButtons = () => {
    const buttons = [
      {
        text: "Yesterday",
        isActive: selectedDate === -1,
        date: -1,
        id: 1,
      },
      {
        text: "Today",
        isActive: selectedDate === 0,
        date: 0,
        id: 2,
      },
      {
        text: "Tomorrow",
        isActive: selectedDate === 1,
        date: 1,
        id: 3,
      },
    ];

    return (
      <div className="subheader">
        {buttons.map((value) => {
          const btnClassName =
            value.date === selectedDate ? "button-active" : "";
          return (
            <button
              key={value.id}
              onClick={(e) => onClickChangeDate(value.date)}
              className={btnClassName}
            >
              <div>{value.text}</div>
            </button>
          );
        })}
      </div>
    );
  };

  const loadHealthData = () => {
    let stepOptions = {
      startDate: new Date(new Date().setHours(0, 0, 0, 0)),
      endDate: new Date(),
      dataType: "steps",
      bucket: "day",
    };

    HealthKit.queryAggregated(stepOptions)
      .then((data) => {
        // alert("step data json is ", JSON.stringify(data));
        // localStorage.setItem("step_data_format", JSON.stringify(data));
        // console.log("lolo", data);

        let stepSum = data.reduce((a, b) => a + b.value, 0);

        let params = {
          patientId: patientProfile?.id,
          steps: Math.floor(stepSum),
          recordedAt: new Date(
            new Date(Date.now()).setHours(12, 0, 0, 0)
          ).toISOString(),
        };
        dispatch(
          logEvent({
            action: "step_data",
            data: { id: patientProfile?.id, data: data },
          })
        );
        trackerService
          .postStepsData(params)
          .then((res) => {})
          .catch((err) => {
            dispatch(
              logEventWithData({
                action: "step_data_error",
                data: {
                  err: err,
                },
              })
            );
          });
      })
      .catch((err) => {
        dispatch(logEvent({ action: "no_step_data" }));
      });
  };

  const loadSleepData = () => {
    let sleepOptions = {
      startDate: new Date(
        new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000).setHours(
          18,
          0,
          0,
          0
        )
      ),
      endDate: new Date(
        new Date(Date.now() + 2 * 24 * 60 * 60 * 1000).setHours(18, 0, 0, 0)
      ),
      dataType: "sleep",

      // sampleType: "HKCategoryTypeIdentifierSleepAnalysis",
    };

    HealthKit.query(sleepOptions)
      .then((sleepData) => {
        // alert("s data json is ",JSON.stringify(data));
        // localStorage.setItem("sleep_data_format", JSON.stringify(data));
        let data = formatSleepData(sleepData);

        let stDate, enDate;
        if (new Date().getHours() >= 18) {
          stDate = moment(new Date().setHours(18, 0, 0, 0)).format();
          enDate = moment(
            new Date(Date.now() + 24 * 60 * 60 * 1000).setHours(18, 0, 0, 0)
          ).format();
        } else {
          stDate = moment(
            new Date(Date.now() - 24 * 60 * 60 * 1000).setHours(18, 0, 0, 0)
          ).format();
          enDate = moment(new Date().setHours(18, 0, 0, 0)).format();
        }
        let realData = processSingleDayData(data, stDate, enDate);

        let totalTime = 0;

        totalTime = getExactSLeepingTime(realData);
        // console.log("lol total time is ", totalTime);

        let recordingDate = new Date(
          new Date(Date.now()).setHours(12, 0, 0, 0)
        ).toISOString();
        if (new Date().getHours() >= 18) {
          recordingDate = new Date(
            new Date(Date.now() + 24 * 60 * 60 * 1000).setHours(12, 0, 0, 0)
          ).toISOString();
        }

        let param = {
          patientId: patientProfile?.id,
          time: Math.round(totalTime),
          recordedAt: recordingDate,
        };

        trackerService
          .postSleepData(param)
          .then((res) => {})
          .catch((err) => {});

        if (totalTime === 0 && showNoDataAlert === null) {
          dispatch(showNoDataAlert(true));
        }
      })
      .catch((err) => {});
  };
  const syncStepData = (res) => {
    return new Promise((resolve, reject) => {
      if (
        new Date(res.lastSyncedSteps).toISOString() !==
        new Date(0).toISOString()
      ) {
        let currentDay = Date.now();
        let oneDayDuration = 24 * 60 * 60 * 1000;
        let lastSync = res.lastSyncedSteps;
        let ressteps = [];

        let id = patientProfile?.id;

        let startDate = new Date(lastSync).setHours(0, 0, 0, 1);

        let stepOptions = {
          startDate: new Date(startDate),
          endDate: new Date(currentDay),
          dataType: "steps",
          bucket: "day",
        };

        HealthKit.queryAggregated(stepOptions)
          .then((data) => {
            data.map((ele) => {
              let obj = {
                recordedAt: new Date(
                  new Date(ele.startDate).setHours(12, 0, 0, 0)
                ).toISOString(),
                steps: Math.floor(ele.value),
              };
              ressteps.push(obj);
            });

            let params = {
              steps: ressteps,
            };
            trackerService
              .postSyncedSteps(patientProfile?.id, params)
              .then((res) => {
                resolve();
              })
              .catch((err) => {
                reject();
              });
          })
          .catch((err) => {
            reject();
          });
      } else {
        let currentDay = Date.now();
        let oneDayDuration = 24 * 60 * 60 * 1000;
        let lastSync = new Date(
          Date.now() - 7 * 24 * 60 * 60 * 1000
        ).toISOString();
        let ressteps = [];

        let id = patientProfile?.id;

        let startDate = new Date(lastSync).setHours(0, 0, 0, 1);

        let stepOptions = {
          startDate: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
          endDate: new Date(currentDay),
          dataType: "steps",
          bucket: "day",
        };

        HealthKit.queryAggregated(stepOptions)
          .then((data) => {
            dispatch(
              logEventWithData({
                action: "aggregated_steps",
                data: { data: data },
              })
            );
            data.map((ele) => {
              let obj = {
                recordedAt: new Date(
                  new Date(ele.startDate).setHours(12, 0, 0, 0)
                ).toISOString(),
                steps: Math.floor(ele.value),
              };
              ressteps.push(obj);
            });

            let params = {
              steps: ressteps,
            };
            trackerService
              .postSyncedSteps(patientProfile?.id, params)
              .then((res) => {
                resolve();
              })
              .catch((err) => {
                reject();
              });
          })
          .catch((err) => {});
      }
    });
  };

  const syncSleepData = (res) => {
    return new Promise((resolve, reject) => {
      if (window.Capacitor.platform !== "ios") {
        resolve();
        return;
      }
      if (
        new Date(res.lastSyncedSleep).toISOString() !==
        new Date(0).toISOString()
      ) {
        let oneDayDuration = 24 * 60 * 60 * 1000;
        let currentDay = Date.now() - oneDayDuration;
        let lastSync = res.lastSyncedSleep;
        let ressleep = [];

        let id = patientProfile?.id;

        let sleepOptions = {
          startDate: new Date(
            new Date(lastSync).getTime() - 2 * 24 * 60 * 60 * 1000
          ),
          endDate: new Date(
            new Date(Date.now() + 2 * 24 * 60 * 60 * 1000).setHours(18, 0, 0, 0)
          ),
          dataType: "sleep",
        };

        HealthKit.query(sleepOptions)
          .then((data) => {
            // console.log("data for diff data type lol", data);
            let params = {
              data: formatSleepData(data),
              lastSync: lastSync,
              id: id,
            };

            trackerService
              .postSyncedSleep(patientProfile?.id, params)
              .then((res) => {
                resolve();
              })
              .catch((err) => {
                // console.log("error in querying lol",err);
                reject();
              });
          })
          .catch((err) => {
            // console.log("error in querying lol", err);
            console.log("error !!! something went wrong ", err);
            reject();
          });
      } else {
        let oneDayDuration = 24 * 60 * 60 * 1000;
        let currentDay = Date.now() - oneDayDuration;
        let lastSync = new Date(
          Date.now() - 7 * 24 * 60 * 60 * 1000
        ).toISOString();
        let ressleep = [];

        let id = patientProfile?.id;

        let sleepOptions = {
          startDate: new Date(new Date().getTime() - 10 * 24 * 60 * 60 * 1000),
          endDate: new Date(
            new Date(Date.now() + 2 * 24 * 60 * 60 * 1000).setHours(18, 0, 0, 0)
          ),
          dataType: "sleep",
        };

        HealthKit.query(sleepOptions)
          .then((data) => {
            // console.log("dff data type lol", data);
            let params = {
              data: formatSleepData(data),
              lastSync: lastSync,
              id: id,
            };
            trackerService
              .postSyncedSleep(patientProfile?.id, params)
              .then((res) => {
                resolve();
              })
              .catch((err) => {
                reject();
              });
          })
          .catch((err) => {
            // console.log("error in querying lol", err);
            console.log("error !!! something went wrong", err);
            reject();
          });
      }
    });
  };
  const syncTrackerData = () => {
    return new Promise((resolve, reject) => {
      trackerService
        .getLastSynced(patientProfile?.id)
        .then((res) => {
          if (
            new Date(res.data.lastSyncedSteps).toISOString() ===
            new Date(0).toISOString()
          ) {
            reject();
          } else {
            resolve(res.data);
          }
        })
        .catch((err) => {
          reject();
        });
    });
  };

  const stepAndSleepInitialize = (syncResponse) => {
    if (window.Capacitor.platform !== "web") {
      HealthKit.isAvailable().then((available) => {
        // console.log("lolo available is ", available);
        if (available) {
          // Requesting permissions

          // let options = {
          //   readTypes: [
          //     "HKQuantityTypeIdentifierStepCount",
          //     "HKCategoryTypeIdentifierSleepAnalysis",
          //   ],
          // };

          let optionsForIos = [{ read: ["steps", "sleep"] }];
          let optionsForAndroid = [{ read: ["steps"] }];

          HealthKit.requestAuthorization(
            window.Capacitor.platform === "ios"
              ? optionsForIos
              : optionsForAndroid
          )
            .then((res) => {
              handlePrivacyScreenChanges(true);
              dispatch(toggleTrackingEnabled(true));
              Promise.allSettled = Promise.allSettled || myPromisSettled;
              Promise.allSettled([
                syncStepData(syncResponse),
                syncSleepData(syncResponse),
              ]).finally(() => {
                if (window.Capacitor.platform === "ios") {
                  loadSleepData();
                }

                loadHealthData();
                healthInterval.current = setInterval(() => {
                  loadHealthData();
                }, 30000);
              });
            })
            .catch((err) => {
              console.log("Authorisation not granted", err);
              handlePrivacyScreenChanges(true);
            });
        } else {
          alert("Google fit is not available on this device");
        }
      });
    }
    dispatch(logEvent({ action: "open_home" }));
  };

  return (
    <div className="daily-target-wrapper">
      {/* <p className="daily-target-header">What we have for you today</p> */}
      {/*
       */}
      {/* <p className="daily-target-msg">Build a healthy habit everyday!</p>
      {_createHeaderButtons()} */}

      {loading ? (
        <SkeletonLoader />
      ) : (
        <React.Fragment>
          {window.Capacitor.platform !== "web" && (
            <Steps
              steps={steps}
              target={stepsTarget}
              day={selectedDate}
              trackingEnabled={trackingEnabled}
              initializeStepAndSleep={stepAndSleepInitialize}
            />
          )}
        </React.Fragment>
      )}
    </div>
  );
}

export default DailyTarget;
