import { IonContent, IonPage } from "@ionic/react";
import backImage from "../../assets/images/back.png";
import assesmentImage from "../../assets/images/riskAssement.png";
import frameImage from "../../assets/images/Frame.png";
import dotImage from "../../assets/images/dot.png";
import { Link } from "react-router-dom";

export default function HealthRiskAssesment() {
  return (
    <IonPage>
      <IonContent>
        <div className="p-5">
          <div className="flex items-center ">
            <Link to="/health-assesment">
              <img src={backImage} alt="icon" className="w-6 h-6" />
            </Link>
            <div className="px-3 font-semibold text-base leading-7  ">
              Health risk assessment
            </div>
          </div>
          <div>
            <hr className="bg-gray-200 w-full" />
          </div>
          <div className="w-full py-2">
            <img src={assesmentImage} alt="" className="h-9 w-full" />
            <div className="flex justify-between py-2">
              <div>Basic details</div>
              <div>Schedule</div>
            </div>
            <div>
              <hr className="bg-gray-200 w-full " />
            </div>
          </div>
          <div className="font-bold text-base leading-7  ">Booking for</div>

          <div className="flex justify-between items-center text-center">
            <img src={frameImage} alt="" className="w-10 h-10" />
            <div className="font-bold text-base leading-7">
              Vinit Kausik
              <div className="font-medium text-xs leading-4">32, self</div>
            </div>
            <img src={dotImage} alt="" className="w-4 h-4" />
          </div>

          <div className="flex justify-center pt-80">
            <div className="text-zburgundy font-bold text-sm leading-5">
              Add a family member
            </div>
          </div>

          <div className="flex justify-center  pt-5">
            <button
              type="submit"
              className="w-full bg-zburgundy text-white p-4 text-xl leading-7  font-bold rounded-full"
            >
              Continue
            </button>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}
