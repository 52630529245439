import React, { Component, useEffect, useRef, useState } from "react";
import { withRouter, Link, useLocation, useHistory } from "react-router-dom";
import AuthenticationService from "../../services/AuthenticationService";
import {
  IonPage,
  IonButton,
  IonContent,
  withIonLifeCycle,
  IonLoading,
  NavContext,
} from "@ionic/react";

import upImage from "../../assets/images/upload.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import DocumentPreview from "../documents/components/documentPreview";
import BackButton from "../../components/common/BackButton";
import ArrowForward from "../../assets/images/ic_arrow_forward.png";
import ImageIcon from "../../assets/icons/ic_image.png";
import PDFIcon from "../../assets/icons/ic_pdf.png";
import uploadImg from "../../assets/images/upload.svg";
import homeIcon from "../../assets/icons/home-selected.png";
import workIcon from "../../assets/icons/address-office-icon.svg";
import otherIcon from "../../assets/icons/address-others-icon.svg";
import plusIcon from "../../assets/images/ic_plus_button_primary_2z.svg";
import MaleActive from "../../assets/images/ic_male_active.png";
import FemaleActive from "../../assets/images/ic_female_active.png";
import GreenSuccessIcon from "../../assets/images/greenSuccess.png";
import StorageService from "../../services/StorageService";
import documentService from "../../services/DocumentService";
import backImage from "../../assets/images/arrow-back.png";
import orederMedicineImage from "../../assets/images/order-medicine-banner.png";
import "./index.scss";
import { DocumentChooseAndUpload } from "../documents/components/documentChooseAndUpload";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  makeStyles,
  Snackbar,
  Typography,
} from "@mui/material";
import FamilyPlanServices from "../../services/FamilyPlanServices";
import SecureImage from "../../components/common/SecureImage";
import { useDispatch, useSelector } from "react-redux";
import { getUrlWithToken } from "../../utils/helperMethods";
import { getFamilyData, getFamilyRelations } from "../familyInfo/familySlice";
import ErrorComponent from "../../components/common/ErrorComponent";
import { setMedicineAddress } from "./medicineSlice";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
function MedicineOrderComponent() {
  const user = useSelector((store) => store.login.user);
  const familyStore = useSelector((store) => store.familyInfo);
  const medicineStore = useSelector((store) => store.medicine);
  const platform = useSelector((state) => state.app.device.platform);
  const token = useSelector((store) => store.tab.docToken?.token);
  // const medicineAddress = useSelector((store) => store.medicine.medicineAddress);
  const [nodata, setNoData] = useState(false);
  const [memberSelected, setMemberSelected] = useState({});
  const [memberData, setMemberData] = useState({});
  const [loading, setLoading] = useState(true);
  const [showFamilyMember, setShowFamilyMember] = useState(false);
  const [fetchingData, setFetchingData] = useState(1);
  const [isApp, setIsApp] = useState(
    window.Capacitor.platform === "web" ? false : true
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [hasError, setHasError] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [order, setOrder] = useState({});
  const [faq, setFaq] = useState([]);
  const [showFAQs, setShowFAQs] = useState(false);
  const [showFinalModal, setShowFinalModal] = useState(false);
  const [document, setDocument] = useState(null);
  const [memberPatientId, setMemberPatientId] = useState(-1);
  const [memberEmail, setMemberEmail] = useState(null);
  const [vendorInfo, setVendorInfo] = useState(null);
  const [address, setAddress] = useState(null);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const memberRef = useRef(null);
  const {
    loadedFamily,
    fetchingFamily,
    loadedRelation,
    fetchingRelations,
    familyData,
  } = familyStore;
  const { medicineAddress } = medicineStore;
  useEffect(() => {
    // console.log(
    //   "loaded family and relation",
    //   loadedFamily,
    //   loadedRelation,
    //   familyStore
    // );
    if (loadedFamily && loadedRelation) {
      medicineOrder();
      handleComponentDidMountFunctionality();
    } else {
      if (!loadedFamily && !fetchingFamily) {
        dispatch(getFamilyData());
      }
      if (!loadedRelation && !fetchingRelations) {
        dispatch(getFamilyRelations());
      }
    }
  }, [loadedFamily, loadedRelation, fetchingFamily, fetchingRelations]);

  useEffect(() => {
    if (medicineAddress) {
      setAddress(medicineAddress);
    }
  }, [medicineAddress]);

  const handleComponentDidMountFunctionality = () => {
    const { state } = location;
    let addr = null;
    let memberPId = -1;
    let memberEmail = null;
    let memberData = {};

    if (state && state.isMember) {
      memberPId = state.memberPatientId;
      memberEmail = state.memberEmail;
      memberData = { ...state.memberData };
      if (
        memberData &&
        memberData.countryCode &&
        memberData.countryCode != "91"
      ) {
        window.location.href = "/tab/chat";
      }
      handleGetTokenAndSetState(
        memberPId,
        addr,
        memberEmail,
        memberData,
        false,
        true
      );
    } else {
      if (
        user &&
        user.patientProfile &&
        user.patientProfile.countryCode != "91"
      ) {
        window.location.href = "/tab/chat";
      }

      handleGetTokenAndSetState(
        memberPId,
        addr,
        memberEmail,
        memberData,
        false,
        true
      );
    }
  };
  // const componentWillReceiveProps = () => {
  //   const { state } = location;
  //   let address = null;
  //   let memberPatientId = this.state.memberPatientId;
  //   let memberEmail = this.state.memberEmail;
  //   let memberData = this.state.memberData;

  //   if (state && state.addressSelected) {
  //     address = { ...state.address };
  //   }

  //   if (state && state.isMember) {
  //     memberData = { ...state.memberData };
  //     memberEmail = state.memberEmail;
  //     memberPatientId = state.memberPatientId;
  //   }

  //   let newfamilyData = this.state.familyData.map((fd) => {
  //     return { ...fd };
  //   });

  //   if (
  //     state &&
  //     state.isMemberSelected &&
  //     state.memberData.memberPatientId !== this.memberRef.current
  //   ) {
  //     if (state.memberData && state.memberData.countryCode == "91") {
  //       newfamilyData.push({ ...state.memberData });
  //     }
  //     this.memberRef.current = state.memberData.memberPatientId;
  //   }

  //   this.documentService.getToken((token) => {
  //     this.setState({
  //       token: token,
  //       document: this.storageService.get("document"),
  //       address: address,
  //       familyData: newfamilyData,
  //       memberData: memberData,
  //       memberPatientId: memberPatientId,
  //       memberEmail: memberEmail,
  //     });
  //   });
  // };

  const handleGetTokenAndSetState = (
    memberPId,
    addr,
    memberEmail,
    memberData,
    showFamilyMember,
    memberSelected
  ) => {
    setDocument(null);
    setMemberPatientId(memberPId);
    setMemberEmail(memberEmail);
    setMemberData(memberData);
    setLoading(false);
    setShowFamilyMember(showFamilyMember);
    setMemberSelected(memberSelected);
    setAddress(null);
    dispatch(setMedicineAddress({ address: addr }));
  };

  const medicineOrder = () => {
    let vendorInfo;

    documentService
      .getMedicineOffer()
      .then((res) => {
        const response = res.data;
        vendorInfo = Object.values(Object.values(response)[0])[0];

        setVendorInfo(vendorInfo);
        setFaq([...Object.values(vendorInfo.faq)]);
        setShowFAQs(true);
      })
      .catch((err) => {
        console.log("error  catch in medicine order", err);
        // setShowFAQs(false);
      });
  };

  const openFinalModal = async (showFinalModal) => {
    if (showFinalModal) {
      // this.setState({ loading: true });
      if (vendorInfo && vendorInfo.vendor) {
        setLoading(true);

        let email = user.patientProfile?.email;
        if (memberPatientId !== -1) {
          let rel = memberData?.relation;
          if (rel && rel != 6 && rel != 8) {
            email = memberEmail;
          }
        }
        let addr = `${address.line1},${address.line2},${address.city},${address.district}, ${address.state}`;

        let order = {
          patientId:
            memberPatientId != -1 ? memberPatientId : user.patientProfile?.id,
          vendor: vendorInfo.vendor,
          address: addr,
          prescription: document.url,
          patientEmail: email,
          vendorEmail: vendorInfo.vendorEmail,
          thumbnail: document.thumbnail,
          fileFormat: `${document.type}`,
        };
        documentService
          .postMedicineOffer(order)
          .then(() => {
            setLoading(false);
            setAddress(null);
            setDocument(null);
            setMemberPatientId(-2);

            dispatch(setMedicineAddress({ address: null }));
            history.replace("/ordercomplete", {
              direction: "forward",
            });
          })
          .catch(() => {
            setLoading(false);
          });
      } else {
        setHasError(true);
        setErrorMessage("Something went wrong.");
      }
    } else {
      setShowFinalModal(showFinalModal);
      setLoading(false);
    }
  };
  const handleProfileSelect = (arg, member = null) => {
    if (arg === "self") {
      setMemberPatientId(-1);
      setMemberSelected(true);
      setShowFamilyMember(false);
      setMemberEmail("");
      setMemberData({});
    } else if (arg === "add") {
      history.push({
        pathname: "/family",
        state: { isMedicine: true, isPath: true, path: "/medicine" },
      });
    } else {
      setMemberPatientId(member.memberPatientId);
      setMemberSelected(true);
      setShowFamilyMember(false);
      setMemberEmail(member.email);
      setMemberData({ ...member });
    }
  };

  return (
    <IonPage>
      <IonContent>
        {loading ? (
          <CircularProgress className="ion-router-circular-progress "></CircularProgress>
        ) : (
          <React.Fragment>
            <ErrorComponent
              show={hasError}
              msg={errorMessage}
              errorClose={() => {
                setHasError(false);
              }}
            />

            <div id="main-wrapper">
              <div className="modal-wrapper">
                <div className="modal-upper-section">
                  <div id="text-wrapper"></div>
                  <div id="upper-wrapper">
                    <div className="doc-wrapper">
                      {/*  {document && (
                        <DocumentPreview
                          detail={false}
                          internal={false}
                          document={document}
                          redirect="medicine"
                        ></DocumentPreview>
                      )} */}

                      <DocumentChooseAndUpload
                        onUploadStart={() => {
                          setShowUpload(false);
                          setUploading(true);
                          setHasError(false);
                          setErrorMessage("");
                        }}
                        onUploadDone={(res) => {
                          setShowUpload(false);
                          setUploading(false);
                          setHasError(false);
                          setErrorMessage("");

                          if (res && res.status === "S") {
                            setDocument(res.result);
                          } else {
                            setHasError(true);
                            setErrorMessage(
                              "Please upload prescription within allowed size limit."
                            );
                          }
                        }}
                        fileChooser={showUpload}
                        hideFileTypeOption={() => {
                          setShowUpload(false);
                        }}
                        patientId={
                          memberPatientId != -1
                            ? memberPatientId
                            : user.patientProfile?.id
                        }
                      ></DocumentChooseAndUpload>
                    </div>
                  </div>
                </div>
                <div className="p-4">
                  <div className="flex items-center ">
                    <BackButton className="w-6 h-6" />
                    <div className="px-3 font-bold text-base leading-7  ">
                      Order medicines
                    </div>
                  </div>
                  <div className="pt-8">
                    <img src={orederMedicineImage} className="w-full h-32" />
                    <div className=" w-full h-8  text-white p-2 -mt-1 text-xs font-bold text-center bg-mliblue rounded-b-md">
                      Save upto 20% on prescription medicines
                    </div>
                  </div>
                  {!document && (
                    <div
                      className="pres-upload"
                      onClick={() => {
                        setShowUpload(true);
                      }}
                    >
                      <div className="outline outline-gray-100 outline-1 mt-8 p-4 rounded-md flex justify-around items-center">
                        <img src={upImage} className="h-10 w-10" />
                        <div className="text-base font-normal leading-6">
                          Upload latest prescription <br />
                          by doctor
                          <span className="font-normal italic text-xs text-gray-500">
                            (max size 20MB)
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                  {loading && (
                    <div className="pres-upload relative">
                      <div className="outline outline-gray-100 outline-1 mt-8 rounded-md flex justify-around items-center">
                        <div className="flex-1 bg-emerald-500 h-20"></div>
                        <div className="flex-1"></div>
                      </div>
                      <div className="uploading-text">
                        <div className="font-semibold text-lg">
                          Uploading prescription...
                        </div>
                      </div>
                    </div>
                  )}
                  {document && (
                    <div
                      className="pres-upload"
                      onClick={() => {
                        setShowUpload(true);
                      }}
                    >
                      <div className="outline outline-gray-100 outline-1 mt-8 p-4 rounded-md flex justify-around items-center">
                        <img src={GreenSuccessIcon} className="h-10 w-10" />
                        <div className="text-base  text-gray-500 font-medium leading-6">
                          Prescription Uploaded
                          <br />
                          <div className="text-blue-500 underline">
                            Change Prescription
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {address && (
                    <div className="address-book-element-medicine">
                      <div className="address-book-element-top-medicine">
                        {/* <img
                          src={
                            address.type === "Home"
                              ? homeIcon
                              : address.type === "Work"
                              ? workIcon
                              : otherIcon
                          }
                          className="w-10 h-10 address-book-element-top-type-medicine"
                        /> */}
                        <h6>Delivery address</h6>
                        {/* <p className="address-book-element-top-name-medicine">
                          {address.name}
                        </p> */}
                      </div>
                      <div className="address-book-element-bottom-medicine">
                        <p>
                          {`${address.line1},${address.line2} ${
                            address.landmark ? address.landmark : ""
                          }`}
                          <br />
                          {`${address.district},${address.city},${address.state}-${address.pincode}`}
                        </p>
                        <p>Phone number- {address.phoneNo}</p>
                      </div>
                    </div>
                  )}
                  {!address && (
                    <button
                      className={` w-full  text-white p-4 text-2xl  mt-24 font-semibold rounded-full ${
                        document ? "bg-zburgundy" : "bg-gray-300"
                      }`}
                      onClick={() => {
                        if (document) {
                          history.push({
                            pathname: "/address",
                            state: {
                              isMedicine: true,
                              isMember: memberPatientId != -1 ? true : false,
                              memberPatientId: memberPatientId,
                              memberEmail: memberEmail,
                              memberData: { ...memberData },
                              isPath: true,
                              path: "/medicine",
                            }, // your data array of objects
                          });
                        } else {
                          setHasError(true);
                          setErrorMessage("Please upload document.");
                        }
                      }}
                    >
                      Continue
                    </button>
                  )}
                  {address && (
                    <button
                      className={` w-full  text-white p-4 text-2xl text-center  mt-10 font-semibold rounded-full ${
                        document ? "bg-zburgundy" : "bg-gray-300"
                      }`}
                      onClick={() => {
                        if (document) {
                          openFinalModal(true);
                        } else {
                          setHasError(true);
                          setErrorMessage("Please upload document.");
                        }
                      }}
                    >
                      Place order
                    </button>
                  )}
                </div>
                {/* {!address && (
                  <div id="modal-bottom-section">
                    <div
                      id="accordian-wrapper"
                      className="medicine-order-accordian-wrapper"
                    >
                      {faq &&
                        faq.length > 0 &&
                        faq.map((element) => (
                          <Accordion className="medicine-order-accordian-wrapper">
                            <AccordionSummary
                              expandIcon={
                                <span>
                                  <FontAwesomeIcon icon={faChevronDown} />
                                </span>
                              }
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography>{element.question}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography>{element.answer}</Typography>
                            </AccordionDetails>
                          </Accordion>
                        ))}
                    </div>
                  </div>
                )} */}
              </div>
            </div>

            {showFinalModal && (
              <div id="main-wrapper">
                <div className="modal-wrapper" id="final-div-wrapper">
                  <div id="final-div">
                    {/* <button
                    onClick={() => {
                      this.setFinalModal(false);
                      this.props.history.push("/tab/chat");
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      className="back-icon-fa"
                    />
                  </button> */}
                    <div className="final-div-text-wrapper">
                      <p> Your order has been placed successfully.</p>
                      <span>
                        You will receive a call from our medicine partner to
                        discuss the order with you.
                      </span>
                    </div>
                  </div>
                </div>
                <IonButton
                  onClick={() => {
                    history.replace("/tab/chat", {
                      direction: "forward",
                    });
                  }}
                >
                  Go To Chat
                </IonButton>
              </div>
            )}
            {showFamilyMember && (
              <div className="medicine-order-family-container">
                <div className="medicine-order-family-container-main">
                  <p className="medicine-order-family-container-main-heading">
                    Who are you ordering for?
                  </p>
                  {/* {fetchingData === 1 && (
                    <CircularProgress className="medicine-order-family-container-main-loader"></CircularProgress>
                  )} */}
                  {/* {fetchingData === 2 && ( */}
                  <div className="medicine-order-container-main-list">
                    <div
                      className="medicine-order-container-main-list-ele"
                      onClick={() => handleProfileSelect("self")}
                    >
                      <SecureImage
                        src={user.patientProfile?.profileImage}
                        defaultImage={
                          user.patientProfile?.gender == 1
                            ? FemaleActive
                            : MaleActive
                        }
                      />
                      <p>Self</p>
                    </div>
                    {familyData.map((fd) => {
                      if (fd.countryCode != "91") {
                        return null;
                      }
                      return (
                        <div
                          className="medicine-order-container-main-list-ele"
                          key={fd.id}
                          onClick={() => handleProfileSelect("member", fd)}
                        >
                          <SecureImage
                            src={fd.profilePicURL}
                            defaultImage={
                              fd.gender == 1 ? FemaleActive : MaleActive
                            }
                          />
                          <p>{`${fd.firstName} ${
                            fd.lastName ? fd.lastName : ""
                          }`}</p>
                        </div>
                      );
                    })}
                    <div
                      className="medicine-order-container-main-list-ele"
                      onClick={() => handleProfileSelect("add")}
                    >
                      <img src={plusIcon} />
                      <p>Add Member</p>
                    </div>
                  </div>
                  {/* )} */}
                  <NavContext.Consumer>
                    {(conValue) => (
                      <button
                        onClick={() => {
                          conValue.goBack("/");
                        }}
                        className="medicine-order-container-main-button"
                      >
                        Cancel
                      </button>
                    )}
                  </NavContext.Consumer>
                </div>
              </div>
            )}
          </React.Fragment>
        )}
      </IonContent>
    </IonPage>
  );
}

export default MedicineOrderComponent;
