import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import chcIcon from "../../../../../assets/icons/chcIcon.svg";
import hraIcon from "../../../../../assets/icons/hraIcon.svg";
import mhcIcon from "../../../../../assets/icons/mhcIcon.svg";
import stepIcon from "../../../../../assets/icons/stepIcon.svg";
import arrowAhedImage from "../../../../../assets/images/forwardRedz.svg";
import messageImage from "../../../../../assets/images/message-circle.png";
import pointsImage from "../../../../../assets/images/points.png";
import "../rewardAnswerCard.scss";

export const SewaAnswerCard = ({ selectedPolicy }) => {
  const policyData = selectedPolicy.data;

  const settings = {
    dots: false,
    infinite: false,
    speed: 400,
    slidesToShow: 2,
    slidesToScroll: 1,
    // centerPadding: '100px'
  };

  const data = [
    {
      text: "Earned",
      heading: "Health risk assessment",
      img: hraIcon,
      points: "  3000 points",
      policy: policyData.hraRewardPointEarned,
    },
    {
      text: "Earned",
      heading: "Complimentary health checkup",
      img: chcIcon,
      points: "10000 points",
      policy: policyData.chcRewardPointEarned,
    },
    {
      text: "Earned",
      heading: "Daily step count",
      img: stepIcon,
      points: "  12000 points",
      policy: policyData.StepsRewardPointEarned,
    },
    {
      text: "Earned",
      heading: " Monthly health challenges",
      img: mhcIcon,
      points: "     18000 points",
      policy: policyData.mhcRewardPointEarned,
    },
  ];

  function AnswerCard({ elem }) {
    return (
      <>
        <div
          className={`flex-1 rounded-md shadow-md h-36 " ${
            elem.policy
              ? "bg-[#E3E3E399]"
              : "bg-gradient-to-r from-[#B7C6DC] to-[#fcfaf9] ring-1 ring-[#B7C6DC]"
          }`}
          style={{ width: "200px" }}
        >
          <div className={elem.policy ? "p-2 text-gray-300 " : "p-3"}>
            <img
              className="mb-2"
              src={elem.img}
              alt=""
              // style={{ width: "52px", height: "52px" }}
            />
            {elem.policy ? (
              <>
                <div className="flex bg-gray-300 text-gray-500 w-full font-medium p-1 px-2  justify-center text-xs">
                  {elem.text}
                </div>
                <div className="text-xs">{elem.heading}</div>
              </>
            ) : (
              <div className="font-bold text-xs text-[#171A21]">
                {elem.heading}
              </div>
            )}

            <div
              className={
                elem.policy
                  ? "text-xs mt-1 font-semibold"
                  : "text-zburgundy text-xs mt-1 font-semibold"
              }
            >
              {elem.points}
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <div>
      <div className="font-bold text-base leading-5 mb-3 pt-5">
        Earn more reward points
      </div>
      <div className="container text-lightblack ">
        <Slider {...settings}>
          {data.map((elem) => {
            return (
              <div className="slide-reward">
                <AnswerCard elem={elem} />
              </div>
            );
          })}
        </Slider>
      </div>
      <div className="mt-5">
        <div className="font-bold text-base leading-5  mb-4 text-lightblack">
          Looking for answers?
        </div>
        <div className="flex flex-col gap-y-2">
          <Link to="/rewardPointLedger" className="no-underline">
            <div className=" flex  justify-between items-start text-center ">
              <div className="flex items-start gap-x-3">
                <img src={pointsImage} alt="" className="w-4 h-4 " />
                <div className="flex flex-col items-start gap-y-2">
                  <span className="text-lightblack font-bold text-xs leading-5">
                    Reward points history
                  </span>
                  <div className=" font-medium text-xs text-lightblack leading-3">
                    Details of your points usages
                  </div>
                </div>
              </div>

              <img src={arrowAhedImage} alt="" className="h-6 w-6" />
            </div>
          </Link>

          <Link to="/faqs" className="no-underline text-lightblack ">
            <div className=" flex  justify-between items-start text-center ">
              <div className="flex items-start gap-x-3">
                <img src={messageImage} alt="" className="w-4 h-4 " />
                <div className="flex flex-col items-start gap-y-2">
                  <span className="text-lightblack font-bold text-xs leading-5">
                    FAQs
                  </span>
                  <div className=" font-medium text-xs text-lightblack leading-3">
                    Frequently asked questions
                  </div>
                </div>
              </div>

              <img src={arrowAhedImage} alt="" className="h-6 w-6" />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};
