import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router";
import "./vitals_charts.scss";
import arrowBlack from "../../assets/icons/arrow-back-black.svg";
import next_button from "../../assets/images/forwardRedz.svg";
import previous_button from "../../assets/images/back_red.png";
import { IonPage, IonContent, IonSpinner } from "@ionic/react";
import VitalsTabs from "./components/vitalsTabs.js";
import LineGraph from "./components/vitalsLineChart.js";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import ErrorComponent from "../../components/common/ErrorComponent";
import NoVitalsShared from "./components/noVitalsShared";
import VitalsChartAverage from "./components/vitalsChartAverage";
import { logEvent } from "../../app/eventSlice";
import {
  getVitalsParams,
  updateVitalChartError,
  updateVitalChartTime,
} from "./vitalsChartSlice";

export default function VitalsChart() {
  const paytmFlag = useSelector((store) => store.app.device.isPaytm);
  const patientProfile = useSelector(
    (store) => store.login.user.patientProfile
  );
  const showChart = useSelector((store) => store.vitalsChart.ui.showChart);
  const loading = useSelector((store) => store.vitalsChart.ui.loading);
  const selectedEndTime = useSelector(
    (store) => store.vitalsChart.ui.selectedEndTime
  );
  const selectedStartTime = useSelector(
    (store) => store.vitalsChart.ui.selectedStartTime
  );
  const error = useSelector((store) => store.vitalsChart.ui.error);
  const errorMsg = useSelector((store) => store.vitalsChart.ui.errorMsg);
  const paramGroups = useSelector((store) => store.vitalsChart.vitalParams);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(
      getVitalsParams({
        patientId: patientProfile.id,
        groupId: window.location.search.substring(1),
      })
    );
  }, []);

  const renderDisplay = useCallback((selectedStartTime, selectedEndTime) => {
    return (
      moment(selectedStartTime).format("DD MMM") +
      " - " +
      moment(selectedEndTime).format("DD MMM")
    );
  }, []);

  const onClickPrevious = () => {
    changeDateRange(true);
  };
  const onClickNext = () => {
    changeDateRange(false);
  };
  const changeDateRange = (back) => {
    let newStartDate = selectedStartTime;
    let newEndDate = selectedEndTime;
    if (back) {
      newEndDate = moment(selectedStartTime).subtract(1, "days");
      newStartDate = moment(newEndDate).subtract(13, "days");
    } else {
      newStartDate = moment(selectedEndTime).add(1, "days");
      newEndDate = moment(newStartDate).add(13, "days");
    }

    dispatch(
      updateVitalChartTime({
        selectedStartTime: moment(newStartDate).toDate().valueOf(),
        selectedEndTime: moment(newEndDate).toDate().valueOf(),
      })
    );
  };
  const handleBackClick = () => {
    if (history.location.pathname === "/tab/home") {
      dispatch(logEvent({ action: "Click_back_Vital_charts_Home" }));
    }
    history.goBack();
  };

  return (
    <IonPage>
      <IonContent>
        <ErrorComponent
          show={error}
          msg={errorMsg}
          errorClose={() => {
            dispatch(updateVitalChartError({ value: false, msg: "" }));
          }}
        />
        {loading && (
          <div className="loading-wrapper">
            <IonSpinner />
          </div>
        )}
        {!loading && (
          <div style={{ height: "100%" }}>
            <div className="blue_box">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {!paytmFlag && (
                  <img
                    src={arrowBlack}
                    onClick={() => handleBackClick()}
                    className="back_arrow"
                    alt="icon"
                  ></img>
                )}
                <span className="vital-chart-span">My vitals</span>
              </div>
              {paramGroups && <VitalsTabs></VitalsTabs>}
            </div>

            {showChart && (
              <div className="chart_box">
                <div className="date_section">
                  <img
                    src={next_button}
                    onClick={() => onClickPrevious()}
                    className="date_button rotate-180"
                    alt="icon"
                  ></img>
                  <p> {renderDisplay(selectedStartTime, selectedEndTime)}</p>
                  <img
                    src={next_button}
                    onClick={() => onClickNext()}
                    className="date_button"
                    alt="icon"
                  ></img>
                </div>
                <LineGraph></LineGraph>
              </div>
            )}

            {showChart && <VitalsChartAverage />}

            {!showChart && <NoVitalsShared />}
          </div>
        )}
      </IonContent>
    </IonPage>
  );
}
