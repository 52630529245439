import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import {
  IonPage,
  IonHeader,
  IonContent,
  IonDatetime,
  IonToolbar,
  IonButtons,
} from "@ionic/react";

import DownArrowImage from "../../../assets/images/icon_chevron_downz.svg";
import NoReportingIcon from "../../../assets/images/no_reporting.png";
import { Divider } from "@mui/material";
import { useHistory } from "react-router-dom";
import "./submitted_reporting.scss";
import reportingService from "../../../services/ReportingService";
import BackButton from "../../../components/common/BackButton";
import ErrorBox from "../../../components/common/error-box";
import { useDispatch, useSelector } from "react-redux";
import { updateAndSaveReports } from "../reportSlice";
import { logEvent } from "../../../app/eventSlice";

export default function SubmittedReporting() {
  const patientProfile = useSelector(
    (store) => store.login.user.patientProfile
  );
  const paytmFlag = useSelector((store) => store.app.device.isPaytm);
  const reports = useSelector((store) => store.reporting.reports);
  const [items, setItems] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    moment().format("DD MMM YYYY")
  );
  const [currentDate, setCurrentDate] = useState(moment().format("YYYY-MM-DD"));
  const [loading, setLoading] = useState(false);
  const [changeFire, setChangeFire] = useState(true);
  const [hasError, setHasError] = useState(false);
  const dispatch = useDispatch();
  const dateRef = useRef(null);
  const history = useHistory();

  useEffect(() => {
    fetchReportingData(moment(currentDate).format("DD-MM-YYYY"), true);
  }, []);

  const onChangeDate = (e) => {
    if (moment(e.detail.value).isValid()) {
      fetchReportingData(moment(e.detail.value).format("DD-MM-YYYY"), false);
    }
  };
  const fetchReportingData = (date, initial) => {
    if (reports[date]) {
      setItems(reports[date].data);
    } else {
      setLoading(true);
      reportingService
        .getReportsByDate(date, patientProfile.id)
        .then((res) => {
          let response = reportingService._postProcessResponse(res.data);

          if (response && response.length > 0) {
            setItems(response);

            dispatch(
              updateAndSaveReports({
                date: date,
                report: {
                  data: response,
                  _id: res.data._id,
                },
              })
            );
            setLoading(false);
          } else {
            setItems([]);
            setLoading(false);
          }
        })
        .catch((err) => {
          setItems([]);
          setLoading(false);
          setHasError(false);
        });
    }
  };

  return (
    <IonPage className="submitted-reporting">
      <IonContent>
        <IonHeader className="heading">
          <div className="page-head">
            {!paytmFlag && (
              <IonButtons slot="start">
                <div className="button-wrapper">
                  <BackButton history={history}></BackButton>
                </div>
              </IonButtons>
            )}

            <div className="fwbold reporting-header">
              <small>Routine Reporting</small>
              <div className="reporting-date">
                <IonDatetime
                  ref={(ref) => (dateRef.current = ref)}
                  onIonChange={(e) => {
                    onChangeDate(e);
                  }}
                  placeholder={selectedDate}
                  max={currentDate}
                ></IonDatetime>
                <img
                  className="img-down"
                  alt="img-down"
                  onClick={() => {
                    dateRef.current?.open();
                  }}
                  src={DownArrowImage}
                />
              </div>
            </div>
          </div>
          <Divider />
        </IonHeader>
        {hasError && <ErrorBox />}
        {items && items.length > 0 && (
          <div className="reporting-detail">
            <table>
              <thead>
                <tr className="table-header">
                  <td colSpan="2">Time</td>
                  <td>Activity</td>
                </tr>
              </thead>
              <tbody>
                {items.map((value) => {
                  return (
                    <tr className="table-row">
                      <td>
                        {value.hour}&nbsp;:&nbsp;{value.minute}
                      </td>
                      <td>{value.dayNight}</td>
                      <td>{value.activity}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        {items && items.length === 0 && (
          <div className="no-reporting-container">
            <img src={NoReportingIcon} alt="icon" />
            <div className="display-message">
              You have not reported for the day yet!
            </div>
            <div
              onClick={() => {
                history.replace("/reporting?q=redirect");
                dispatch(
                  logEvent({
                    action: "click_reporting_tour_start",
                  })
                  // console.log("click_reporting_tour_start")
                );
              }}
              className="button"
            >
              Start Reporting
            </div>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
}
