import React, { useEffect } from "react";
import { IonAlert, IonContent, IonPage } from "@ionic/react";
import { CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchIntroImages,
  toggleNoUserAlert,
  updateErrorState,
  updateLoginAndRegister,
} from "./loginSlice";
import IntroCarousel from "../../components/introCarousel/introCarousel";
import "./login.scss";
import LoginPhone from "./components/phone";
import LoginOTP from "./components/otp";

import ErrorComponent from "../../components/common/ErrorComponent";
import { useHistory } from "react-router";
import { logEventWithData } from "../../app/eventSlice";

export default function LoginPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const loginStore = useSelector((state) => state.login);
  const country = useSelector((state) => state.app.country);
  const { user, introImages, ui } = loginStore;

  useEffect(() => {
    console.log("", ui);
    if (ui.notEligible) {
      history.push("/eligibility-check");
    }
  }, [ui.notEligible]);

  useEffect(() => {
    dispatch(fetchIntroImages());
    dispatch(updateLoginAndRegister());
    const userAgent = navigator.userAgent;
    dispatch(
      logEventWithData({
        action: "login_page_open",
        data: {
          Timestamp: Date.now(),
          device: userAgent,
        },
      })
    );
  }, []);

  // useEffect(() => {
  //   if (token) {
  //     if (user && user.patientProfile) {
  //       dispatch(
  //         updateUserAndPatientProfile({
  //           data: user,
  //           token: token,
  //         })
  //       );
  //       history.replace("/testimonials");
  //     } else {
  //       history.replace("/register");
  //     }
  //   }
  // }, [token, user, history]);

  const revereseErrorUpdates = () => {
    dispatch(updateErrorState({ data: false, msg: "" }));
  };

  return (
    <IonPage>
      <IonContent>
        <div className="login-container">
          {/* <div className="login-info-carousel">
            <IntroCarousel images={introImages} loading={ui.lodingImage} />
          </div> */}
          <div className="login-form-parent">
            <div className="login-form">
              <LoginPhone
                defaultPhoneNo={user?.phoneNo}
                country={country.default}
              />

              {ui && ui.loading && (
                <div className="login-loading-wrapper">
                  <CircularProgress />
                </div>
              )}
            </div>
          </div>
        </div>
        <ErrorComponent
          show={ui.error}
          msg={ui.errMsg}
          isPositive={ui.isErrorPositive}
          isBottom={ui.isErrorPositive}
          errorClose={revereseErrorUpdates}
        />
        {ui.showNoUserAlert && (
          <IonAlert
            className="ion-alert-login"
            isOpen={ui.showNoUserAlert}
            onDidDismiss={() => dispatch(toggleNoUserAlert(false))}
            message={
              "You are currently not allowed to access the Max Fit app. Kindly reach out to Axis Max Life customer support at service.helpdesk@maxlifeinsurance.com"
            }
            buttons={[
              {
                text: "OK",
                cssClass: "no_data_alert_ok",
                handler: () => {
                  dispatch(toggleNoUserAlert(false));
                },
              },
            ]}
          />
        )}
      </IonContent>
    </IonPage>
  );
}
