import { IonProgressBar } from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
const rewardProgressDots = [1, 2, 3, 4];
const rewardProgresslabels = [0, 13, 27, 36];
export const CidrRewardProgressCard = ({ selectedPolicy }) => {
  const policyData = selectedPolicy?.data;

  const status = useSelector((state) => state.reward.status);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [dot2X, setDot2X] = useState();
  const [dot3X, setDot3X] = useState();
  const [dot4X, setDot4X] = useState();

  const dotContainer = useRef();
  useEffect(() => {
    let dot2 = (13 / 42) * 100;
    let dot3 = (27 / 42) * 100;
    let dot4 = (36 / 42) * 100;
    setDot2X(dot2);
    setDot3X(dot3);
    setDot4X(dot4);
  }, []);
  let value = policyData?.hwc / 42;

  return (
    <>
      <div
        className="outline rounded-lg"
        style={{ outlineColor: "#95ADCF", outlineWidth: "1px" }}
      >
        <div className="p-3 relative level-progress">
          <div className="relative">
            <IonProgressBar
              value={value}
              className="h-2 rounded-full"
            ></IonProgressBar>
            <div
              className=" level-progress-dots-container flex justify-between "
              ref={dotContainer}
            >
              {rewardProgressDots.map((dots, ind) => {
                let style = {};

                if (ind === 1) {
                  style = { left: `${dot2X}%` };
                } else if (ind === 2) {
                  style = { left: `${dot3X}%` };
                } else if (ind === 3) {
                  style = { left: `${dot4X}%` };
                } else if (ind === 4) {
                  style = { right: `0px` };
                }
                return (
                  <div key={dots} className="level-progress-dots" style={style}>
                    {dots === 1 && (
                      <div className="flex font-medium text-xs leading-10 justify-between ">
                        0
                      </div>
                    )}
                    {dots === 2 && (
                      <div className="flex font-medium text-xs leading-10 justify-center ">
                        13
                      </div>
                    )}
                    {dots === 3 && (
                      <div className="flex font-medium text-xs leading-10 justify-center ">
                        27
                      </div>
                    )}
                    {dots === 4 && (
                      <div className="flex font-medium text-xs leading-10 justify-center ">
                        36
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <hr
          className=" w-[95%] "
          style={{ backgroundColor: "#95ADCF", marginTop: "30px" }}
        />

        <div className="p-3 pb-4 flex flex-col text-center justify-between">
          <div className="flex  items-center justify-between">
            <div className="font-medium text-xs mb-3">
              Next target to level up
            </div>
            <div className="font-medium text-xs mb-3">Days left to achieve</div>
          </div>
          <div className="flex justify-between">
            <div className="md:px-8 px-5 py-1 font-bold text-xs rounded-lg bg-gradient-to-r from-[#95ADCF] to-[#f9f9fc] ring-1 ring-[#95ADCF]">
              {policyData?.weeksNeededForNextLevel}

              <span>&nbsp; Weeks</span>
            </div>
            <div className="font-bold text-xs md:px-8 px-5 py-1 rounded-lg bg-gradient-to-r from-[#95ADCF] to-[#f9f9fc] ring-1 ring-[#95ADCF]">
              <span>{policyData?.daysLeftToComplete}</span>
              <span>&nbsp;Days</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
