import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useIonViewDidEnter } from "@ionic/react";
import "./VitalSubmission2.scss";
import BPMonitorIcon from "../../../assets/icons/bp-monitor-icon-small.png";
import SubmitIcon from "../../../assets/icons/orange-circle-add.png";
import OrangeChevron from "../../../assets/images/forwardRedz.svg";
import { vitalParams } from "../homeUtilityFunc";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { logEvent } from "../../../app/eventSlice";
import {
  cacheVitalsFromResponse,
  getVitalByDate,
} from "../../vitals/vitalSlice";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";

let swiper = null;
const slideOpts = {
  initialSlide: 0,
  speed: 400,
  on: {
    beforeInit() {
      swiper = this;
    },
  },
};

function ViatlSubmissions2() {
  const vitals = useSelector((store) => store.vitals.vitals);
  const user = useSelector((state) => state.login.user);
  const [todayVitals, setTodayVitals] = useState([]);
  const [yesterdayVitals, setYesterDayVitals] = useState([]);
  const [tomorrowVitals, setTomorrowVitals] = useState([]);
  const [currentVisibleSlideIndex, setCurrentVisibleSlideIndex] = useState(0);
  const [info, setInfo] = useState(false);
  const [todv, setTodv] = useState(false);
  const [yesv, setYesv] = useState(false);
  const [tomv, setTomv] = useState(false);
  const prevDate = useMemo(() => moment().subtract(1, "day").format("Do MMMM"));
  const currDate = useMemo(() => moment().format("Do MMMM"));
  const nextDate = useMemo(() => moment().add(1, "day").format("Do MMMM"));
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!info && todv && yesv && tomv) {
      setInfo(true);
    }
  }, [todv, tomv, yesv]);
  // useEffect(() => {
  //   if (!todv) {
  //     setTodv(true);
  //   }
  // }, [vitals[currDate]]);
  // useEffect(() => {
  //   if (!vitals[prevDate]) {
  //     getVitalsByDate(prevDate);
  //   } else {
  //     let processedArray = processVitals(vitals[prevDate]);

  //     let arr = processedArray.slice(0, 1);
  //     setYesterDayVitals(arr);
  //     setYesv(true);
  //   }
  // }, [vitals[prevDate]]);
  // useIonViewDidEnter(() => {
  //   if (vitals[currDate]) {
  //     let processedArray = processVitals(vitals[currDate]);
  //     let arr = processedArray.slice(0, 1);

  //     setTodayVitals(arr);
  //     setTodv(true);
  //   }
  //   getVitalsByDate(currDate);
  // }, []);
  // useIonViewDidEnter(() => {
  //   if (vitals[nextDate]) {
  //     let processedArray = processVitals(vitals[nextDate]);

  //     let arr = processedArray.slice(0, 1);
  //     setTomorrowVitals(arr);
  //     setTomv(true);
  //   }
  //   getVitalsByDate(nextDate);
  // }, []);

  useIonViewDidEnter(() => {
    getVitalsByDate(prevDate, 0);
    getVitalsByDate(currDate, 1);
    getVitalsByDate(nextDate, 2);
  }, []);

  const redirectToVitalCharts = (unit) => {
    let selectedVital = vitalParams.find((vital) => vital.unit === unit);
    let id = selectedVital?.id;
    if (id === 9 || id === 10) {
      history.push(`/vitalsChart`);
    } else {
      history.push(`/vitalsChart?${id}`);
    }
  };
  const getVitalsByDate = (formattedDate, day) => {
    const requestDate = moment(formattedDate, "Do MMMM").toDate().valueOf();

    dispatch(
      getVitalByDate({
        user: user,
        date: moment(requestDate).format("Do MMMM"),
      })
    )
      .unwrap()
      .then((res) => {
        const data = res.data;
        dispatch(cacheVitalsFromResponse({ date: formattedDate, data: data }));
        let processedArray = processVitals(data);
        let arr = processedArray.slice(0, 1);
        if (day === 0) {
          setYesterDayVitals(arr);
          setYesv(true);
        } else if (day === 1) {
          setTodayVitals(arr);
          setTodv(true);
        } else {
          setTomorrowVitals(arr);
          setTomv(true);
        }
      })
      .catch((err) => {});
  };

  const processVitals = (data) => {
    const finalArray = [];
    data.map((d) => {
      d.params.map((p) => {
        if (p.isRecommended === true) {
          finalArray.push(p);
        }
      });
    });

    return finalArray;
  };

  return (
    <>
      {info && (
        <div className="slides_wrapper">
          <Swiper
            // pager={true}
            slidesPerView={1}
            pagination={{
              clickable: true,
            }}
            className="my__new__class"
            // options={slideOpts}
          >
            {yesterdayVitals &&
              yesterdayVitals.length > 0 &&
              yesterdayVitals.map((vitals) => (
                <SwiperSlide>
                  <div className="reading-category-average">
                    <img
                      className="BPMonitorIcon"
                      src={BPMonitorIcon}
                      alt="icon"
                    />
                    <div className="reading-div">
                      <span className="reading-type">
                        {vitals.value
                          ? vitals.name + " for yesterday"
                          : "Recommended for yesterday"}
                      </span>
                      <span className="reading">
                        {vitals.value
                          ? vitals.value + " " + vitals.unit.name
                          : vitals.name}
                      </span>
                      {vitals.value && (
                        <>
                          {vitals.inRange === true && (
                            <div className="badge">IN RANGE</div>
                          )}
                          {vitals.inRange === false && (
                            <div className="badge" id="out-of-range">
                              OUT OF RANGE
                            </div>
                          )}
                        </>
                      )}
                    </div>
                    {vitals.value && (
                      <img
                        src={OrangeChevron}
                        onClick={() => {
                          dispatch(
                            logEvent({ action: "click_add_recommended_vital" })
                          );
                          redirectToVitalCharts(vitals.unit.name);
                        }}
                        className="icon"
                        alt="icon"
                      />
                    )}
                    {!vitals.value && vitals.isRecommended && (
                      <img
                        src={SubmitIcon}
                        onClick={() => {
                          dispatch(
                            logEvent({ action: "first_add-recommended_vital" })
                          );
                          history.push("/vitals");
                        }}
                        className="icon"
                        alt="icon"
                      />
                    )}
                  </div>
                </SwiperSlide>
              ))}
            {todayVitals &&
              todayVitals.length > 0 &&
              todayVitals.map((vitals) => (
                <SwiperSlide>
                  <div className="reading-category-average">
                    <img
                      className="BPMonitorIcon"
                      src={BPMonitorIcon}
                      alt="icon"
                    />
                    <div className="reading-div">
                      <span className="reading-type">
                        {vitals.value
                          ? vitals.name + " for today"
                          : "Recommended for today"}
                      </span>
                      <span className="reading">
                        {vitals.value
                          ? vitals.value + " " + vitals.unit.name
                          : vitals.name}
                      </span>
                      {vitals.value && (
                        <>
                          {vitals.inRange === true && (
                            <div className="badge">IN RANGE</div>
                          )}
                          {vitals.inRange === false && (
                            <div className="badge" id="out-of-range">
                              OUT OF RANGE
                            </div>
                          )}
                        </>
                      )}
                    </div>
                    {vitals.value && (
                      <img
                        src={OrangeChevron}
                        onClick={() => {
                          dispatch(
                            logEvent({ action: "click_add_recommended_vital" })
                          );
                          redirectToVitalCharts(vitals.unit.name);
                        }}
                        className="icon"
                        alt="icon"
                      />
                    )}
                    {!vitals.value && vitals.isRecommended && (
                      <img
                        src={SubmitIcon}
                        onClick={() => {
                          dispatch(
                            logEvent({ action: "first_add-recommended_vital" })
                          );
                          history.push("/vitals");
                        }}
                        className="icon"
                        alt="icon"
                      />
                    )}
                  </div>
                </SwiperSlide>
              ))}
            {tomorrowVitals &&
              tomorrowVitals.length > 0 &&
              tomorrowVitals.map((vitals) => (
                <SwiperSlide>
                  <div className="reading-category-average">
                    <img
                      className="BPMonitorIcon"
                      src={BPMonitorIcon}
                      alt="icon"
                    />
                    <div className="reading-div">
                      <span className="reading-type">
                        Recommend for tomorrow
                      </span>
                      <span className="reading">{vitals.name}</span>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      )}
    </>
  );
}

export default ViatlSubmissions2;
