import React, { useCallback, useEffect, useState } from "react";
import BackButton from "../../../components/common/BackButton";
import SingleAppointmentComponent from "./SingleAppointmentComponent";
import noSlotVector from "../../../assets/images/no-slots.svg";

import blueClock from "../../../assets/icons/blue-clock-icon.png";

import { useDispatch, useSelector } from "react-redux";
import { ChevronRightIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { getUrlWithToken } from "../utilityFunctions";
import { logEventWithData } from "../../../app/eventSlice";
import { setStateFromPayload, navigoShowError } from "../navigoSlice";
import { SocialSharing } from "@ionic-native/social-sharing";

import NavigoServices from "../../../services/NavigoServices";
import { NoAppointmentFound } from "./noAppointmentFound";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";
import GeneralNavigoCard from "./generalNavigoCard";
import { IonSlide, IonSlides } from "@ionic/react";
import navigoServicesBanner from "../../../assets/images/navigo-services-banner.png";

let navigoServicesSwiper = null;
const navigoServicesSlideOpts = {
  initialSlide: 0,
  speed: 500,

  on: {
    beforeInit() {
      navigoServicesSwiper = this;
    },
  },
};

const typeIdToServiceName = ["Diagnostic", "Consultancy", "Assessment"];

SwiperCore.use([Pagination]);
export default function NavigoFirstSection({ handleGotoAddress }) {
  const token = useSelector((store) => store.tab.docToken?.token);
  const navigoStore = useSelector((store) => store.navigo);
  const user = useSelector((store) => store.login.user);
  const plan = useSelector((state) => state.home.plans);
  const [finalServices, setFinalServices] = useState([]);
  const dispatch = useDispatch();
  const { services, upcomingAppointments, serviceTypeId } = navigoStore.ui;

  // const handleAndroidReadAndWrite = async (
  //   fileName,
  //   res,
  //   directory,
  //   folder
  // ) => {
  //   try {
  //     let createdFileResult = await File.createFile(
  //       File.externalRootDirectory + folder,
  //       fileName,
  //       true
  //     );
  //     let writeToFile = await File.writeFile(
  //       directory + folder,
  //       fileName,
  //       res,
  //       {
  //         replace: true,
  //         append: false,
  //       }
  //     );
  //     dispatch(
  //       navigoShowError({
  //         msg: "File is saved in the Download directory.",
  //         isPositive: true,
  //       })
  //     );
  //   } catch (err) {
  //     dispatch(
  //       navigoShowError({
  //         msg: "An error occured while downloading the file.",
  //       })
  //     );
  //   }
  // };
  // const handleReportDownload = async (url) => {
  //   dispatch(setStateFromPayload({ openDrawer: true, drawerType: 3 }));
  //   NavigoServices.getNavigoReport(
  //     url,
  //     async (res) => {
  //       let format = "pdf";

  //       if (res.type === "image/png") {
  //         format = "png";
  //       } else if (res.type === "image/jpeg") {
  //         format = "jpeg";
  //       } else if (res.type === "image/jpg") {
  //         format = "jpg";
  //       }
  //       let fileName = `zyla-report-${Date.now()}-${parseInt(
  //         Math.random() * 10000
  //       )}.${format}`;

  //       if (window.Capacitor.platform === "android") {
  //         try {
  //           let result = await File.checkDir(
  //             File.externalRootDirectory,
  //             "Download"
  //           );
  //           handleAndroidReadAndWrite(
  //             fileName,
  //             res,
  //             File.externalRootDirectory,
  //             "Download"
  //           );
  //         } catch (err) {
  //           try {
  //             let result = await File.createDir(
  //               File.externalRootDirectory,
  //               "Download",
  //               false
  //             );
  //             handleAndroidReadAndWrite(
  //               fileName,
  //               res,
  //               File.externalRootDirectory,
  //               "Download"
  //             );
  //           } catch (err) {
  //             dispatch(
  //               navigoShowError({
  //                 msg: "Error while creating download directory.",
  //               })
  //             );
  //           }
  //         }
  //       } else {
  //         File.createDir(File.documentsDirectory, "myDownloads", true)
  //           .then((dirRes) => {
  //             createFile(fileName)
  //               .then((result) => {
  //                 return writeToFile(fileName, res);
  //               })
  //               .then((createdFile, arg) => {
  //                 dispatch(
  //                   setStateFromPayload({ openDrawer: false, drawerType: 0 })
  //                 );
  //                 handleFileShare(createdFile, fileName);
  //               })
  //               .catch((err) => {
  //                 dispatch(
  //                   navigoShowError({
  //                     msg: "An error occured while downloading the file.",
  //                   })
  //                 );
  //               });
  //           })
  //           .catch((err) => {
  //             console.log("s");
  //           });
  //       }
  //     },
  //     (err) => {
  //       dispatch(
  //         navigoShowError({
  //           msg: "An error occured while downloading the file.",
  //         })
  //       );
  //     }
  //   );
  // };
  // const createFile = async (fileName) => {
  //   return new Promise((resolve, reject) => {
  //     File.createFile(File.documentsDirectory + "myDownloads", fileName, true)
  //       .then((crf) => {
  //         resolve(true);
  //       })
  //       .catch((err) => {
  //         dispatch(
  //           navigoShowError({
  //             msg: "An error occured while downloading the file.",
  //           })
  //         );
  //         reject(true);
  //       });
  //   });
  // };

  // const handleFileShare = useCallback((info, fileName) => {
  //   SocialSharing.share(null, null, info.nativeURL, null)
  //     .then((res) => {
  //       handleRemoveFile(fileName);
  //     })
  //     .catch((err) => {
  //       handleRemoveFile(fileName);
  //     });
  // }, []);
  // const handleRemoveFile = useCallback((fileName) => {
  //   File.removeFile(File.documentsDirectory + "myDownloads", fileName)
  //     .then((res) => {})
  //     .catch((err) => {});
  // }, []);

  // const writeToFile = useCallback((fileName, data) => {
  //   return new Promise((resolve, reject) => {
  //     File.writeFile(File.documentsDirectory + "myDownloads", fileName, data, {
  //       replace: true,
  //       append: false,
  //     })
  //       .then((createdFile) => {
  //         resolve(createdFile, fileName);
  //       })
  //       .catch((err) => {
  //         reject(false);
  //       });
  //   });
  // }, []);

  function compareServices(serviceA, serviceB) {
    if (serviceA.discountedPrice === 0 && serviceB.discountedPrice !== 0) {
      // ServiceA is free, so it comes before serviceB
      return -1;
    } else if (
      serviceA.discountedPrice !== 0 &&
      serviceB.discountedPrice === 0
    ) {
      // ServiceB is free, so it comes before serviceA
      return 1;
    } else {
      // If any service does not have a discounted price
      if (
        serviceA.discountedPrice != null &&
        serviceB.discountedPrice == null
      ) {
        return serviceA.discountedPrice - serviceB.price;
      } else if (
        serviceA.discountedPrice == null &&
        serviceB.discountedPrice != null
      ) {
        return serviceB.discountedPrice - serviceA.price;
      } else if (
        serviceA.discountedPrice == null &&
        serviceB.discountedPrice == null
      ) {
        return serviceA.price - serviceB.price;
      } else {
        // Both services are either free or paid, sort by price in ascending order
        return serviceA.discountedPrice - serviceB.discountedPrice;
      }
    }
  }

  useEffect(() => {
    // Need to show HRA call service on the top and sort the rest
    const specificServiceId = 119; //Health risk assessment call- Max Fit
    // process.env.REACT_APP_HRA_ID
    if (services && services.length > 0) {
      const serviceHRA = services?.find(
        (service) => service.ID === specificServiceId
      );

      const otherServices = services?.filter(
        (service) => service.ID !== specificServiceId
      );
      const sortedServices = otherServices?.slice().sort(compareServices);
      let finalServices = [];
      if (serviceHRA) {
        finalServices.push(serviceHRA);
      }
      if (sortedServices) {
        finalServices.push(...sortedServices);
      }
      if (!plan?.response?.payload?.hsaPlan) {
        finalServices = finalServices.filter((ele) => ele.ID != 54);
      }

      setFinalServices(finalServices);
    }
  }, [services]);

  return (
    <React.Fragment>
      <div className="navigo-booking-container">
        <div className="navigo-booking-container-back-button">
          <BackButton></BackButton>
          <p>{"Book services"}</p>
        </div>

        <div className="navigo-booking-container-top">
          <img
            src={navigoServicesBanner}
            className="navigo-booking-container-top-banner-image"
          />
        </div>

        <div className="navigo-booking-container-section-3">
          <p className="navigo-booking-container-section-3-heading">
            Upcoming appointments
          </p>
          {upcomingAppointments &&
            upcomingAppointments.length > 0 &&
            upcomingAppointments.map((uAppoint) => {
              return (
                <div
                  className="navigo-booking-container-section-3-ele"
                  key={uAppoint.appointment.ID}
                >
                  <SingleAppointmentComponent
                    serviceName={uAppoint?.service.name}
                    displayName={uAppoint.appointment.displayName}
                    date={uAppoint?.appointment?.appointmentDate}
                    timeSlot={uAppoint?.appointment?.timeSlot}
                  />

                  <div className="navigo-booking-container-section-3-ele-bottom">
                    {uAppoint.appointment.status !== 6 && (
                      <button
                        className="navigo-booking-container-section-3-ele-bottom-reschedule"
                        onClick={() => {
                          dispatch(
                            logEventWithData({
                              action: "navigo_reschedule_check",
                              data: {
                                is_app_event: true,
                                serviceID: uAppoint?.service?.ID,
                                serviceName: uAppoint?.service?.name,
                              },
                            })
                          );

                          dispatch(
                            setStateFromPayload({
                              appointmentDate: null,
                              currentAppointment: { ...uAppoint },
                              currentServices: { ...uAppoint.service },
                              timeSlot: null,
                              isReschedule: true,
                              currScreen: 3,
                              loadingSlots: true,
                              dateSelected: 0,
                            })
                          );
                        }}
                      >
                        <img
                          src={blueClock}
                          alt="icon"
                          className="general-reschedule-cancel-icons"
                        />
                        Reschedule
                      </button>
                    )}
                    {uAppoint.appointment.status !== 6 &&
                      (uAppoint.appointment.paymentOrderId == "" ||
                        uAppoint.appointment.paymentOrderId == null) && (
                        <button
                          className="navigo-booking-container-section-3-ele-bottom-cancel"
                          onClick={() => {
                            dispatch(
                              logEventWithData({
                                action: "navigo_cancel_check",
                                data: {
                                  is_app_event: true,
                                  serviceID: uAppoint?.service?.ID,
                                  serviceName: uAppoint?.service?.name,
                                },
                              })
                            );
                            dispatch(
                              setStateFromPayload({
                                openDrawer: true,
                                drawerType: 2,
                                cancelAppointmentId: uAppoint.appointment.ID,
                              })
                            );
                          }}
                        >
                          <XMarkIcon className="general-reschedule-cancel-icons" />
                          Cancel
                        </button>
                      )}
                  </div>
                </div>
              );
            })}
          {upcomingAppointments.length === 0 && <NoAppointmentFound />}
        </div>

        <div className="navigo-booking-container-section-2">
          {services && services.length > 0 && (
            <div>
              {/* {services.map((service, index) => { */}
              {finalServices.map((service, index) => {
                return (
                  <GeneralNavigoCard
                    service={service}
                    handleGotoAddress={handleGotoAddress}
                  />
                );
              })}
            </div>
          )}
          {services && services.length === 0 && (
            <div className="navigo-booking-container-section-2-no-service">
              <img src={noSlotVector} alt="bg-img" />
              <p>No service found. please try again later.</p>
            </div>
          )}
        </div>

        {/* <div className="navigo-booking-container-section-3 navigo-booking-container-section-4">
          <p className="navigo-booking-container-section-3-heading">
            Past appointments
          </p>
          {pastAppointments.length > 0 &&
            pastAppointments.map((pAppoint) => {
              return (
                <div
                  className="navigo-booking-container-section-3-ele"
                  key={pAppoint.appointment.ID}
                >
                  <SingleAppointmentComponent
                    serviceName={pAppoint?.service.name}
                    displayName={pAppoint.appointment.displayName}
                    date={pAppoint?.appointment?.appointmentDate}
                    timeSlot={pAppoint?.appointment?.timeSlot}
                  />
                  {pAppoint &&
                    pAppoint.appointment &&
                    pAppoint.appointment.reportURL && (
                      <div className="navigo-booking-container-section-3-ele-bottom">
                        {window.Capacitor.platform === "web" ? (
                          <a
                            href={`${getUrlWithToken(
                              pAppoint.appointment.reportURL,
                              token
                            )}`}
                            target="_blank"
                            download={`${
                              pAppoint.service?.reportURL
                            }${Date.now()}`}
                            className="navigo-booking-container-section-3-ele-bottom-reschedule"
                          >
                            Download report
                            <ChevronRightIcon className="general-download-document-blue-chevron" />
                          </a>
                        ) : (
                          <>
                            <button
                              className="navigo-booking-container-section-3-ele-bottom-reschedule"
                              onClick={() => {
                                handleReportDownload(
                                  getUrlWithToken(
                                    pAppoint.appointment.reportURL,
                                    token
                                  )
                                );
                              }}
                            >
                              Download report
                              <ChevronRightIcon className="general-download-document-blue-chevron" />
                            </button>
                          </>
                        )}
                      </div>
                    )}
                </div>
              );
            })}
          {pastAppointments.length === 0 && <NoAppointmentFound />}
        </div> */}
      </div>
    </React.Fragment>
  );
}
