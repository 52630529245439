import {
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  useIonRouter,
} from "@ionic/react";
import { Redirect, Route, useHistory } from "react-router";

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateDefaultCountryCode } from "../../app/appSlice";
import chatIcon from "../../assets/icons/chat_icon_orange.svg";
import homeIcon from "../../assets/icons/home_icon.svg";
import profileIcon from "../../assets/icons/mli-feed.svg";
import profileIconSelected from "../../assets/icons/mli-feed-black.svg";
import useInterval from "../../components/common/useInterval";
import { getMessages, getNextMessages, sendMessage } from "../chat/chatSlice";
import ChatRoomPage from "../chat/newChat";
import hamburger from "../hamburger";
import {
  clearChatDot,
  createChat,
  fetchGenericSetting,
  generateDocumentToken,
  getChat,
  getClientConfig,
  getUIConfig,
  toggleNetworkStatus,
  toggleScrollChat,
  updateDeviceInfo,
} from "./tabSlice";

import "@capacitor-community/camera-preview";

import moment from "moment";
import ZylaNotification from "../../Notification";
import { logEvent } from "../../app/eventSlice";
import clevertapLogger from "../../clevertapLogger";
import NotificationService from "../../services/NotificationService";
import { App } from "@capacitor/app";
import { getUserProfile } from "../login/loginSlice";
import "./index.scss";
import MliHomePage from "../HomePage/MliHomePage";
import Feed from "../HomePage/Feed";
import HomePage from "../HomePage/homePage";
import NewHomePage from "../HomePage";
import APIConfig from "../../services/APIConfig";
import axiosClient from "../../app/axiosConfig";
import UserService from "../../services/UserService";
import { FirebaseCrashlytics } from "@capacitor-community/firebase-crashlytics";
import { homePlanData } from "../HomePage/homePageSlice";

export default function TabPage(props) {
  const [showTab, setShowTab] = useState(true);
  const [shouldShowExit, setShouldShowExit] = useState(false);
  const tabStore = useSelector((state) => state.tab);
  const metadata = useSelector((state) => state.chat.metadata);
  const pendingMessages = useSelector((state) => state.chat.pendingMessages);
  const device = useSelector((state) => state.app.device);
  const appInfo = useSelector((state) => state.app.appInfo);
  const user = useSelector((state) => state.login.user);
  const savedDob = useSelector((state) => state.login.savedDOB);
  const docToken = useSelector((store) => store.tab.docToken);
  const clientId = useSelector((state) => state.login.clientId);
  const zylaTabBtn = useRef(null);
  const dispatch = useDispatch();
  const ionRouter = useIonRouter();
  const history = useHistory();
  const { ui, chat, uiConfig, showRedDotHome, updatedob } = tabStore;
  const { showRedDotChat, unReadCount, chatFound } = ui;
  const userId = user.id;
  const patientId = user?.patientProfile?.id;

  useEffect(() => {
    try {
      FirebaseCrashlytics.setUserId({ userId: patientId })
        .then((res) => {})
        .catch((err) => {});
    } catch (err) {}
    ZylaNotification.register(
      handleNotificationTap,
      handleForegroundNotif,
      user
    );
    window.ononline = (event) => {
      dispatch(toggleNetworkStatus(false));
    };
    window.onoffline = (event) => {
      dispatch(toggleNetworkStatus(true));
    };
    window.addEventListener("generateDocToken", handleGenerateDocToken);
    window.addEventListener("logUserOut", handleLogUserOut);

    document.addEventListener("ionBackButton", (ev) => {
      ev.detail.register(10, () => {
        if (window.location.pathname === "/tab/home") {
          setShouldShowExit(true);
        } else {
          history.go(-1);
        }
      });
    });

    return () => {
      window.removeEventListener("generateDocToken", handleGenerateDocToken);
      window.removeEventListener("logUserOut", handleLogUserOut);
    };
  }, []);

  useEffect(() => {
    App.addListener("appUrlOpen", (data) => {
      UserService.getLatestSettings(patientId)
        .then((res) => {
          if (res.data?.isChatVisible) {
            const myURL = new URL(data.url);

            const pathname = myURL.pathname;
            const sp = new URLSearchParams(myURL.search);

            if (pathname === "/mlinavigo") {
              if (sp.has("id")) {
                history.push(`/navigo?currScreen=1&serviceId=${sp.get("id")}`);
              } else {
                history.push("/navigo?currScreen=1");
              }
            } else if (pathname === "/mliplan") {
              history.push("/sewa-myplan");
            } else if (pathname === "/mlihp") {
              history.push("/health-plan");
            } else if (pathname === "/mlivoucher") {
              history.push("/registervoucher");
            } else if (pathname === "/mlivitals") {
              history.push("/vitals");
            } else if (pathname === "/mlihome") {
              history.push("/tab/home");
            } else if (pathname === "/mlichat") {
              history.push("/tab/chat");
            } else if (pathname === "/mlireward") {
              history.push("/reward-point-info");
            } else if (pathname === "/stepsync") {
              history.push("/tab/home/#step");
            }
          }
        })
        .catch((err) => {
          console.log("error is ", err);
        });
    });
  }, []);

  useEffect(() => {
    dispatch(fetchGenericSetting());
    dispatch(homePlanData(clientId));
  }, [dispatch]);

  // fetch messages
  useInterval(() => {
    if (chat) {
      if (metadata) {
        dispatch(
          getNextMessages({
            roomId: chat.chatRoom,
            maxId: metadata.maxId,
            userId: chat.externalUserIdSt,
          })
        );
      } else {
        dispatch(
          getNextMessages({
            roomId: chat.chatRoom,
            maxId: "",
            userId: chat.externalUserIdSt,
          })
        );
      }
      dispatch(getUIConfig(patientId));
    }

    handleOfflineMessages();
    if (docToken && docToken.expiredTime < moment.utc().valueOf()) {
      dispatch(generateDocumentToken());
    }
  }, 5000);

  useEffect(() => {
    const deviceDetail = {
      device: device.platform,
      deviceName: device.model,
      osVersion: device.osVersion,
      appVersion:
        device?.platform === "web"
          ? process.env.REACT_APP_APP_VERSION
          : appInfo?.version,
    };
    if (user && user.patientProfile) {
      dispatch(
        updateDefaultCountryCode({ patientProfile: user.patientProfile })
      );
    }

    dispatch(
      updateDeviceInfo({
        id: userId,
        req: deviceDetail,
      })
    );
  }, [userId]);

  useEffect(() => {
    dispatch(getUserProfile());
  }, []);

  useEffect(() => {
    if (ui.updatedob) {
      updateUserDOB();
    }
  }, [ui.updatedob]);

  useEffect(() => {
    if (chatFound) {
      dispatch(getChat(userId));
    } else {
      dispatch(createChat(user));
    }
  }, [chatFound, userId, user]);

  // get chat
  useEffect(() => {
    dispatch(generateDocumentToken());
    dispatch(getClientConfig());
    clevertapLogger.signUp(user);
  }, [dispatch, userId, patientId]);

  useEffect(() => {
    if (chat) {
      dispatch(getUIConfig(patientId));
      dispatch(getMessages({ roomId: chat.chatRoom, userId: chat.chatUserId }));
    }
  }, [chat, patientId]);

  const handleNotificationTap = (notifData) => {
    try {
      let notifObj = JSON.parse(notifData.notification.data.data);

      if (notifObj._zyla_notification_id) {
        let payload = {};
        payload.id = notifObj._zyla_notification_id;
        const notificationService = new NotificationService();
        notificationService.postNotifAnalytics(payload, user);
      } else {
      }
    } catch (err) {
      dispatch(logEvent({ action: "errNotif", data: { err: err } }));
    }
    dispatch(toggleScrollChat({ value: true }));

    history.push("/tab/chat");
  };
  const handleForegroundNotif = (notifData) => {
    try {
      let notifObj = JSON.parse(notifData.data.data);

      if (notifObj._zyla_notification_id) {
        let payload = {};
        payload.id = notifObj._zyla_notification_id;
        const notificationService = new NotificationService();
        notificationService.postNotifAnalytics(payload, user);
      } else {
      }
    } catch (err) {}
  };

  const handleOfflineMessages = () => {
    if (
      navigator.onLine &&
      pendingMessages &&
      pendingMessages.ids &&
      pendingMessages.ids.length > 0
    ) {
      for (let i = 0; i < pendingMessages.ids.length; i++) {
        const req = {
          roomId: chat.chatRoom,
          userId: chat.externalUserIdSt,
          content: pendingMessages.entities[pendingMessages.ids[i]].content,
          isResend: true,
        };
        dispatch(sendMessage(req));
      }
    }
  };

  const handleGenerateDocToken = () => {
    dispatch(generateDocumentToken());
  };

  const handleLogUserOut = () => {
    let cc = "";

    if (user && user.patientProfile && user.patientProfile.clientCode) {
      cc = user.patientProfile.clientCode;
    }
    if (cc === "DI" || cc === "MG" || cc === "PY") {
      history.replace("/session-expired");
    } else {
      dispatch({ type: "logout" });
    }
  };

  const updateUserDOB = () => {
    const { patientProfile } = user;
    let payload = {
      dateOfBirth: savedDob,
      email: patientProfile.email,
      firstName: patientProfile.firstName,
      lastName: patientProfile.lastName,
      gender: patientProfile.gender,
    };
    let url = APIConfig.patientsData.updatePatientData.format(
      patientProfile.id
    );
    axiosClient
      .put(url, payload)
      .then((res) => {
        // console.log("dob updated");
      })
      .catch((err) => {
        console.log("error while updating dob");
      });
  };

  return (
    <IonTabs
      onIonTabsDidChange={(e) => {
        if (e.detail.tab === "chat") {
          dispatch(clearChatDot());
        }
      }}
    >
      <IonRouterOutlet animated={false}>
        <Route
          exact={true}
          path="/tab/chat"
          render={(props) => <ChatRoomPage {...props} />}
        />
        <Route
          exact={true}
          path="/tab/home"
          render={(props) => (
            <MliHomePage
              {...props}
              zylaTabBtn={zylaTabBtn}
              shouldShowExit={shouldShowExit}
              setShouldShowExit={setShouldShowExit}
            />
          )}
        />
        <Route exact={true} path="/tab/feed" component={Feed} />
        <Route exact={true} path="/tab/hamburger" component={hamburger} />

        <Redirect exact from="/tab" to="/tab/chat" />
      </IonRouterOutlet>

      {uiConfig.isChatVisible ? (
        <IonTabBar slot="bottom" className="items-baseline">
          <IonTabButton tab="home" href="/tab/home">
            <IonIcon
              src={homeIcon}
              className="ionicon normal-ion-icon-class"
            ></IonIcon>
            <IonLabel className="">Home</IonLabel>
          </IonTabButton>
          <IonTabButton tab="chat" href="/tab/chat" ref={zylaTabBtn}>
            <IonIcon
              icon={chatIcon}
              className="chat-ion-icon stroke-0  "
            ></IonIcon>
            <IonLabel>Zyla</IonLabel>
            {showRedDotChat && window.location.pathname !== "/tab/chat" && (
              <div className="unread-message-indicator-chat">
                <span>{unReadCount >= 10 ? "9+" : unReadCount}</span>
              </div>
            )}
          </IonTabButton>
          <IonTabButton tab="feed" href="/tab/feed">
            <img
              src={
                window.location.pathname == "/tab/feed"
                  ? profileIconSelected
                  : profileIcon
              }
              className="mli-feed-class"
            />
            <IonLabel className="text-xs">Feed</IonLabel>
          </IonTabButton>
        </IonTabBar>
      ) : (
        <IonTabBar></IonTabBar>
      )}
    </IonTabs>
  );
}
