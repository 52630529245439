import { IonContent, IonPage } from "@ionic/react";
import backImage from "../../assets/images/arrow-back.png";
import downArraow from "../../assets/images/arrow-ios-down.png";
import { useState } from "react";
import { Link } from "react-router-dom";

function TestAccordion({ test }) {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <div className="flex justify-between pt-5" key={test.id}>
        {test.title} ({test.children.length})
        <img
          src={downArraow}
          alt=""
          className={`h-6 w-6 cursor-pointer ${
            isOpen ? "rotate-180 delay-70  " : ""
          } `}
          onClick={() => {
            setOpen(!isOpen);
          }}
        />
      </div>

      {isOpen && (
        <div className="px-5 font-normal text-xs text-lightblack leading-4 ">
          {test.children.map((v) => {
            return <p>{v.title}</p>;
          })}
        </div>
      )}
    </>
  );
}

export default function FullBodyHealthCheckup() {
  const tests = [
    {
      title: "Lipid profile",
      children: [
        {
          title: "a",
        },
        {
          title: "b",
        },
      ],
    },
    {
      title: "Liver function test ",
      children: [
        {
          title: "a",
        },
        {
          title: "b",
        },
      ],
    },
    {
      title: "Complete hemogram ",
      children: [
        {
          title: "a",
        },
        {
          title: "b",
        },
      ],
    },
    {
      title: "Iron studies ",
      children: [
        {
          title: "Iron, serum",
        },
        {
          title: "TIBC",
        },
        {
          title: "UIBC, serum",
        },
        {
          title: "Transferrin saturation",
        },
      ],
    },

    {
      title: "Kidney function test",
      children: [],
    },
    {
      title: "HbA1c ",
      children: [
        {
          title: "a",
        },
        {
          title: "b",
        },
      ],
    },
  ];

  return (
    <>
      <IonPage>
        <IonContent>
          <div className="px-5 py-4 text-lightblack bg-zinc-50 h-full fixed">
            <div className="flex items-center ">
              <Link to="/mliNavigo">
                <img src={backImage} alt="icon" className="w-6 h-6" />
              </Link>
              <div className="px-3 font-bold text-base leading-7  ">
                Full body health checkup
              </div>
            </div>
            <div className="font-medium text-xs leading-4  pt-3 ">
              Preventive health checkups helps you to be aware of your health
              and reduce the risk of falling ill by detecting potential health
              issues early on. It also provide an opportunity to discuss healthy
              lifestyle habits and to identify any necessary changes to improve
              overall health and well-being. This test is also eligible for tax
              exemption under 80 D.
            </div>

            <div className="font-medium text-xs leading-4 w-full bg-white p-6 mt-4 rounded-md ">
              <div>
                Includes
                <span className="font-bold text-xs leading-4 ">89 tests</span>
              </div>
              <hr className="bg-indigo-500 w-full mt-2" />

              <div className="  font-medium text-xs leading-4 items-center text-lightblack">
                {tests.map((test) => {
                  return <TestAccordion test={test} />;
                })}
              </div>
            </div>

            <div className="flex justify-center pt-7 py-4 ">
              <button className="text-white bg-zburgundy  font-bold text-base leading-7 flex justify-center px-16 py-3 rounded-full">
                Book now
              </button>
            </div>
          </div>
        </IonContent>
      </IonPage>
    </>
  );
}
