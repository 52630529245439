import React, { useState } from "react";
import VitalTableHeader from "./components/vitalTableHeader";

import { Link, useHistory } from "react-router-dom";
import "./vitals.scss";
import NoVitalRequired from "./components/noVitalRequired";
import LoadingVital from "./components/loadingVitals";

import {
  IonPage,
  IonContent,
  IonLoading,
  useIonViewDidEnter,
} from "@ionic/react";
import VitalCompleteSuccess from "./components/vitalCompleteSuccess";
import NextImage from "../../assets/images/ic_arrow_forward.png";
import closeImg from "../../assets/images/white_cross.png";
import chartIcon from "../../assets/images/vital-chartsz.svg";
import moment from "moment";
import BackButton from "../../components/common/BackButton";
import CreateHeaderButton from "./components/createHeaderButtonVitals";
import { logEvent } from "../../app/eventSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  getVitalByDate,
  handleVitalLoaders,
  handleGetVitalFullfill,
  handleVitalError,
  submitVitals,
} from "./vitalSlice";
import VitalDetails from "./components/vitalDetails";
import { validateResponse, isNoVitalRequiredFunc } from "./utilityFunctions";
import ErrorComponent from "../../components/common/ErrorComponent";
import { toggleScrollChat } from "../tab/tabSlice";
import { useEffect } from "react";
import { sendMessage } from "../chat/chatSlice";
import {
  toggleDataUpdated,
  toggleShowChart,
} from "../vitalsChart/vitalsChartSlice";

export default function VitalContainer() {
  const paytmFlag = useSelector((store) => store.app.device.isPaytm);
  const user = useSelector((store) => store.login.user);
  const vitals = useSelector((store) => store.vitals.vitals);
  const {
    groupModels,
    isNoVitalRequired,
    loaded,
    loading,
    error,
    errorMsg,
    isSendActive,
  } = useSelector((store) => store.vitals.ui);
  const chat = useSelector((state) => state.tab.chat);
  const isOffline = useSelector((store) => store.tab.ui.isOffline);
  const [active, setActive] = useState(1);
  const [selectedDate, setSelectedDate] = useState(moment().format("Do MMMM"));
  const [submitted, setSubmitted] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [showInfoDiv, setShowInfoDiv] = useState(true);
  const [currentDetails, setCurrentDetails] = useState({});

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (submitted) {
      setTimeout(() => {
        history.goBack(-2);
        // setSubmitted(false);
      }, 3000);
    }
  }, [submitted]);

  useIonViewDidEnter(() => {
    dispatch(logEvent({ action: "open_vitals" }));
    _loadVitalsByDate(selectedDate);
  }, []);
  const _loadVitalsByDate = (date) => {
    dispatch(getVitalByDate({ date: date, user: user }))
      .unwrap()
      .then((res) => {
        const response = res.data;

        dispatch(
          handleGetVitalFullfill({
            groupModels: response,
            isNoVitalRequired: isNoVitalRequiredFunc(response, date),
            date: date,
          })
        );
      })
      .catch((err) => {
        dispatch(
          handleVitalError({
            data: true,
            msg: isOffline ? "No internet connection" : "Something went wrong.",
          })
        );
        dispatch(
          handleGetVitalFullfill({
            groupModels: [],
            isNoVitalRequired: isNoVitalRequiredFunc([], date),
          })
        );
      });
  };
  const onClickChangeDate = (date, activeId) => {
    setSelectedDate(date);
    setActive(activeId);
    _loadVitalsByDate(date);
  };

  const onSubmitVital = () => {
    if (validateResponse(groupModels)) {
      dispatch(handleVitalLoaders({ data: true }));
      dispatch(
        submitVitals({ date: selectedDate, params: groupModels, user: user })
      )
        .unwrap()
        .then((res) => {
          dispatch(logEvent({ action: "vitals_submit_successful" }));
          dispatch(toggleScrollChat({ value: true }));
          dispatch(toggleShowChart({ value: true }));
          dispatch(toggleDataUpdated(true));
          dispatch(
            sendMessage({
              content: {
                message: {
                  type: 8,
                  text: "Vitals sumitted",
                  metadata: {
                    vitalsDate: moment(selectedDate, "Do MMMM").format(
                      "DD-MM-YYYY"
                    ),
                  },
                  id: "" + new Date().getTime() + Math.random(),
                },
              },
              roomId: chat.chatRoom,
              userId: chat.externalUserIdSt,
            })
          );
          setSubmitted(true);
        })
        .catch((err) => {
          dispatch(
            handleVitalError({
              data: true,
              msg: isOffline
                ? "No internet connection."
                : "Something went wrong.",
            })
          );
        });
    } else {
      dispatch(
        handleVitalError({ data: true, msg: "Please fill valid details." })
      );
    }
  };

  const showParamDetail = (p) => {
    dispatch(logEvent({ action: "open_vital_description" }));
    setCurrentDetails(p);
    setShowDetail(true);
  };
  const closeDrawer = () => {
    setShowDetail(false);
    setCurrentDetails({});
  };

  return (
    <IonPage className="vitals-container">
      {loaded && !error && (
        <IonLoading isOpen={loading} message={"Submitting"} duration={5000} />
      )}

      {submitted ? (
        <VitalCompleteSuccess
          open={submitted}
          date={selectedDate}
          onClose={() => setSubmitted(false)}
        ></VitalCompleteSuccess>
      ) : (
        <IonContent>
          <ErrorComponent
            show={error}
            msg={errorMsg}
            errorClose={() => {
              dispatch(handleVitalError({ data: false, msg: "" }));
            }}
          />
          {loaded && (
            <header>
              <div className="vitals-header">
                <div className="title">Vitals</div>

                <Link to="/vitalsChart" className="vital-chart-icon">
                  <img src={chartIcon} alt="icon" />
                </Link>
                {!paytmFlag && (
                  <BackButton history={history} isClose></BackButton>
                )}
              </div>
              <CreateHeaderButton
                active={active}
                onClickChangeDate={onClickChangeDate}
              />
            </header>
          )}
          {loading && !loaded && <LoadingVital />}
          {loaded && isNoVitalRequired && <NoVitalRequired></NoVitalRequired>}
          {loaded && !isNoVitalRequired && (
            <main>
              <section className="vital-share-section">
                <div className="vital-top-message">
                  <span>Recommended tests are highlighted in blue</span>
                </div>

                {showInfoDiv && (
                  <div className="showInfo-div-parent">
                    <div className="showInfo-div">
                      <p>
                        Click on vital name to learn how and when to measure the
                        vital
                      </p>
                      <img
                        src={closeImg}
                        alt="icon"
                        onClick={() => setShowInfoDiv(false)}
                      />
                    </div>
                    <div className="empty-rotated-div"></div>
                  </div>
                )}
                <table className="vital-table">
                  {groupModels.map((groupModel, parentIndex) => {
                    if (groupModel.params.length > 0) {
                      return (
                        <VitalTableHeader
                          updateVital={(value, index) =>
                            this.onUpdateVital(value, index, parentIndex)
                          }
                          parentIndex={parentIndex}
                          groupModel={groupModel}
                          showParamDetail={(p) => showParamDetail(p)}
                          active={active}
                        ></VitalTableHeader>
                      );
                    } else {
                      return null;
                    }
                  })}
                </table>
                {active !== 2 && (
                  <button
                    className={
                      isSendActive ? "submit-btn" : "inactive-submit-btn "
                    }
                    onClick={isSendActive ? (e) => onSubmitVital() : ""}
                  >
                    <img
                      src={isSendActive ? NextImage : NextImage}
                      alt="icon"
                    ></img>
                    <label>SEND</label>
                  </button>
                )}

                {showDetail && (
                  <VitalDetails
                    closeDrawer={closeDrawer}
                    showDetail={showDetail}
                    currentDetail={currentDetails}
                  />
                )}
              </section>
            </main>
          )}
        </IonContent>
      )}
    </IonPage>
  );
}
