import {
  IonContent,
  IonPage,
  NavContext,
  useIonViewDidEnter,
} from "@ionic/react";
import React, { useContext, useEffect, useRef } from "react";
import plusIcon from "../../assets/images/ic_plus_button_primary_2z.svg";
import nextImg from "../../assets/images/nextz.svg";
import "./index.scss";
import EditAddAddress from "./components/EditAddAddress";
import { CircularProgress, Drawer } from "@mui/material";
import familyPlanServices from "../../services/FamilyPlanServices";
import navigoServices from "../../services/NavigoServices";
import { useHistory, useLocation, withRouter } from "react-router";
import BackButton from "../../components/common/BackButton";
import AuthenticationService from "../../services/AuthenticationService";
import AddressElement from "./components/addressElement";
import RemoveDialog from "./components/removeDialog";
import NoAddressFound from "./components/noAddressFound";
import { useDispatch, useSelector } from "react-redux";
import {
  handleAddressErrorReducer,
  handleEditRemove,
  setStateFromPayloadAddress,
  toggleDisabledAddress,
} from "./addressSlice";
import Toast from "../../components/common/toast";
import { setStateFromPayload } from "../navigo/navigoSlice";
import { setMedicineAddress } from "../medicineOrder/medicineSlice";
import ErrorComponent from "../../components/common/ErrorComponent";
import { allRoutes } from "../../app/list";

const emptyAddress = {
  city: "",
  countryCode: "91",
  district: "",

  landmark: "",
  line1: "",
  line2: "",
  name: "",

  phoneNo: "",
  pincode: "",
  state: "",
  type: 1,
};

export default function AddressBook() {
  const patientProfile = useSelector(
    (store) => store.login.user.patientProfile
  );

  const addressStore = useSelector((store) => store.address);

  const toastTimer = useRef(null);
  const addressToRemove = useRef();
  const indexOfAddressToRemove = useRef(null);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const navContext = useContext(NavContext);

  const {
    layout,
    loading,
    memberData,
    memberPatientId,
    addressbook,
    editRemoveIndex,
    openRemoveDialog,
    showToast,
    buttonMsg,
    selectedAddress,
    isEdit,
    selectMedicineOrderAddress,
    disabledDeliveryAddress,
    defaultLayout,
    error,
    errorMsg,
  } = addressStore;

  useEffect(() => {
    const { state } = location;
    let id = -1;
    let memberData = {};

    if (state && state.isMember) {
      id = state.memberPatientId;

      memberData = { ...state.memberData };
    }
    if (
      memberData &&
      memberData.countryCode &&
      memberData.countryCode != "91"
    ) {
      // history.replace("/tab/chat");
      navContext.goBack("/tab/chat");
    } else {
      if (
        location.pathname !== "/medicine" &&
        location.pathname !== "/navigo"
      ) {
        dispatch(
          setStateFromPayloadAddress({
            loading: true,
          })
        );
        familyPlanServices
          .getAllAddress(id)
          .then((result) => {
            const res = result.data;
            let layout = 1;
            let defLayout = 1;
            let path = "";
            let btnMsg = "Deliver to this address";
            if (state && state.isPath) {
              defLayout = 4;
              layout = 4;
              path = state.path;
              if (state.path === "/navigo") {
                btnMsg = "Continue";
              }
            }
            let myAddresses = [];
            if (res && res.length === 0) {
              layout = 2;
            }

            if (res && res.length > 0) {
              myAddresses = [...res];
            }
            dispatch(
              setStateFromPayloadAddress({
                loading: false,
                layout: layout,
                defaultLayout: defLayout,
                addressbook: [...myAddresses],
                memberPatientId: id,
                memberData: memberData,
                path: path,
                buttonMsg: btnMsg,
                disabledDeliveryAddress: true,
                selectMedicineOrderAddress: -1,
              })
            );
          })
          .catch((err) => {
            dispatch(
              setStateFromPayloadAddress({
                loading: false,
                error: true,
                errorMsg: "Error while fetching address",
              })
            );
          });
      }
    }
    return () => {
      if (toastTimer.current) {
        clearTimeout(toastTimer.current);
      }
    };
  }, [location.state]);

  useIonViewDidEnter(() => {
    dispatch(handleEditRemove(-1));
  }, []);

  const handleAddressAddition = () => {
    let userName = "";
    let phoneNo = "";
    let countryCode = "91";

    if (patientProfile) {
      userName = patientProfile.firstName + " " + patientProfile.lastName;
      phoneNo = patientProfile.phoneno;
      countryCode = patientProfile.countryCode;
    }
    if (memberPatientId !== -1) {
      phoneNo = memberData.phoneNo;
      if (
        memberData &&
        (memberData.relation === 6 || memberData.relation === 8)
      ) {
        phoneNo = patientProfile?.phoneno;
      }
      userName =
        memberData.firstName +
        " " +
        `${memberData.lastName ? memberData.lastName : ""}`;
      countryCode = memberData.countryCode;
    }

    dispatch(
      setStateFromPayloadAddress({
        layout: 3,
        isEdit: false,
        selectedAddress: {
          ...emptyAddress,
          name: userName,
          phoneNo: phoneNo,
          countryCode: countryCode,
        },
      })
    );
  };
  const handleDrawerClose = (arg, data) => {
    if (arg) {
      let newAddressList = [];
      if (isEdit) {
        newAddressList = addressbook.map((addr) => {
          if (addr.id === data.id) {
            return { ...data };
          }
          return { ...addr };
        });
      } else {
        if (addressbook && addressbook.length > 0) {
          newAddressList = addressbook.map((addr) => addr);
        }
        newAddressList.push({ ...data });
      }
      dispatch(
        setStateFromPayloadAddress({
          addressbook: [...newAddressList],
          layout: defaultLayout,
        })
      );
    } else {
      dispatch(
        setStateFromPayloadAddress({
          layout: addressbook.length > 0 ? defaultLayout : 2,
        })
      );
    }
  };

  const handleOpenRemoveDialog = (arg, addr) => {
    dispatch(
      setStateFromPayloadAddress({
        openRemoveDialog: arg,
        editRemoveIndex: -1,
        addressToRemove: arg ? { ...addr } : {},
      })
    );
  };
  const handleMedicineOrder = () => {
    let selectedAddress = addressbook[selectMedicineOrderAddress];
    const { state } = location;

    const {
      isMedicine,
      path,
      serviceId,
      memberPatientId,
      memberData,
      isMember,
      isUserSelectedFamily,
    } = state;

    if (isMedicine) {
      dispatch(setMedicineAddress({ address: { ...selectedAddress } }));
      navContext.navigate(
        `${path}?isFamily=${isMember}&memberPatientId=${memberPatientId}&isAddr=true`,
        "none",
        "pop"
      );
    } else {
      dispatch(
        setStateFromPayloadAddress({
          disabledDeliveryAddress: false,
          loading: true,
        })
      );
      navigoServices
        .checkServiceAvailability({
          serviceId: serviceId,
          addressId: selectedAddress.id,
        })
        .then((result) => {
          let res = result.data;
          if (res && res.serviceable) {
            dispatch(
              setStateFromPayload({ addressSelected: { ...selectedAddress } })
            );

            navContext.navigate(
              `${path}?isFamily=${isMember}&memberPatientId=${memberPatientId}&isUserSelectedFamily=${isUserSelectedFamily}`,
              "none",
              "pop"
            );
          } else {
            dispatch(
              handleAddressErrorReducer({
                err: true,
                errMsg:
                  "Location is non-serviceable.Please select a different address",
              })
            );
          }
        })
        .catch((err) => {
          dispatch(
            handleAddressErrorReducer({
              err: true,
              errMsg:
                "Location is non-serviceable.Please select a different address",
            })
          );
        });
    }
  };

  return (
    <IonPage>
      <IonContent className="address-book-ion-content-class">
        {error && (
          <ErrorComponent
            show={error}
            msg={errorMsg}
            errorClose={() => {
              dispatch(handleAddressErrorReducer({ err: false, errMsg: "" }));
            }}
          />
        )}
        {editRemoveIndex != -1 && (
          <div
            className="fixed w-screen h-screen bg-transparent z-9"
            onClick={() => {
              dispatch(handleEditRemove());
            }}
          ></div>
        )}
        <div className="address-book-container">
          {(layout === 1 || layout === 2 || layout === 4) && (
            <div className="address-book-header">
              <BackButton></BackButton>
              <p>Address book</p>
            </div>
          )}
          {loading && (
            <CircularProgress className="address-book-loading-circular"></CircularProgress>
          )}

          {!loading && layout === 1 && (
            <div className="address-book-main">
              {addressbook.map((addr, index) => {
                return (
                  <AddressElement
                    addr={{ ...addr }}
                    index={index}
                    editRemoveIndex={editRemoveIndex}
                    handleOpenRemoveDialog={handleOpenRemoveDialog}
                    showRemoveOption={true}
                    memberData={memberData}
                  />
                );
              })}

              <div
                className="address-book-add-new-item"
                onClick={handleAddressAddition}
              >
                <img src={plusIcon} alt="icon" />
              </div>
            </div>
          )}
          {!loading && layout === 2 && (
            <NoAddressFound handleAddressAddition={handleAddressAddition} />
          )}
          {!loading && layout === 3 && (
            <EditAddAddress handleClose={handleDrawerClose} />
          )}
          {!loading && layout === 4 && (
            <div className="address-book-main">
              {addressbook.map((addr, index) => {
                return (
                  <AddressElement
                    key={addr.id}
                    addr={{ ...addr }}
                    index={index}
                    editRemoveIndex={editRemoveIndex}
                    handleOpenRemoveDialog={handleOpenRemoveDialog}
                    selectMedicineOrderAddress={selectMedicineOrderAddress}
                    showRemoveOption={false}
                    isRadio={true}
                  />
                );
              })}
              <div className="address-layout-5-middle">
                <span>Add a new address</span>
                <button
                  className="address-layout-5-middle-button"
                  onClick={handleAddressAddition}
                >
                  <img src={nextImg} />
                </button>
              </div>
              <button
                className="address-layout-5-bottom"
                onClick={handleMedicineOrder}
                disabled={disabledDeliveryAddress}
              >
                {buttonMsg}
              </button>
            </div>
          )}
        </div>
        {openRemoveDialog && (
          <RemoveDialog handleOpenRemoveDialog={handleOpenRemoveDialog} />
        )}
        {showToast && (
          <Toast
            msg={showToast}
            close={() => {
              dispatch(setStateFromPayloadAddress({ showToast: "" }));
            }}
          />
        )}
      </IonContent>
    </IonPage>
  );
}
