import { Link, useHistory } from "react-router-dom";
import earnImage from "../../../../../assets/images/footIcon.svg";
import pointImage from "../../../../../assets/images/earn image.png";
import forwardImage from "../../../../../assets/images/forwardRedz.svg";
export const CidrRewardPointrCard = ({ selectedPolicy }) => {
  const policyData = selectedPolicy?.data;
  const history = useHistory();

  return (
    <>
      <div className="relative  text-lightblack">
        <img src={pointImage} alt="" className="w-full h-60 rounded-lg" />
        <Link to="/policy">
          <img
            src={forwardImage}
            className="h-6 w-6 absolute top-5 right-5 cursor-pointer"
          />
        </Link>
        <div className="absolute bottom-4 px-2  flex justify-between w-full items-center">
          <div
            className="rounded-lg bg-white p-2 outline left-7 flex items-center w-full"
            style={{ outlineColor: "#95ADCF", outlineWidth: "1px" }}
          >
            <img src={earnImage} alt="" className="w-4 h-4 top-4 " />
            <span className="font-bold text-base  px-3  text-center leading-7 ">
              {policyData?.hwc.toLocaleString()}
              <span>&nbsp;Healthy Weeks earned</span>
            </span>
          </div>
          {/* <button className="rounded  w-20 h-10 px-2  text-white font-bold reward-point-level-tag">
            <span className="reward-point-level-top">Level</span>
            <span className="reward-point-level-bottom">
              {status === "success" && data.data.currentLevelName}
              {reward[0].currentLevelName}
            </span>
          </button> */}
        </div>
      </div>
    </>
  );
};
