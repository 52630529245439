import { IonDatetime, IonInput, IonItem, IonLabel } from "@ionic/react";

import { CircularProgress } from "@mui/material";
import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { logEvent, logEventWithData } from "../../../app/eventSlice";
import { checkHTMLInput } from "../../../utils/helperMethods";
import calendarIcon from "../../../assets/icons/calendar-icon.svg";
import TermsCondition from "../../login/components/termsCondition";
import { registerUser, updateErrorState } from "../registrationSlice";
import { useEffect } from "react";
import moment from "moment";

const RegistrationInfo = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDob] = useState("");

  const registrationStore = useSelector((state) => state.registration);
  const { user } = useSelector((store) => store.login);
  const dispatch = useDispatch();
  const history = useHistory();
  const calendarRef = useRef(null);
  const [maxDateRef, setMaxDateRef] = useState(moment().format("YYYY-MM-DD"));
  const [minDateRef, setMinDateRef] = useState(
    moment().subtract(100, "years").format("YYYY-MM-DD")
  );
  const { registerData } = registrationStore;
  const {
    sourceAdded,
    whitelisted,
    loading,
    shouldShowEmail,
    email,
    doctorCode,
    documentId,
  } = registrationStore.ui;

  const getRegisterUserData = () => {
    return {
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      dob: dob,
    };
  };
  const performValidationCheck = () => {
    let result = true;
    const nameRegex = /^[ a-zA-Z\-\']+$/;
    if (!firstName || !nameRegex.test(firstName) || !firstName.trim()) {
      dispatch(logEvent({ action: "error_first_name" }));
      dispatch(
        updateErrorState({ data: true, msg: "Please enter a valid first name" })
      );
      result = false;
    } else if (lastName && !nameRegex.test(lastName)) {
      dispatch(logEvent({ action: "error_last_name" }));
      dispatch(
        updateErrorState({ data: true, msg: "Please enter a valid last name" })
      );
      result = false;
    } else if (checkHTMLInput(firstName) || checkHTMLInput(lastName)) {
      dispatch(updateErrorState({ data: true, msg: "HTML tags not allowed." }));
      result = false;
    }
    return result;
  };

  const onSignup = () => {
    const valid = performValidationCheck();

    if (valid) {
      dispatch(registerUser({ body: getRegisterUserData() }));
    }
  };
  const [showTerm, setShowTerm] = useState(false);
  function closeTerm() {
    setShowTerm(false);
  }
  const span = {
    color: "#E44437",
  };

  useEffect(() => {
    if (registerData) {
      history.replace("/generate-voucher");
    }
  }, [registerData]);
  const handleIonDateTimeOpener = () => {
    if (calendarRef && calendarRef.current) {
      calendarRef.current.open();
    }
  };
  return (
    <div className="form-group-div ">
      <div className="baby-dob-container">
        <p className="baby-dob-string">First name*</p>
        <input
          type="text"
          placeholder="First name"
          className="baby-dob-input placeholder:text-gray-300"
          value={firstName}
          onChange={(e) => {
            setFirstName(e.target.value);
          }}
        />
      </div>

      <div className="baby-dob-container">
        <p className="baby-dob-string">Last name</p>
        <input
          type="text"
          placeholder="Last name"
          className="baby-dob-input placeholder:text-gray-300"
          value={lastName}
          onChange={(e) => {
            setLastName(e.target.value);
          }}
        />
      </div>
      <div className="baby-dob-container">
        <p className="baby-dob-string">Date of birth*</p>
        <div className="baby-dob-container-bottom">
          {dob ? (
            <p
              className="baby-dob-date !text-base"
              onClick={handleIonDateTimeOpener}
            >
              {dob}
            </p>
          ) : (
            <p
              className="baby-dob-placeholder"
              onClick={handleIonDateTimeOpener}
            >
              {"dd-mm-yyyy"}
            </p>
          )}
          <img src={calendarIcon} onClick={handleIonDateTimeOpener} />
        </div>
      </div>
      <IonDatetime
        className="my-ion-date-time-class"
        ref={calendarRef}
        displayFormat="DD/MMM/YYYY"
        mode="ios"
        monthShortNames="Jan, Feb, Mar, Apr, May, June, July, Aug, Sep, Oct, Nov, Dec"
        style={{ display: "none" }}
        onIonChange={(e) => {
          setDob(moment(e.detail.value).format("Do MMMM YYYY"));
        }}
        max={maxDateRef}
        min={minDateRef}
      />

      <div
        className={`pt-10 text-center btn-wrapper ${
          firstName.length > 0 && dob !== "" ? "enable-regs-btn" : null
        }`}
      >
        <button
          onClick={(e) =>
            onSignup() &&
            dispatch(
              logEventWithData({
                action: "voucher code",
                data: {
                  timestamp: Date.now(),
                  name: firstName + " " + lastName,
                  DOB: dob,
                },
              })
            )
          }
          disabled={loading}
          className="!text-white bg-zburgundy disabled:bg-grayd8"
        >
          Generate voucher
          {loading && <CircularProgress size={24} className="buttonprogress" />}
        </button>
      </div>
      {/* <div className="register-bottom">
        <span>By clicking Let’s get started, you agree to our </span>
        <br />
        <span
          onClick={(e) => {
            dispatch(
              logEvent({ action: "click_registration_terms_and_condition" })
            );
            history.push("/terms");
          }}
        >
          <strong>Terms of Service </strong>
        </span>
        <span>&</span>
        <span
          onClick={(e) => {
            dispatch(logEvent({ action: "click_registration_privacy_policy" }));
            history.push("/privacy");
          }}
        >
          <strong> Privacy Policy</strong>
        </span>
      </div> */}

      {showTerm && (
        <>
          <TermsCondition closeTerm={closeTerm} />
        </>
      )}
    </div>
  );
};

export default RegistrationInfo;
